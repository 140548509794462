import React from 'react';
import { Button, Theme, Grid, IconButton } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Link as RouterLink, useHistory } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';

/**
 * Icon
 */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { usePermission } from '../../_providers/PermissionProvider';


// Utils

interface IProps {
}

export interface IState {
    file: File | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
        actionContainer: {
            display: 'flex'
        }
    })
)

const SubProjectList = (props: IProps) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/subproject';
    const classes = useStyles();
    const history = useHistory();

    const columnsTable = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'project_name',
            label: 'Project',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        {
                            permissions['subproject.manage'] &&
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => history.push(`subproject/edit/${row.id}`)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        }
                    </div>
                );
            }
        }
    ]

    return (
        <>
            {permissions['subproject.manage'] &&
                <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                    <Grid item>
                        <RouterLink to="/subproject/add">
                            <Button
                                variant="contained"
                                color="primary"
                                component="label"
                                size="small"
                                startIcon={<AddIcon />}
                            >
                                Add Sub Project
                            </Button>
                        </RouterLink>
                    </Grid>
                </Grid>
            }

            <DataTable
                url={API_URL}
                columns={columnsTable}
            />
        </>
    )
}

export default SubProjectList;
