import React, { useEffect, useMemo, useState } from 'react';

/**
 * Components
 */
import { Grid, Theme, Button, TextField, MenuItem } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import TextEditor from '../../_components/_form/TextEditor';
import PhotoPreview from '../../_components/PhotoPreview';
import ButtonUpload from '../../_components/ButtonUpload';
import DateRange from '../../_components/_form/DateRange';
import AsyncMultipleAutoComplete from '../../_components/_form/AsycMultipleAutoComplete';

/**
 * Utils
*/
import { IValidationErrors, validateData } from '../../_utils/Validation';
import { ARTICLE_CATEGORIES } from './ArticleList';
import { useLocation, useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { generalErrorHandler, getBase64, resizeAndResetOrientationImage } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';
import moment from 'moment';

interface Props {
}

interface ArticleFormState {
    edit_reason: string
    category: string
    project_name: string
    project_id: number
    cluster_name: string
    cluster_id: number
    title: string
    slug: string
    meta_title: string
    meta_description: string
    meta_keyword: string
    content: string
    image: null | {
        url: string
        file: File
    }
    image_thumbnail: null | {
        url: string
        file: File
    }
    image_link_url: string
    image_caption: string
    image_alt: string
    promotion_start_date: moment.Moment | null
    promotion_end_date: moment.Moment | null
    listings: IAutoCompleteOption[]
    banner_image: null | {
        url: string
        file: File
    }
    banner_caption: string
    banner_alt: string
    banner_url: string
    kpr_listing_id: string
    kpr_listing_label: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        borderBottomLine: {
            borderBottom: '1px solid #eee'
        },
        fab: {
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            display: 'none',
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            }
        },
        fabSpacing: {
            marginRight: '1rem',
        },
        container: {
            paddingBottom: '50px'
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'column',
            '& > .label': {
                fontSize: 16,
                color: '#484848',
                marginBottom: 12,
            },
            '& > .error': {
                color: theme.palette.error.main,
                marginTop: 12,
            }
        },
        promotionContainer: {
            position: 'relative',
            '& > .DateRangePicker': {
                width: '100%',
            },
        },
        promotion: {
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            top: 16,
            left: 36,
            background: 'white',
            zIndex: 1,
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        },
    })
);

const ArticleForm = (props: Props) => {
    const classes = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const id = useMemo(() => params.id, [params])
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<ArticleFormState>({
        edit_reason: '',
        category: '',
        project_name: '',
        project_id: 0,
        cluster_name: '',
        cluster_id: 0,
        title: '',
        slug: '',
        meta_title: '',
        meta_description: '',
        meta_keyword: '',
        content: '',
        image: null,
        image_thumbnail: null,
        image_link_url: '',
        image_caption: '',
        image_alt: '',
        promotion_start_date: null,
        promotion_end_date: null,
        listings: [],
        banner_image: null,
        banner_caption: '',
        banner_alt: '',
        banner_url: '',
        kpr_listing_id: '',
        kpr_listing_label: '',
    })
    const [error, setError] = useState<IValidationErrors<ArticleFormState>>({})

    const validationRules = useMemo(() => ({
        category: 'required',
        title: 'required',
        content: 'required',
        edit_reason: id ? 'required' : '',
    }), [id])

    useEffect(() => {
        if (id) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/blog/${id}`)
            .then(res => res.data)
            .then(data => {
                setState(prev => {
                    const newState = {
                        ...prev,
                        ...data,
                        project_name: data.project_name || '',
                        cluster_name: data.cluster_name || '',
                        meta_description: data.meta_description || '',
                        meta_keyword: data.meta_keyword || '',
                        project_id: data.project_id || 0,
                        cluster_id: data.cluster_id || 0,
                        image: data.image_url ? {
                            file: null,
                            url: data.image_url,
                        } : null,
                        image_thumbnail: data.image_thumbnail_url ? {
                            file: null,
                            url: data.image_thumbnail_url,
                        } : null,
                        image_link_url: data.image_link_url,
                        promotion_start_date: data.promotion_start_date ? moment(data.promotion_start_date) : null,
                        promotion_end_date: data.promotion_end_date ? moment(data.promotion_end_date) : null,
                        listings: data.listing ? data.listing.map((listing: any) => ({ id: listing.id, label: `${listing.code} - ${listing.name}` })) : [],
                        banner_image: data.banner_image_url ? {
                            file: null,
                            url: data.banner_image_url,
                        } : null,
                        kpr_listing_id: data.kpr_listing_id || '',
                        kpr_listing_label: data.kpr_listing_label || '',
                    };

                    delete newState.image_url;
                    delete newState.image_thumbnail_url;
                    delete newState.banner_image_small
                    delete newState.banner_image_small_url

                    return newState;
                })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            cluster_name: name === 'project_id' ? '' : name === 'cluster_id' ? value.label : prev.cluster_name,
            cluster_id: name === 'project_id' ? 0 : name === 'cluster_id' ? value.id : prev.cluster_id,
            project_name: name === 'project_id' ? value.label : prev.project_name,
            project_id: name === 'project_id' ? value.id : prev.project_id,
            kpr_listing_id: name === 'kpr_listing_id' ? value.id.toString() : prev.kpr_listing_id,
            kpr_listing_label: name === 'kpr_listing_id' ? value.label : prev.kpr_listing_label,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            kpr_listing_id: name === 'kpr_listing_id' ? '' : prev.kpr_listing_id,
            kpr_listing_label: name === 'kpr_listing_id' ? '' : prev.kpr_listing_label,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutoCompleteMultipleChange = (name: string, value: IAutoCompleteOption[]) => {
        setState(prev => ({
            ...prev,
            listings: value,
        }))

        setError(prev => ({
            ...prev,
            listings: '',
        }))
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target

        if (files?.length) {
            if (files[0].size >= 5000000) {
                Swal.fire({
                    title: 'Error!',
                    text: `Failed: Max uploaded file is 5MB`,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        getBase64(file)
                            .then(base64 => {
                                setState(prev => ({
                                    ...prev,
                                    [name]: {
                                        file,
                                        url: base64 as string,
                                    },
                                }))
                            })

                        setError(prev => ({
                            ...prev,
                            [name]: '',
                        }))
                    })
            }
        } else {
            setState(prev => ({
                ...prev,
                [name]: value,
            }))
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleEditorChanged = (name: string, value: string) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleRemoveImage = (name: string) => {
        setState(prev => ({
            ...prev,
            [name]: null,
        }))
    }

    const handleSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)

        setError(errors)

        if (isValid) {
            setIsLoading(true)

            const fd = new FormData()

            Object.entries(state).forEach(([key, value]) => {
                if (value || (!value && ['image', 'image_thumbnail', 'banner'].includes(key))) {
                    switch (key) {
                        case 'image':
                        case 'image_thumbnail':
                        case 'banner_image':
                            if (value) {
                                if (value.file) {
                                    fd.append(key, value.file)
                                } else if (value.url) {
                                    fd.append(`${key}_url`, value.url)
                                }
                            }
                            break
                        case 'promotion_start_date':
                            fd.append(key, value.format('YYYY-MM-DD'))
                            break
                        case 'promotion_end_date':
                            fd.append(key, value.format('YYYY-MM-DD'))
                            break
                        case 'listings':
                            state.listings.forEach(value => {
                                fd.append('listings[]', value.id.toString())
                            })
                            break
                        default:
                            fd.append(key, value)
                            break
                    }
                }
            })

            if (id) {
                fd.append('_method', 'PATCH')
            }

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/blog${id ? `/${id}` : ''}`, fd)
                .then(res => res.data)
                .then(data => {
                    Swal.fire({
                        title: "Submit data berhasil",
                        icon: 'success',
                        onAfterClose: () => {
                            history.push('/article');
                        },
                        timer: 1000
                    });
                    setIsLoading(false)
                })
                .catch(err => {
                    generalErrorHandler(err)
                    setIsLoading(false)
                })
        }
    }

    return (
        <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
                <h1 className={classes.borderBottomLine}>Article - {location.pathname === '/article/add' ? 'Add' : 'EDIT'}</h1>
            </Grid>

            <LoadingScreen open={isLoading} fullScreen />
            {
                !!id &&
                <Grid item xs={12}>
                    <TextField
                        label="Edit Reason"
                        name="edit_reason"
                        value={state.edit_reason}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!error.edit_reason}
                        helperText={error.edit_reason}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Category"
                    name="category"
                    value={state.category}
                    onChange={handleChanged}
                    error={!!error.category}
                    helperText={error.category}
                >
                    {ARTICLE_CATEGORIES.map(category => <MenuItem value={category.value} key={category.value}>{category.label}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <AsyncAutoComplete
                    label="KPR Listing"
                    name="kpr_listing_id"
                    initialQuery={state.kpr_listing_label}
                    onChange={handleAutocomplete}
                    onInputChange={handleAutocompleteInputChanged}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/all-listing`}
                    iconSearch
                    errorText={error.kpr_listing_id}
                    placeholder='Hanya isi bila akan menggunakan shortcode [kpr_banner]'
                />
            </Grid>
            {
                state.category === 'promotion' &&
                <Grid item xs={12} className={classes.promotionContainer}>
                    <div className={classes.promotion}>
                        Promotion Duration
                    </div>
                    <DateRange
                        dates={{
                            startDate: state.promotion_start_date,
                            endDate: state.promotion_end_date
                        }}
                        identifier="article-form"
                        setDates={dates => {
                            setState(prev => ({
                                ...prev,
                                promotion_start_date: dates.startDate,
                                promotion_end_date: dates.endDate,
                            }))
                        }}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <AsyncAutoComplete
                    label="Project"
                    name="project_id"
                    initialQuery={state.project_name}
                    onChange={handleAutocomplete}
                    onInputChange={handleAutocompleteInputChanged}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/project`}
                    iconSearch
                    errorText={error.project_id}
                />
            </Grid>
            <Grid item xs={12}>
                <AsyncAutoComplete
                    label="Cluster"
                    name="cluster_id"
                    initialQuery={state.cluster_name}
                    onChange={handleAutocomplete}
                    onInputChange={handleAutocompleteInputChanged}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster${state.project_id ? `?project_id=${state.project_id}` : ''}`}
                    iconSearch
                    errorText={error.cluster_id}
                />
            </Grid>
            <Grid item xs={12}>
                <AsyncMultipleAutoComplete
                    label='Units'
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/listing?cluster_id=${state.cluster_id}`}
                    name="listings"
                    value={state.listings}
                    onChange={handleAutoCompleteMultipleChange}
                    errorText={error.listings}
                    disabled={!state.cluster_id}
                    iconSearch
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Title"
                    name="title"
                    value={state.title}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.title}
                    helperText={error.title}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Slug"
                    name="slug"
                    value={state.slug}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.slug}
                    helperText={error.slug}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Meta Title"
                    name="meta_title"
                    value={state.meta_title}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.meta_title}
                    helperText={error.meta_title}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Meta Description"
                    name="meta_description"
                    value={state.meta_description}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.meta_description}
                    helperText={error.meta_description}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Meta Keyword"
                    name="meta_keyword"
                    value={state.meta_keyword}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.meta_keyword}
                    helperText={error.meta_keyword}
                />
            </Grid>

            <Grid item xs={12}>
                <h3>Article</h3>
                <TextEditor
                    name="content"
                    value={state.content}
                    onChange={handleEditorChanged}
                    imagesUpload
                />
                {error.content && <span style={{ fontSize: 12, color: '#f44336', marginTop: 4, marginLeft: 8 }}>{error.content}</span>}
            </Grid>

            <Grid item xs={12} md={6}>
                <div className={classes.imageContainer}>
                    <h3>
                        Banner Image
                    </h3>
                    {
                        state.banner_image ?
                            <PhotoPreview
                                index={1}
                                onRemoveFile={() => handleRemoveImage('banner_image')}
                                src={state.banner_image.url}
                                style={{
                                    margin: 0,
                                }}
                            />
                            :
                            <ButtonUpload
                                onChange={(e) => handleChanged(e as React.ChangeEvent<HTMLInputElement>)}
                                name="banner_image"
                                accept='image/x-png,image/jpeg,.webp'
                                style={{
                                    margin: 0,
                                }}
                            />
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Banner URL"
                    name="banner_url"
                    value={state.banner_url}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.banner_url}
                    helperText={error.banner_url}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Banner Caption"
                    name="banner_caption"
                    value={state.banner_caption}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Banner Alt"
                    name="banner_alt"
                    value={state.banner_alt}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <div className={classes.imageContainer}>
                    <h3>
                        Primary Image
                    </h3>
                    {
                        state.image ?
                            <PhotoPreview
                                index={1}
                                onRemoveFile={() => handleRemoveImage('image')}
                                src={state.image.url}
                                style={{
                                    margin: 0,
                                }}
                            />
                            :
                            <ButtonUpload
                                onChange={(e) => handleChanged(e as React.ChangeEvent<HTMLInputElement>)}
                                name="image"
                                style={{
                                    margin: 0,
                                }}
                            />
                    }
                    {
                        (error.image) &&
                        <span className="error">
                            Please choose the image
                        </span>
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Image URL"
                    name="image_link_url"
                    value={state.image_link_url}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!error.image_link_url}
                    helperText={error.image_link_url}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Image Caption"
                    name="image_caption"
                    value={state.image_caption}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Image Alt"
                    name="image_alt"
                    value={state.image_alt}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <div className={classes.imageContainer}>
                    <h3>
                        Primary Image Thumbnail
                    </h3>
                    {
                        state.image_thumbnail ?
                            <PhotoPreview
                                index={1}
                                onRemoveFile={() => handleRemoveImage('image_thumbnail')}
                                src={state.image_thumbnail.url}
                                style={{
                                    margin: 0,
                                }}
                            />
                            :
                            <ButtonUpload
                                onChange={(e) => handleChanged(e as React.ChangeEvent<HTMLInputElement>)}
                                name="image_thumbnail"
                                style={{
                                    margin: 0,
                                }}
                            />
                    }
                </div>
                <i>Recommended dimension : 360x240</i>
            </Grid>

            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
}

const validationAlias = {
    edit_reason: 'Edit Reason',
    category: 'Category',
    title: 'Title',
    meta_description: 'Meta Description',
    meta_keyword: 'Meta Keyword',
    content: 'Article',
    image: 'Image',
}

export default ArticleForm;