import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'

export interface FolderFormModalProps {
    isOpen: boolean
    state: {
        name: string
        id: null | number
    }
    error: {
        name: string
    }
    onClose: () => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onSubmit: () => void
}

const FolderFormModal = (props: FolderFormModalProps) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>{ props.state.id ? 'Rename Folder' : 'New Folder' }</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="name"
                            placeholder='Folder Name'
                            value={props.state.name}
                            onChange={props.onChange}
                            error={!!props.error.name}
                            helperText={props.error.name}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={props.onSubmit}
                >
                    { props.state.id ? 'Update' : 'Create' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FolderFormModal
