import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React from 'react';
import TextArea from '../../../_components/_form/TextArea';

interface Props {
    open: boolean;
    onClose: () => void;
    deleteReason: string;
    onHandleDeleteReasonChange: (e: any) => void;
    onHandleDeleteEnquiry: () => void;
}

const DeleteEnquiryModal = (props: Props) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
            <DialogTitle>Void Document</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextArea
                            name="deleteReason"
                            label="Alasan Void"
                            value={props.deleteReason}
                            onChange={props.onHandleDeleteReasonChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onHandleDeleteEnquiry} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteEnquiryModal;