import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, InputAdornment, IconButton, Avatar, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import { IState, IErrorState } from './AgentList';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
// import TextArea from '../../_components/_form/TextArea';

//Icon
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
    open: boolean;
    state: IState;
    errorState: IErrorState;
    onClose: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
    onUpload: (e: SyntheticEvent) => void;
    onHandleAutocomplete: (name: string, value: IAutoCompleteOption) => void;
    onHandleAutocompleteInputChanged: (e: any, name: string) => void;
}

const useStyles = makeStyles({
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    photoContainer: {
        marginBottom: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        '& .photo-icon': {
            height: 72,
            width: 72,
            borderRadius: 72,
            margin: '0 1.25rem 0 0',
            color: '#D9D9D9'
        },
        '& .photo': {
            height: 72,
            width: 72,
            borderRadius: 72,
            margin: '0 1.25rem 0 0'
        },
        '& .file-container': {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            '& .upload-button': {
                color: '#033A11',
                fontSize: 16
            },
            '& .detail': {
                color: '#9b9b9b'
            }
        }
    },
})

const AgentForm = ({ open, state, errorState, onClose, onChange, onSubmit, onUpload, onHandleAutocomplete, onHandleAutocompleteInputChanged }: Props) => {
    const permissions = usePermission()
    const classes = useStyles();

    const agentTypes = [
        {
            key: 'inhouse',
            value: 'Inhouse'
        },
        {
            key: 'inhouse_jv',
            value: 'Inhouse JV'
        },
        {
            key: 'external',
            value: 'External'
        },
        {
            key: 'digital_sales',
            value: 'Digital Sales'
        },
    ];

    const uploadInput = useRef<HTMLInputElement>(null);

    const [visible, setVisible] = useState({
        bukan_kata_sandi: false,
        bukan_kata_sandi_confirmation: false
    });

    useEffect(() => {
        setVisible({
            bukan_kata_sandi: false,
            bukan_kata_sandi_confirmation: false
        })
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>Agent Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    {
                        !!state.id &&
                        <div className={classes.photoContainer}>
                            {
                                state.photo.url !== '' ?
                                    <Avatar alt="profile picture" src={state.photo.url || ''} className="photo" /> :
                                    <Avatar className="photo">{localStorage.getItem('user_name') && localStorage.getItem('user_name')![0].toUpperCase()}</Avatar>
                            }
                            <div className="file-container">
                                <span className="upload-button" onClick={() => uploadInput.current!.click()}>Pilih Foto</span>
                                {
                                    state.photo.file &&
                                    <span className="detail">{state.photo.file.name}</span>
                                }
                            </div>
                            <input
                                hidden
                                type="file"
                                name="upload[]"
                                ref={uploadInput}
                                value=""
                                accept="image/x-png,image/jpeg"
                                onChange={onUpload}
                            />
                        </div>
                    }

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama"
                            name="name"
                            value={state.name}
                            onChange={onChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    {
                        localStorage.getItem('role') === 'superadmin' &&
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Type"
                                name="type"
                                value={state.type}
                                onChange={onChange}
                            >
                                {agentTypes.map(agentType => <MenuItem key={agentType.key} value={agentType.key}>{agentType.value}</MenuItem>)}
                            </TextField>
                        </Grid>
                    }
                    {
                        localStorage.getItem('role') === 'pic-project' &&
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Type"
                                name="type"
                                value={state.type}
                                onChange={onChange}
                            >
                                <MenuItem value="inhouse">Inhouse</MenuItem>
                                <MenuItem value="external">External</MenuItem>
                            </TextField>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="email"
                            value={state.email}
                            onChange={onChange}
                            error={!!errorState.email}
                            helperText={errorState.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi ? 'text' : 'password'}
                            label="Password"
                            name="bukan_kata_sandi"
                            value={state.bukan_kata_sandi}
                            onChange={onChange}
                            // error={!!errorState.bukan_kata_sandi}
                            // helperText={errorState.bukan_kata_sandi}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi: !prevState.bukan_kata_sandi }))}
                                        >
                                            {visible.bukan_kata_sandi ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi_confirmation ? 'text' : 'password'}
                            label="Confirm Password"
                            name="bukan_kata_sandi_confirmation"
                            value={state.bukan_kata_sandi_confirmation}
                            onChange={onChange}
                            // error={!!errorState.bukan_kata_sandi_confirmation}
                            // helperText={errorState.bukan_kata_sandi_confirmation}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi_confirmation: !prevState.bukan_kata_sandi_confirmation }))}
                                        >
                                            {visible.bukan_kata_sandi_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                    {permissions['agent.admin'] && state.type !== 'external' &&
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Assigned Project"
                                name="assigned_project_id"
                                initialQuery={state.assigned_project_name || ''}
                                onChange={onHandleAutocomplete}
                                onInputChange={onHandleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/project`}
                                iconSearch
                            />
                        </Grid>
                    }

                    {
                        (
                            (
                                !state.id &&
                                ['admin-agent'].includes(localStorage.getItem('role')!!)
                            )
                            || (state.type === 'external' && ['superadmin'].includes(localStorage.getItem('role')!!))
                        ) &&
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Captain Member"
                                name="captain_id"
                                initialQuery={state.captain_name || ''}
                                onChange={onHandleAutocomplete}
                                onInputChange={onHandleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/user?admin_agent_member=1`}
                                iconSearch
                            />
                        </Grid>
                    }

                    {
                        (state.type === 'external' || ['agent-captain', 'admin-agent', 'sales-manager'].includes(localStorage.getItem('role')!!)) &&
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Assigned Office"
                                name="assigned_office_id"
                                initialQuery={state.assigned_office_name || ''}
                                onChange={onHandleAutocomplete}
                                onInputChange={onHandleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/agent-office`}
                                iconSearch
                                errorText={errorState.assigned_office_id}
                            />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Phone"
                            name="phone"
                            value={state.phone}
                            onChange={onChange}
                            placeholder="628xxxxxxxxxx"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="NRK"
                            name="nrk"
                            value={state.nrk}
                            onChange={onChange}
                            placeholder="Employee ID"
                        />
                    </Grid>

                    {/* {['agent-captain', 'admin-agent'].includes(localStorage.getItem('role')!!) !== false &&
                        <>
                            <Grid item xs={12}>
                                <TextArea
                                    label="Profile"
                                    name="profile_description"
                                    value={state.profile_description}
                                    onChange={onChange}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Facebook URL"
                                    name="facebook_url"
                                    value={state.facebook_url}
                                    onChange={onChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Instagram URL"
                                    name="instagram_url"
                                    value={state.instagram_url}
                                    onChange={onChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Twitter URL"
                                    name="twitter_url"
                                    value={state.twitter_url}
                                    onChange={onChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="LinkedIn URL"
                                    name="linkedin_url"
                                    value={state.linkedin_url}
                                    onChange={onChange}
                                />
                            </Grid>
                        </>
                    } */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AgentForm;