import { Theme } from "@mui/material"
import { makeStyles, createStyles } from '@mui/styles';
import React, { ReactNode, useEffect } from "react"

interface IProps {
    children: ReactNode
    isOpen: boolean
    background?: string
    fullScreen?: boolean
    noPadding?: boolean
    notCentered?: boolean
    fullScreenKeyboard?: boolean
    isNested?: boolean
    isNestedOpen?: boolean
    maxWidth?: number
    close?: () => void
}

const ModalContainer = ({ children, isOpen, background, fullScreen, noPadding, notCentered, fullScreenKeyboard, isNested, isNestedOpen, maxWidth, close }: IProps) => {
    const classes = useStyles()

    useEffect(() => {
        if (!isNested) {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
        return () => { document.body.style.overflow = 'auto' }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isNested) {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
        // eslint-disable-next-line
    }, [isOpen])

    return (
        <div className={`${ classes.container } ${ isOpen ? classes.open : classes.close } ${ isNestedOpen ? classes['overflow-none'] : classes['overflow-auto']}`}>
            <div className={`${classes['relative']} ${classes['min-h-full']} ${classes['min-w-full']} ${fullScreen ? `${classes['w-full']} ${classes['h-full']}` : ''} ${classes['flex']} ${classes['md_items-center']} ${classes['justify-center']}`}>
                <div className={`${classes.backdrop}`} style={{ justifyContent: notCentered ? 'flex-start' : 'center', backgroundColor: fullScreenKeyboard ? 'white' : undefined }} onClick={() => {
                    if (typeof close !== 'undefined') {
                        close()
                    }
                }}>
                </div>
                <div
                    className={`${classes.card} ${ isOpen ? classes['card-open'] : '' } ${background || classes['bg-white']} ${ fullScreen ? `${classes['w-full']} ${classes['h-full']}` : fullScreenKeyboard ? classes['w-full'] : '' } ${ noPadding ? '' : classes['p-8']} ${ fullScreen || fullScreenKeyboard ? classes['px-0'] : '' } ${classes['rounded']}`}
                    style={{
                        margin: fullScreenKeyboard ? undefined : notCentered ? '75px 0' : 0,
                        maxWidth
                    }}    
                >
                    { children }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1200,
        transform: 'scale(0)',
    },
    open: {
        transform: 'scale(1)',
    },
    close: {
        transitionDelay: '.2s',
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 50,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    card: {
        transition: 'opacity .2s ease-in-out',
        opacity: 0,
        zIndex: 51,
    },
    'card-open': {
        opacity: 1,
    },
    'overflow-none': {
        overflow: 'hidden',
    },
    'overflow-auto': {
        overflow: 'auto'
    },
    relative: {
        position: 'relative'
    },
    'min-h-full': {
        minHeight: '100%'
    },
    'min-w-full': {
        minWidth: '100%'
    },
    'w-full': {
        width: '100%'
    },
    'h-full': {
        height: '100%'
    },
    flex: {
        display: 'flex'
    },
    'justify-center': {
        justifyContent: 'center'
    },
    'bg-white': {
        backgroundColor: 'white'
    },
    'p-8': {
        padding: '2rem'
    },
    'px-0': {
        paddingLeft: 0,
        paddingRight: 0,
    },
    rounded: {
        borderRadius: '0.25rem',
    },
    'md_items-center': {
        [theme.breakpoints.up('md')]: {
            alignItems: 'center'
        }
    }
  })
)

export default ModalContainer