import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Dialog, AppBar, Toolbar, IconButton, Slide, Theme } from '@mui/material';

/**
 * Table
 */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/**
 * Form
 */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { renderWarningButton } from '../../../_utils/Helper';

interface IProps {
    isOpen: boolean;
    cluster_id: number;
    onClose: Function;
}

interface ICluster {
    id: number;
    name: string;
}

interface IListAdvisor {
    name: string;
    cluster_id: number;
    user_id: number;
}

interface ISelectAdvisor {
    value: number;
    label: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

export default function ClusterMemberModal({ isOpen, cluster_id, onClose }: IProps) {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [cluster, setCluster] = useState<ICluster>({ id: 0, name: "" });
    const [listMember, setListMember] = useState<Array<IListAdvisor>>([]);
    const [selectMembers, setSelectMembers] = useState<Array<ISelectAdvisor>>([]);
    const [selectedMember, setSelectedMember] = useState(0);
    const [addFormState, setAddFormState] = useState(false);

    const addAdvisor = () => {
        if (selectedMember === 0) {
            alert('No Advisor Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah advisor ini?")
            .then((result) => {
                if (result.value) {
                    let data = {
                        cluster_id: cluster.id,
                        user_id: selectedMember
                    }
                    /**
                     * Add To List, send user_id + cluster_id
                     */
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/member`, data).then(res => {
                        setIsLoading(true);
                    })
                }
            });
        }
    }

    const handleClose = () => {
        onClose();
    }

    const deleteMember = (cluster_id: number, user_id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus member ini?")
        .then((result) => {
            if (result.value) {
                let data = {
                    cluster_id: cluster_id,
                    user_id: user_id
                }
                /**
                 * Delete from List
                 */
                DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/cluster/member/${cluster_id}`, { params: data}).then(res => {
                    setIsLoading(true);
                })
            }
        });
    }

    useEffect(() => {
        if (cluster_id !== 0) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/member`, { params: { id: cluster_id } })
            .then(res => {
                setCluster(res.data.cluster as ICluster);
                setListMember(res.data.cluster_members as Array<IListAdvisor>)
                setSelectMembers(res.data.members as Array<ISelectAdvisor>)
                setSelectedMember(0)
                setIsLoading(false);
            })
        }
    }, [cluster_id, isLoading]);

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Cluster Member
                        </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Typography variant="h4">
                    Member Cluster - {cluster.name}
                </Typography>

                <br></br>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setAddFormState(!addFormState) }}
                >
                    Tambah Advisor
                </Button>

                <br></br>

                {addFormState &&
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <br></br>
                        <Autocomplete
                            id="combo-box-demo"
                            options={selectMembers}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 300 }}
                            onChange={(e: any, option: any) => {
                                if (option === null) {
                                    setSelectedMember(0)
                                } else {
                                    setSelectedMember(option.value)
                                }
                            }}
                            onInputChange={(e: any, option: any) => {
                            }}
                            renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                        />

                        <br></br>

                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            disabled={selectedMember === 0}
                            onClick={addAdvisor}
                        >
                            Submit
                        </Button>
                    </div>
                }

                <br></br>
                <br></br>

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listMember.map((row) => (
                                <TableRow key={row.user_id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => deleteMember(row.cluster_id, row.user_id)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    )
}