import React, { useState, useEffect, useReducer, CSSProperties } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { TablePagination, MenuItem, ListSubheader, Theme, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import qs from 'querystring';
import moment from 'moment';

// Sortable
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// Input
import TextField from '@mui/material/TextField';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Utilities
import DefaultAxios from '../../_utils/DefaultAxios';
import SortableTableHeader from './SortableTableHeader';

// Component
import LoadingScreen from '../LoadingScreen';

// Type
import { Order } from './SortableTableHeader';

//Utils
import { isMobile } from '../../_utils/Helper';

// Datepicker
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

// Styles
import './CSS/datatable.css';

interface Props {
    url: string;
    columns: IColumn[];
    reDraw?: number;
    orders?: [
        string,
        Order
    ];
    onSort?: Function;
    sorting?: boolean;
    onDateChange?: Function;
    customParams?: Object;
    tab?: React.ReactNode
    rowCallback?: Function;
    responseCallback?: Function;
}

interface IColumn {
    filterable?: boolean;
    sortable?: boolean
    name: string;
    label: string;
    type?: string;
    render?: Function;
    style?: CSSProperties;
    options?: any[] | Object;
    defaultOption?: string;
}

let typingTimer: any; //timer identifier
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            // position: 'relative'
        },
        tableContainer: {
            position: 'relative',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "50%"
        },
        label: {
            backgroundColor: 'white',
            padding: "2px"
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        refresh_btn: {
            marginTop: theme.spacing(2)
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        noData: {
            textAlign: 'center'
        },
        loadingBody: {
            textAlign: 'center',
            position: 'relative',
            height: '100px'
        },
        pagination: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            height: '52px'
        },
        paginationInfo: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        toggleSeeTotal: {
            marginLeft: '16px',
            display: 'absolute',
            whiteSpace: 'nowrap'
        }
    }),
);

const DragableContainer = SortableContainer(({ children }: { children: any[] }) => {
    return <TableBody>{children}</TableBody>
})

const DragableElement = SortableElement(({ value }: { value: any }) =>
    <TableRow style={{ zIndex: 9999 }}>{value}</TableRow>
);

export default function DataTable(props: Props) {
    const table_filterable = false;
    const classes = useStyles();
    const columns = props.columns;
    const location = useLocation();

    const [rows, setRows] = useState<any[]>([]);
    const [firstInit, setFirstInit] = useState(true);
    const [count, setCount] = React.useState(rows.length);
    const [hasMore, setHasMore] = React.useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState<Order>(props.orders && props.orders[1] ? props.orders[1] : false);
    const [orderBy, setOrderBy] = React.useState<string>(props.orders && props.orders[0] ? props.orders[0] : '');
    const [loading, setLoading] = React.useState(true);
    const [isPrepopulateQueryDone, setIsPrepopulateQueryDone] = React.useState(false);

    // Params
    let initialParams: any = {};
    let initialOptionParams: any = {};
    let initialDateParams: any = {};
    let initialDateFocus: any = {};

    let initialMonthParams: any = {};
    let initialMonthFocus: any = {};
    props.columns.map((column: any) => {
        if (typeof column.options !== 'undefined' && typeof column.filterable !== 'undefined' ? column.filterable : table_filterable) {
            initialOptionParams[column.name] = column.defaultOption && column.defaultOption !== '' ? column.defaultOption : '';
        } else if (typeof column.type !== 'undefined' && column.type === 'date' && typeof column.filterable !== 'undefined' ? column.filterable : table_filterable) {
            initialDateParams[`${column.name}_start`] = '';
            initialDateParams[`${column.name}_end`] = '';
            initialDateFocus[column.name] = null;
        } else if (typeof column.type !== 'undefined' && column.type === 'month' && typeof column.filterable !== 'undefined' ? column.filterable : table_filterable) {
            initialMonthParams[column.name] = '';
            initialMonthFocus[column.name] = null;
        } else if (typeof column.filterable !== 'undefined' ? column.filterable : table_filterable) {
            initialParams[column.name] = "";
        }
        return true;
    });

    const paramsReducer = (state: any, action: any) => {
        switch (action.type) {
            case 'SET_ITEM':
                return {
                    ...state,
                    [action.name]: action.value
                }
            case 'REPLACE_STATE':
                const newState = action.value;
                return { ...state, ...newState };
            default:
                break;
        }

        return { ...state };
    }
    const [queries, setQuery] = useReducer(paramsReducer, initialParams);
    const [optionQueries, setOptionQuery] = useReducer(paramsReducer, initialOptionParams);
    const [dateQueries, setDateQuery] = useReducer(paramsReducer, initialDateParams);
    const [dateFocus, setDateFocus] = useReducer(paramsReducer, initialDateFocus);
    const [seeTotal, setSeeTotal] = useState(false)

    //TEMP_DISABLED
    // const [monthQueries, setMonthQuery] = useReducer(paramsReducer, initialMonthParams);
    const monthQueries = initialMonthParams;

    //TEMP_DISABLED
    // const [monthFocus, setMonthFocus] = useReducer(paramsReducer, initialMonthFocus);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let queryExists = false;
        const newQueries: any = {};
        const newOptionQueries: any = {};
        const newDateQueries: any = {};

        queryParams.forEach((value: string, key: string) => {
            const column = props.columns.find(item => {
                return item.name === key;
            });

            if (column) {
                if (column.type === 'string' || column.type === 'number') {
                    newQueries[key] = value;
                    queryExists = true;
                }

                if (typeof column.options !== 'undefined') {
                    newOptionQueries[key] = value;
                    queryExists = true;
                }

                if (column.type === 'date') {
                    const exploded = value.split(' - ');
                    newDateQueries[`${key}_start`] = exploded[0] ? moment(exploded[0], 'DD/MM/YYYY') : '';
                    newDateQueries[`${key}_end`] = exploded[1] ? moment(exploded[1], 'DD/MM/YYYY') : '';
                    queryExists = true;
                }
            }
        });

        if (queryExists) {
            setQuery({ name: '', value: newQueries, type: 'REPLACE_STATE' });
            setOptionQuery({ name: '', value: newOptionQueries, type: 'REPLACE_STATE' });
            setDateQuery({ name: '', value: newDateQueries, type: 'REPLACE_STATE' });
            setIsPrepopulateQueryDone(true);
        } else {
            loadTable();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isPrepopulateQueryDone) {
            loadTable();
        }
        // eslint-disable-next-line
    }, [isPrepopulateQueryDone]);

    useEffect(() => {
        if (!firstInit) {
            let doneTypingInterval = 1500;
            clearTimeout(typingTimer);
            const doneTyping = () => loadTable()
            typingTimer = setTimeout(doneTyping, doneTypingInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.url, queries]);

    useEffect(() => {
        if (!firstInit) {
            loadTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, order, orderBy, props.reDraw, optionQueries, props.customParams, seeTotal])

    const loadTable = () => {
        let newDateQueries: any = {};
        let newMonthQueries: any = {};
        for (let i in monthQueries) {
            console.log('loadtable monthqueries', monthQueries[i])
            newMonthQueries[i] = monthQueries[i] ? moment(monthQueries[i]).format('YYYY-MM-DD') : '';
        }
        for (let i in dateQueries) {
            let key = String(i).replace(/(_start|_end)$/gi, '');
            if (typeof newDateQueries[key] === 'undefined') {
                newDateQueries[key] = dateQueries[i] ? dateQueries[i].format('DD/MM/YYYY') : '';
            } else {
                newDateQueries[key] += ' - ' + (dateQueries[i] ? dateQueries[i].format('DD/MM/YYYY') : '');
            }
        }
        pushQueryParams();
        setLoading(true);
        const params = props.customParams && props.customParams !== ''
            ? { ...queries, ...optionQueries, ...newMonthQueries, ...newDateQueries, ...props.customParams, page: page + 1, rowsPerPage, order: orderBy, dir: order, see_total_data: seeTotal ? 1 : 0 }
            : { ...queries, ...optionQueries, ...newMonthQueries, ...newDateQueries, page: page + 1, rowsPerPage, order: orderBy, dir: order, see_total_data: seeTotal ? 1 : 0 }
        DefaultAxios.get(props.url, { params: params })
            .then(res => {
                setRows(res.data.data);
                setCount(res.data.total);
                setHasMore(res.data.more)
                if (typeof props.responseCallback !== 'undefined') {
                    props.responseCallback(res.data);
                }
            })
            .finally(() => {
                if (firstInit) {
                    setFirstInit(false);
                }
                setLoading(false);
            });
    }

    const pushQueryParams = () => {
        const filteredQueries: any = {};
        for (let key in queries) {
            if (queries[key]) {
                filteredQueries[key] = queries[key];
            }
        }
        for (let key in optionQueries) {
            if (optionQueries[key]) {
                filteredQueries[key] = optionQueries[key];
            }
        }
        for (let i in dateQueries) {
            let key = String(i).replace(/(_start|_end)$/gi, '');
            if (typeof filteredQueries[key] === 'undefined') {
                filteredQueries[key] = dateQueries[i] ? dateQueries[i].format('DD/MM/YYYY') : '';
            } else {
                filteredQueries[key] += ' - ' + (dateQueries[i] ? dateQueries[i].format('DD/MM/YYYY') : '');
            }

            if (String(filteredQueries[key]).trim() === '-') {
                delete filteredQueries[key];
            }
        }
        window.history.pushState([], 'List', Object.keys(filteredQueries).length ? '?' + qs.stringify(filteredQueries) : location.pathname);
    }

    const filterChange = (e: any) => {
        setQuery({ type: 'SET_ITEM', name: e.target.name, value: e.target.value });
        setPage(0);
    }

    const optionChange = (e: any) => {
        setOptionQuery({ type: 'SET_ITEM', name: e.target.name, value: e.target.value });
        setPage(0);
    }

    const handleSort = (oldIndex: number, newIndex: number) => {
        if (typeof props.onSort !== 'undefined') {
            props.onSort(oldIndex, newIndex);
            const oldRows = rows.slice();

            const row1 = oldRows[oldIndex];
            const row2 = oldRows[newIndex];

            oldRows[oldIndex] = row2;
            oldRows[newIndex] = row1;

            setRows(oldRows);
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'desc' : (isDesc ? false : 'asc'));
        setOrderBy(!isDesc ? property : '');
    };

    const handleDateFocusChanged = (focusedInput: 'startDate' | 'endDate' | null, columnName: string) => {
        setDateFocus({ type: 'SET_ITEM', name: columnName, value: focusedInput });
    }

    const handleDateChanged = (dates: any, columnName: string) => {
        let newState = {
            [`${columnName}_start`]: dates.startDate,
            [`${columnName}_end`]: dates.endDate
        }
        setDateQuery({ type: 'REPLACE_STATE', name: '', value: newState });

        if (typeof props.onDateChange !== "undefined") {
            const new_start_date = dates.startDate !== null ? dates.startDate.format('YYYY-MM-DD') : null;
            const new_end_date = dates.endDate !== null ? dates.endDate.format('YYYY-MM-DD') : null;


            props.onDateChange({ start_date: new_start_date, end_date: new_end_date });
        }
    }

    //TEMP_DISABLED
    // const handleMonthFocusChanged = (dates: any, columnName: string) => {
    //     setMonthFocus({ type: 'SET_ITEM', name: columnName, value: dates });
    // }

    // const handleMonthChanged = (dates: any, columnName: string) => {
    //     let newState = {
    //         [columnName]: dates,
    //     }
    //     setMonthQuery({ type: 'REPLACE_STATE', name: columnName, value: newState });
    //     handleMonthFocusChanged(dates, columnName)
    // }

    const getTableBody = () => {
        if (loading) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell
                            className={classes.loadingBody}
                            colSpan={props.columns.length}
                        >
                            <LoadingScreen open={loading} fullScreen={false} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        } else if (rows.length > 0) {
            return (
                <DragableContainer onSortEnd={({ oldIndex, newIndex }) => handleSort(oldIndex, newIndex)} useDragHandle>
                    {
                        rows.map((row, i: number) => {
                            let index = i;
                            const tableRow = columns.map((column: any, i: number) => {
                                let value = null;
                                let style = {};
                                if (typeof row[column.name] !== 'undefined') {
                                    if (column.render && typeof column.render === 'function') {
                                        if (column.options && column.options !== '') {
                                            value = column.render(row[column.name], row, column.name, column.options, setRows, rows);
                                        } else {
                                            value = column.render(row[column.name], row, rows, index);
                                        }
                                    } else {
                                        value = row[column.name];
                                    }
                                } else if (column.name === 'EXTRA' && column.render && typeof column.render === 'function') {
                                    value = column.render(row);
                                }

                                if (typeof props.rowCallback !== 'undefined') {
                                    style = { ...style, ...(props.rowCallback(row) ?? {}) };
                                }

                                return <TableCell key={i} style={style}>{value}</TableCell>
                            });
                            return <DragableElement key={i} index={i} value={tableRow} />
                        })
                    }
                </DragableContainer>
            )
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell
                            className={classes.noData}
                            colSpan={props.columns.length}
                        >
                            No Data Available
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
    }

    return (
        <Paper
            className={classes.tableContainer}
            elevation={2}
        >
            <TableContainer>
                {
                    props.tab ?
                        props.tab
                        : null
                }
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <SortableTableHeader
                            sorting={typeof props.sorting !== 'undefined' ? props.sorting : true}
                            headCells={props.columns.slice()}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        {
                            Object.keys(initialParams).length
                                || Object.keys(initialOptionParams).length
                                || Object.keys(initialDateParams).length
                                ? <TableRow>
                                    {props.columns.map((column: any, i: number) => {
                                        if (column.filterable || (table_filterable)) {
                                            if (column.type === 'date') {
                                                return (
                                                    <TableCell key={i}>
                                                        <DateRangePicker
                                                            startDate={dateQueries[`${column.name}_start`] ? dateQueries[`${column.name}_start`] : null}
                                                            startDateId={`${column.name}_start`}
                                                            endDate={dateQueries[`${column.name}_end`] ? dateQueries[`${column.name}_end`] : null}
                                                            endDateId={`${column.name}_end`}
                                                            focusedInput={dateFocus[column.name]}
                                                            onDatesChange={e => handleDateChanged(e, column.name)}
                                                            onFocusChange={e => handleDateFocusChanged(e, column.name)}
                                                            keepOpenOnDateSelect={true}
                                                            small={true}
                                                            onClose={loadTable}
                                                            showClearDates={true}
                                                            reopenPickerOnClearDates={true}
                                                            isOutsideRange={() => false}
                                                            minimumNights={0}
                                                            withPortal={true}
                                                            numberOfMonths={isMobile() ? 1 : 2}
                                                        />
                                                    </TableCell>
                                                );
                                            } else if (typeof column.options !== 'undefined') {
                                                const options = column.options.map((item: any, key: any) => {
                                                    if (item.type === 'parent') {
                                                        return (
                                                            <ListSubheader key={key} style={{ pointerEvents: 'none', fontWeight: 700 }}>{item.value}</ListSubheader>
                                                        )
                                                    } else {
                                                        return (
                                                            <MenuItem key={key} value={item.key} style={item.style && item.style}>{item.value}</MenuItem>
                                                        );
                                                    }
                                                })
                                                return (
                                                    <TableCell key={i} style={column.style}>
                                                        <TextField
                                                            select
                                                            variant="outlined"
                                                            onChange={optionChange}
                                                            name={column.name}
                                                            value={optionQueries[column.name] !== '' ? optionQueries[column.name] : column.defaultOption}
                                                            fullWidth
                                                            size="small"
                                                        >
                                                            {options}
                                                        </TextField>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={i} style={column.style}>
                                                        <TextField
                                                            name={column.name}
                                                            value={queries[column.name]}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            onChange={filterChange}
                                                            placeholder={column.type === 'number' ? 'Use >,<,=,&' : ''}
                                                        />
                                                    </TableCell>
                                                )
                                            }
                                        } else {
                                            return (
                                                <TableCell key={i} style={column.style}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        disabled={true}
                                                        style={{ backgroundColor: "#d0cbcb52" }}
                                                    />
                                                </TableCell>
                                            )
                                        }
                                    })}
                                </TableRow>
                                : null
                        }
                    </TableHead>
                    {getTableBody()}
                </Table>
            </TableContainer>
            <div className={classes.pagination} style={{ position: 'relative' }}>
                <div className={classes.toggleSeeTotal}>
                    <FormGroup>
                        <FormControlLabel
                            value={seeTotal}
                            defaultChecked={seeTotal}
                            onChange={(_, checked) => setSeeTotal(checked)}
                            control={<Checkbox size='small' />}
                            label={<Typography variant="body2" color="textSecondary">Show Total Data</Typography>}
                        />
                    </FormGroup>
                </div>
                <div style={{ position: 'absolute', right: 0, maxWidth: '60%', overflowY: 'auto' }}>
                    <TablePagination
                        // className={classes.pagination}
                        rowsPerPageOptions={[5, 10, 25, 100, 500]}
                        count={count ? count : 99999}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton={true}
                        showLastButton={seeTotal}
                        nextIconButtonProps={{
                            disabled: !hasMore
                        }}
                        labelDisplayedRows={(paginationInfo) => (
                            <div className={classes.paginationInfo}>
                                <span>{`${paginationInfo.from} - ${paginationInfo.to}${seeTotal ? ` of ${count}` : ''}`}</span>
                            </div>
                        )}
                    />
                </div>
            </div>
        </Paper>
    );
}