
import React from 'react';
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Theme,
    Box,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

interface IProps {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    title: string
    titleContent?: React.ReactElement
    content: Array<any>
    additionalContent?: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 600
        },
        content: {
            marginTop: theme.spacing(2),
        },
        paper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        box: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        h6_title: {
            color: theme.palette.primary.main,
        },
        body: {
            wordBreak: 'break-word',
        },
    }),
);

const ModalSection = ({
    xs,
    md,
    title,
    content,
    titleContent,
    additionalContent
}: IProps) => {
    const classes = useStyles();

    const generateList = (array: Array<Array<string>>) => {
        return array.map((data: Array<string> | undefined) => {
            if (typeof data === "undefined" || data === null) {
                return null;
            } else {
                return (
                    <Box className={classes.box} key={data[0]}>
                        <Grid>
                            <Typography variant="body1" className={classes.title}>
                                {data[0]}
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: "7px" }}>
                            <Typography variant="body2" className={classes.body}>
                                {typeof data[1] === 'string' || data[1] === null ? (data[1] ? data[1] : "-") : data[1]}
                            </Typography>
                        </Grid>
                    </Box>
                )
            }
        }
        )
    }

    return (
        <Grid item md={md ? md : 12} xs={xs ? xs : 12}>
            <Paper className={classes.paper}>
                {titleContent ?
                    titleContent
                    :
                    <Typography variant="h6" className={classes.h6_title}>
                        {title}
                    </Typography>
                }

                <Divider></Divider>

                <Box className={classes.content}>
                    {(typeof content === "object") ?
                        generateList(content)
                        :
                        content
                    }

                    {additionalContent}
                </Box>
            </Paper>
        </Grid>
    );
}

export default ModalSection;