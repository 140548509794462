import React, { useEffect, useState } from 'react';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, TableFooter, Link } from '@mui/material';
import { DateRangePicker } from 'react-dates';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import { generalErrorHandler, isMobile } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import PublishIcon from '@mui/icons-material/Publish';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

interface Props {
    type?: 'agency' | 'captain';
}

const AgentPerformanceReport = (props: Props) => {
    const params: any = useParams();

    const initialDateFilter = {
        date_start: moment().subtract(1, 'weeks'),
        date_end: moment()
    };

    const [isLoading, setIsLoading] = useState(false);
    const [dateFocus, setDateFocus] = useState<'startDate' | 'endDate' | null>(null);
    const [dateFilter, setDateFilter] = useState<{ date_start: moment.Moment | null, date_end: moment.Moment | null }>(initialDateFilter);
    const [rows, setRows] = useState<any[]>([]);
    const [agency, setAgency] = useState('');
    const [captain, setCaptain] = useState('');

    const loadData = () => {
        setIsLoading(true);
        let data = {
            date: `${dateFilter.date_start?.format('DD/MM/YYYY')} - ${dateFilter.date_end?.format('DD/MM/YYYY')}`,
            id: params.id || '',
            type: props.type || ''
        }
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/agent/performance-report`, { params: data })
            .then(res => {
                setRows(res.data.reports);
                setAgency(res.data.agency);
                setCaptain(res.data.captain);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDateChanged = (dates: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
        let newFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    const handleDateFocusChanged = (focusedInput: 'startDate' | 'endDate' | null) => {
        setDateFocus(focusedInput);
    }

    const handleExport = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/agent/performance-report-export`,
                {
                    params:
                    {
                        date: `${dateFilter.date_start?.format('DD/MM/YYYY')} - ${dateFilter.date_end?.format('DD/MM/YYYY')}`,
                        office_id: params.id || ''
                    },
                    responseType: 'blob'
                })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `agent_report.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const countFooter = (key: any) => {
        return rows.reduce((total, item) => {
            return total + item[key];
        }, 0);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);
    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={8}>
                {
                    params.id &&
                    <Link
                        component={RouterLink}
                        to={props.type === 'agency' ? '/agency-performance-report' : '/captain-performance-report'}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<KeyboardArrowLeft />}
                        >
                            Back
                        </Button>
                    </Link>
                }
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    size="small"
                    onClick={handleExport}
                    startIcon={<PublishIcon />}
                    style={{ alignSelf: 'center', marginRight: '5px' }}
                >
                    Export
                </Button>
            </Grid>
            <Grid item xs={6}>
                {
                    props.type === 'agency'
                        ? <h3>{agency}</h3>
                        : <h3>{captain}</h3>
                }
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'end' }}>
                <DateRangePicker
                    startDate={dateFilter.date_start}
                    startDateId="date_start"
                    endDate={dateFilter.date_end}
                    endDateId="date_end"
                    focusedInput={dateFocus}
                    onDatesChange={handleDateChanged}
                    onFocusChange={handleDateFocusChanged}
                    keepOpenOnDateSelect={true}
                    small={true}
                    onClose={loadData}
                    showClearDates={true}
                    reopenPickerOnClearDates={true}
                    isOutsideRange={() => false}
                    minimumNights={0}
                    withPortal={true}
                    numberOfMonths={isMobile() ? 1 : 2}
                />

            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={2}>Name</TableCell>
                                <TableCell rowSpan={2}>Agency Office</TableCell>
                                <TableCell colSpan={8} align="center">Leads Status</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Fresh</TableCell>
                                <TableCell>Tanya</TableCell>
                                <TableCell>Prospect</TableCell>
                                <TableCell>Hot Prospect</TableCell>
                                <TableCell>Visit</TableCell>
                                <TableCell>Drop</TableCell>
                                <TableCell>Closing</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, key) => (
                                <TableRow key={key}>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.assigned_office_name ? row.assigned_office_name : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {row.fresh}
                                    </TableCell>
                                    <TableCell>
                                        {row.ask}
                                    </TableCell>
                                    <TableCell>
                                        {row.prospect}
                                    </TableCell>
                                    <TableCell>
                                        {row.hot_prospect}
                                    </TableCell>
                                    <TableCell>
                                        {row.visit}
                                    </TableCell>
                                    <TableCell>
                                        {row.drop}
                                    </TableCell>
                                    <TableCell>
                                        {row.closing}
                                    </TableCell>
                                    <TableCell>
                                        {row.total}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableCell>Total</TableCell>
                            <TableCell></TableCell>
                            <TableCell>{countFooter('fresh')}</TableCell>
                            <TableCell>{countFooter('ask')}</TableCell>
                            <TableCell>{countFooter('prospect')}</TableCell>
                            <TableCell>{countFooter('hot_prospect')}</TableCell>
                            <TableCell>{countFooter('visit')}</TableCell>
                            <TableCell>{countFooter('drop')}</TableCell>
                            <TableCell>{countFooter('closing')}</TableCell>
                            <TableCell>{countFooter('total')}</TableCell>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default AgentPerformanceReport;