import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import LoadingScreen from '../../_components/LoadingScreen'
import DataTable from '../../_components/_dataTable/DataTable'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'

interface WebsiteFeedbackListProps {
}

const API_URL = process.env.REACT_APP_API_URL + '/website-feedback'

const COLUMNS = [
    {
        name: 'date',
        label: 'Date',
        type: 'date',
        filterable: true
    },
    {
        name: 'q1',
        label: 'Q1',
        type: 'string',
        filterable: true,
        tooltip: 'E-Catalog is very easy to use*',
    },
    {
        name: 'q2',
        label: 'Q2',
        type: 'string',
        filterable: true,
        tooltip: 'The appearance (design) looks good to me*',
    },
    {
        name: 'q3',
        label: 'Q3',
        type: 'string',
        filterable: true,
        tooltip: 'E-Catalog gives many property options*',
    },
    {
        name: 'q4',
        label: 'Q4',
        type: 'string',
        filterable: true,
        tooltip: 'The discounts and promotions on E-Catalog are very attractive*',
    },
    {
        name: 'q5',
        label: 'Q5',
        type: 'string',
        filterable: true,
        tooltip: 'Sales agent responded quickly to my enquiries*',
    },
    {
        name: 'ip',
        label: 'IP',
        type: 'string',
        filterable: true
    },
    {
        name: 'notes',
        label: 'Notes',
        type: 'string',
        filterable: true
    },
]

const WebsiteFeedbackList = (props: WebsiteFeedbackListProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const exportData = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}/export${window.location.search}`, {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `feedbacks.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={exportData}
                    >
                        Export
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        url={API_URL}
                        columns={COLUMNS}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default WebsiteFeedbackList
