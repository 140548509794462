import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@mui/material';

import { IState, IErrorState } from './RoleList';

interface Props {
    open: boolean;
    state: IState;
    errorState: IErrorState;
    onClose: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
}

const RoleForm = ({ open, state, errorState, onClose, onChange, onSubmit }: Props) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>Role Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama"
                            name="name"
                            value={state.name}
                            onChange={onChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Permissions"
                            name="permissions"
                            value={state.permissions}
                            onChange={onChange}
                            error={!!errorState.permissions}
                            helperText={errorState.permissions}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RoleForm;