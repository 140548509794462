import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, InputAdornment, IconButton } from '@mui/material';

import { IState, IErrorState } from './AgentExternalList';

//Icon
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Props {
    open: boolean;
    state: IState;
    errorState: IErrorState;
    onClose: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
}

const AgentExternalForm = ({ open, state, errorState, onClose, onChange, onSubmit }: Props) => {
    const [visible, setVisible] = useState({
        bukan_kata_sandi: false,
        bukan_kata_sandi_confirmation: false
    });

    useEffect(() => {
        setVisible({
            bukan_kata_sandi: false,
            bukan_kata_sandi_confirmation: false
        })
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>User Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama"
                            name="name"
                            value={state.name}
                            onChange={onChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="email"
                            value={state.email}
                            onChange={onChange}
                            error={!!errorState.email}
                            helperText={errorState.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi ? 'text' : 'password'}
                            label="Password"
                            name="bukan_kata_sandi"
                            value={state.bukan_kata_sandi}
                            onChange={onChange}
                            error={!!errorState.bukan_kata_sandi}
                            helperText={errorState.bukan_kata_sandi}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi: !prevState.bukan_kata_sandi }))}
                                        >
                                            {visible.bukan_kata_sandi ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi_confirmation ? 'text' : 'password'}
                            label="Confirm Password"
                            name="bukan_kata_sandi_confirmation"
                            value={state.bukan_kata_sandi_confirmation}
                            onChange={onChange}
                            error={!!errorState.bukan_kata_sandi_confirmation}
                            helperText={errorState.bukan_kata_sandi_confirmation}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi_confirmation: !prevState.bukan_kata_sandi_confirmation }))}
                                        >
                                            {visible.bukan_kata_sandi_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AgentExternalForm;