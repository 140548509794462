import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles';
import { Folder, Replay } from '@mui/icons-material'
import { DriveFolder } from '../DrivePage'

export interface FileMoveModalProps {
    folders: DriveFolder[]
    isOpen: boolean
    isPreviousRoot: boolean
    onClose: () => void
    onSubmit: (folderId: number) => void
}

const FileMoveModal = (props: FileMoveModalProps) => {
    const classes = useStyles()
    const [selectedFolderId, setSelectedFolderId] = useState<null | number>(null)

    useEffect(() => {
        if (!props.isOpen) {
            setSelectedFolderId(null)
        }
    }, [props.isOpen])

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>Move File</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {
                        !props.isPreviousRoot &&
                        <Grid item xs={12}>
                            <div
                                className={`${classes.root} ${selectedFolderId === 0 ? classes.selected : ''}`}
                                onClick={() => setSelectedFolderId(0)}
                            >
                                <Replay style={{ marginRight: 8 }} color='action' />
                                <span>
                                    Previous Folder
                                </span>
                            </div>
                        </Grid>
                    }
                    {
                        props.folders.map(folder =>
                            <Grid item xs={12}>
                                <div
                                    className={`${classes.root} ${selectedFolderId === folder.id ? classes.selected : ''}`}
                                    onClick={() => setSelectedFolderId(folder.id)}
                                >
                                    <Folder style={{ marginRight: 8 }} color='action' />
                                    <span>
                                        {folder.name}
                                    </span>
                                </div>
                            </Grid>
                        )
                    }
                    {
                        (props.folders.length === 0 && props.isPreviousRoot) &&
                        <span>
                            Please create a folder first
                        </span>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                {
                    !(props.folders.length === 0 && props.isPreviousRoot) &&
                    <Button
                        color="primary"
                        disabled={selectedFolderId === null}
                        onClick={selectedFolderId !== null ? () => props.onSubmit(selectedFolderId) : undefined}
                    >
                        Submit
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default FileMoveModal

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: 8,
            border: '1px solid transparent',
            borderRadius: 5,
            transition: '150ms border-color linear',
        },
        selected: {
            borderColor: theme.palette.primary.main,
        },
    })
);
