import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import DefaultAxios from '../../_utils/DefaultAxios';
import {CircularProgress} from '@mui/material';

import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from '@mui/lab/Autocomplete';

export interface IAutoCompleteOption {
    label: string;
    id: number;
    [key: string]: any;
}

interface Props {
    label: string;
    url: string;
    name: string;
    initialQuery?: string; //optional
    onChange: Function;
    onInputChange?: (e: any, name: string) => void;
    disabled?: boolean;
    errorText?: string;
    size?: 'small' | 'medium';
    iconSearch?: boolean;
    placeholder?: string;
    param?: string
    key?: number
    icon?: any;
}

let timeout:any;    
const debounce = (func: ()=>void, wait: number=1000 ) => {
    return function(){
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
    }
}

export default function AsyncAutoComplete(props: Props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<IAutoCompleteOption[]>([]);
    const [query, setQuery] = useState("");
    const loading = open && options.length === 0;
    const url_to_fetch = props.url;

    // Fetching Data
    useEffect(() => {
        if (!open) {
            setOptions([]);
        } else {
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, query, url_to_fetch]);

    useEffect(() => {
        if (typeof props.initialQuery !== 'undefined') {
            setQuery(props.initialQuery);
        }
    }, [props.initialQuery]);

    const loadData = debounce(() => {
        let param = (props.param && props.param !== '') ? props.param : 'search'
            DefaultAxios.get(url_to_fetch, { params: { [ param ]: query } })
            .then(res => {
                setOptions(res.data);
            })
    })

    return (
        <Autocomplete
            size={props.size}
            disabled={props.disabled}
            style={{flexGrow: 1}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={option => option.label}
            options={options}
            loading={loading}
            inputValue={query}
            onChange={(e:any, value:any) => {
                props.onChange(props.name, value);
                setQuery(value.label);
            }}
            value={null}
            popupIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
            renderInput={params => (
                <TextField
                    {...params}
                    label={props.label}
                    fullWidth
                    variant="outlined"
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        if (props.onInputChange) {
                            props.onInputChange(e, props.name);
                        }
                        setQuery(e.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <React.Fragment>
                                {props.iconSearch ? <SearchIcon style={{ color: "#9F9F9F" }} /> : (props.icon ? props.icon : null)}
                            </React.Fragment>
                        ),
                    }}
                    error={!!props.errorText}
                    helperText={props.errorText}
                />
            )}
        />
    )
}