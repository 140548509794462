import React from 'react';
import TextField from '@mui/material/TextField';
import DefaultAxios from '../../_utils/DefaultAxios';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/lab/Autocomplete';

interface IAutoCompleteOption {
    label: string;
    id: number;
}

interface Props {
    label: string;
    url: string;
    value: IAutoCompleteOption[];
    name: string;
    onChange: Function;
    errorText?: string;
    disabled?: boolean
    iconSearch?: boolean;
    icon?: React.ReactNode;
}

let timeout: any;
const debounce = (func: () => void, wait: number = 1000) => {
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
    }
}

export default function AsyncMultipleAutoComplete(props: Props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<IAutoCompleteOption[]>([]);
    const [query, setQuery] = React.useState("");
    const loading = open && options.length === 0;
    const [inputValue, setValue] = React.useState<IAutoCompleteOption[]>([]);
    const url_to_fetch = props.url;

    // Change when props.value changed
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    // Fetching Data
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        } else {
            fetchData()
        }

        // eslint-disable-next-line
    }, [open, query, url_to_fetch]);

    const fetchData = debounce(() => {
        DefaultAxios.get(url_to_fetch, { params: { search: query } })
            .then(res => {
                setOptions(res.data);
            })
    }, 500)

    return (
        <Autocomplete
            multiple
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={option => option.label}
            options={options}
            loading={loading}
            inputValue={query}
            onChange={(e, value) => {
                props.onChange(props.name, value);
                setQuery("");
            }}
            value={inputValue}
            disabled={props.disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={props.label}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    error={!!props.errorText}
                    helperText={props.errorText}
                    disabled={props.disabled}
                />
            )}
        />
    )
}