import React, { useReducer, Dispatch, SetStateAction, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import Swal from 'sweetalert2';

import { useStyles, ISimpleImageState, IAction, API_URL } from '../SiteSetting';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Assets
import defaultThumbnail from '../../../Assets/Images/png/default-thumbnail-unit.png';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';

interface Props {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    state: ISimpleImageState | null;
    loadData: () => void;
    slug: string;
    label?: string;
    info?: string;
    doublePreviewSize?: boolean
    hideName?: boolean;
}

const HomepageBanner = ({ setIsLoading, state, loadData, slug, label, info, doublePreviewSize, hideName = false }: Props) => {
    const classes = useStyles();

    const initialState = {
        id: '',
        name: '',
        file: null,
        url: ''
    }

    const inputReducer = (state: ISimpleImageState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as ISimpleImageState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    useEffect(() => {
        if (state) {
            setInputState({ name: '', value: state, type: 'REPLACE_STATE' });
        }
    }, [state]);

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState: any = { ...inputState };
        const target = event.target;

        if (target.name === 'file') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                newState['url'] = URL.createObjectURL(file);
                newState['file'] = file;
            }
        } else {
            newState[target.name] = target.value;
        }
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    // const checkValidation = () => {
    //     let isValid = true;

    //     if (!inputState.name) {
    //         isValid = false;
    //     }

    //     if (!inputState.id && !inputState.file) {
    //         isValid = false;
    //     }

    //     if (!isValid) {
    //         Swal.fire({
    //             title: "Error",
    //             text: 'Please fill all inputs',
    //             icon: 'error'
    //         });
    //     }

    //     return isValid;
    // }

    const handleSubmit = () => {
        // if (!checkValidation()) {
        //     return;
        // }

        const fd = new FormData();

        for (let [key, value] of Object.entries(inputState)) {
            if (value) {
                fd.append(key, value);
            }
        }

        let url = '';

        if (inputState.id) {
            url = API_URL + '/' +  slug + '/' + inputState.id;
        } else {
            url = API_URL + '/' +  slug;
        }

        setIsLoading(true);
        DefaultAxios
            .post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Berhasil menyimpan",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadData();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleDelete = () => {
        renderWarningButton("Apakah anda yakin ingin menghapus image ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .delete(`${API_URL}/${inputState.id}`)
                    .then(() => {
                        Swal.fire({
                            title: "Berhasil menghapus",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(res => {
                                setInputState({ name: '', value: '', type: 'RESET_ITEM' });
                                loadData();
                            })
                    })
                    .catch(error => {
                        generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    const checkDisableUpload = (): boolean => {
        if (!hideName) {
            return !inputState.name && !inputState.file;
        } else {
            return !inputState.file;
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h3 className={classes.borderBottomLine}>{slug.replace(/-/g, ' ')}</h3>
            </Grid>

            <Grid item xs={12}>
                <img src={inputState.url ? inputState.url : defaultThumbnail} /* width={!!inputState.url && doublePreviewSize ? 200 : 100} */ height="100" alt={inputState.name} style={{ backgroundColor: '#fafafa' }} />
                <br />
                <input type="file" name="file" onChange={handleChanged} />
                {
                    info &&
                        <>
                            <br />
                            <i>{info}</i>
                        </>
                }
            </Grid>

            {
                !hideName &&
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label={label || 'Name'}
                        name="name"
                        value={inputState.name}
                        onChange={handleChanged}
                    />
                </Grid>
            }

            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={checkDisableUpload()}
                >
                    Upload
                </Button>

                {
                    inputState.id
                        ? <Button
                            variant="contained"
                            onClick={handleDelete}
                            style={{ marginLeft: '15px' }}
                        >
                            Delete
                        </Button>
                        : null
                }
            </Grid>
        </Grid>
    );
}

export default HomepageBanner;