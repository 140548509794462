import React, { useState } from 'react'

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen'
import { Button, Grid, Typography } from '@mui/material'
import DateRange from '../../_components/_form/DateRange'

/**
 * Utils
 */
import { IValidationErrors } from '../../_utils/Validation'
import DefaultAxios from '../../_utils/DefaultAxios'
import { downloadLink, generalErrorHandler } from '../../_utils/Helper'
import { Moment } from 'moment'

interface PageTrackingProps {

}

interface State {
    start_date: Moment | null
    end_date: Moment | null
}

const PageTracking = (props: PageTrackingProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>({
        start_date: null,
        end_date: null,
    })

    const [error, setError] = useState<IValidationErrors>({})

    const handleExport = () => {
        if (state.start_date === null || state.end_date === null) {
            setError({
                date: 'Harap mengisi tanggal'
            })
            return
        }

        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/page-tracking/export`,
                {
                    params:
                    {
                        start_date: state.start_date ? state.start_date.format('YYYY-MM-DD') : null,
                        end_date: state.end_date ? state.end_date.format('YYYY-MM-DD') : null,
                    },
                    responseType: 'blob'
                })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                downloadLink(url, 'page_tracking.xlsx')
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen fullScreen open={isLoading} />
            <Grid container>
                <Grid item md={5} xs={12}>
                    <DateRange
                        dates={{
                            startDate: state.start_date,
                            endDate: state.end_date
                        }}
                        identifier="date"
                        setDates={dates => {
                            setState(prev => ({
                                ...prev,
                                start_date: dates.startDate,
                                end_date: dates.endDate,
                            }))

                            setError({
                                date: '',
                            })
                        }}
                    />
                    {
                        error.date &&
                        <Typography
                            variant='body2'
                            color={theme => theme.palette.error.main}
                            sx={{
                                mt: 1,
                            }}
                        >
                            {error.date}
                        </Typography>
                    }
                </Grid>
                <Grid item md={7} xs={0} />
                <Grid item md={5} xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 32 }}>
                    <Button
                        variant='contained'
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default PageTracking
