import React from 'react';
import { Theme, Tooltip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

// Table
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

// Icon
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export type Order = 'asc' | 'desc' | false;

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: any;
    sorting: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

function SortableTableHeader(props: EnhancedTableProps) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort, headCells, sorting } = props;
    
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const renderTooltip = (text: string) => (
        <Tooltip title={text}>
            <InfoOutlinedIcon color='primary' fontSize={'inherit'} style={{ marginLeft: 4 }} />
        </Tooltip>
    )

    return (
        <TableRow>
            {headCells.map((headCell: any) => (
                <TableCell
                    key={headCell.name}
                    sortDirection={orderBy === headCell.name ? order : false}
                    style={headCell.style}
                >
                    {
                        sorting &&
                        (typeof headCell.sortable !== 'undefined' ? headCell.sortable : true)
                        ? (
                                <TableSortLabel
                                    active={orderBy === headCell.name}
                                    direction={order && orderBy === headCell.name ? order : 'asc'}
                                    onClick={createSortHandler(headCell.name)}
                                >
                                    {headCell.label}
                                    { headCell.tooltip && renderTooltip(headCell.tooltip) }
                                    {orderBy === headCell.name ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                        )
                        : 
                        <>
                            {headCell.label}
                            { headCell.tooltip && renderTooltip(headCell.tooltip) }
                        </>
                    }
                </TableCell>
            ))}
        </TableRow>
    );
}

export default SortableTableHeader;