import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

interface Props {
    open: boolean;
    fullScreen?: boolean;
}

const useStyles = makeStyles(_ => 
    createStyles({
        backdropRoot: {
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            opacity: 0.3,
        },
        circularRoot: {
            top: '50%',
            position: 'absolute',
            left: 'calc(50% - 20px)'
        }
    })    
)

const LoadingScreen = (props: Props) => {
    const classes = useStyles();
    
    return (
        <div 
            className={classes.backdropRoot} 
            style={{
                display: props.open ? 'block' : 'none',
                position: props.fullScreen ? 'fixed' : 'absolute',
                zIndex: props.fullScreen ? 1301 : 1099
            }}
        >
            <CircularProgress className={classes.circularRoot} />
        </div>
    );
}

LoadingScreen.defaultProps = {
    fullScreen: false
};

export default LoadingScreen;