import React, { Fragment, useState, useEffect, useReducer, SyntheticEvent } from 'react';
import { Button, Tooltip, IconButton, Switch, Grid, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import AgentForm from './AgentForm';
import { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import ProjectPlacementModal from './_components/ProjectPlacementModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, inputNumber, inputPhoneNumber, isValidEmail, renderWarningButton, resizeAndResetOrientationImage } from '../../_utils/Helper';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import GroupIcon from '@mui/icons-material/Group';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

export interface IOption {
    key: string;
    value: string;
}

interface IPhoto {
    url: string
    file: File | null
}

export interface IState {
    id: string;
    name: string;
    type: string;
    email: string;
    phone: string;
    nrk: string;
    assigned_project_id: string;
    assigned_project_name: string;
    assigned_office_id: string;
    assigned_office_name: string;
    profile_description: string;
    facebook_url: string;
    instagram_url: string;
    twitter_url: string;
    linkedin_url: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    photo: IPhoto;
    captain_id: string;
    captain_name: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
    email: string;
    role_id: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    assigned_office_id: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        addButton: {
            marginBottom: '20px'
        },
        errorContainer: {
            maxHeight: '200px',
            overflowY: 'auto'
        },
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
    })
);


const AgentList = (props: Props) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/agent';
    const AGENT_EXPORT_URL = process.env.REACT_APP_API_URL + '/agent/export';
    const classes = useStyles();

    const agentTypes = [
        {
            key: 'inhouse',
            value: 'Inhouse'
        },
        {
            key: 'inhouse_jv',
            value: 'Inhouse JV'
        },
        {
            key: 'external',
            value: 'External'
        },
        {
            key: 'digital_sales',
            value: 'Digital Sales'
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [projectPlacementModalValue, setProjectPlacementModalValue] = useState('');

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'nrk',
            label: 'NRK',
            type: 'string',
            filterable: true
        },
        {
            name: 'unique_code',
            label: 'Code',
            type: 'string',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'captain_name',
            label: 'Captain',
            type: 'string',
            filterable: true
        },
        {
            name: 'office_name',
            label: 'Kantor',
            type: 'string',
            filterable: true,
            render: (data: string | null) => {
                return data || '-';
            }
        },
        {
            name: 'project_name',
            label: 'Project',
            type: 'string',
            filterable: true,
            render: (data: string | null) => {
                return data || '-';
            }
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true
        },
        {
            name: 'type',
            label: 'Agent Type',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...agentTypes
            ],
            filterable: true,
            render: (data: any) => {
                return agentTypes.find(agentType => agentType.key === data)?.value;
            }
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'In-Active'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchActive(row.id)}
                    color="secondary"
                />
            }
        },
        {
            name: 'is_verified',
            label: 'Verified',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    permissions['agent.verify'] ?
                        <Switch
                            checked={+data === 1}
                            onChange={() => switchVerify(row.id)}
                            color="secondary"
                        />
                        : data === 1 ? 'Yes' : 'No'
                )
            }
        },
        {
            name: 'created_at',
            label: 'Tanggal Pembuatan',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="See Seller Page">
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                    window.open(`${process.env.REACT_APP_MAIN_URL}/agent/${row.slug}`);
                                }}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            permissions['agent.reset-password'] &&
                            <Tooltip title="Reset Password">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleResetPassword(row.id)}
                                >
                                    <ReplayIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            permissions['agent-external-project-placement.view'] &&
                            <Tooltip title="Project Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setProjectPlacementModalValue(row.id)}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                );
            }
        },
    ];

    const initialState = {
        id: '',
        name: '',
        type: 'inhouse',
        email: '',
        phone: '',
        nrk: '',
        assigned_project_id: '',
        assigned_project_name: '',
        assigned_office_id: '',
        assigned_office_name: '',
        profile_description: '',
        facebook_url: '',
        instagram_url: '',
        twitter_url: '',
        linkedin_url: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        photo: {
            url: '',
            file: null
        },
        captain_id: '',
        captain_name: ''
    };

    const initialErrorState = {
        name: '',
        email: '',
        role_id: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        sap_code: '',
        assigned_office_id: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (['bukan_kata_sandi', 'bukan_kata_sandi_confirmation'].includes(name)) {
            value = value.replace(/\s*/g, '');
        } else if (name === 'phone') {
            value = inputPhoneNumber(value);
        } else if (name === 'nrk') {
            value = inputNumber(value)
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.email) {
            newError.email = 'Email wajib diisi';
            isValid = false;
        } else if (!isValidEmail(inputState.email)) {
            newError.email = 'Format email tidak benar';
            isValid = false;
        }

        if (!inputState.id) {
            // if (!inputState.bukan_kata_sandi) {
            //     newError.bukan_kata_sandi = 'Password wajib diisi';
            //     isValid = false;
            // }

            // if (!inputState.password_confirmation) {
            //     newError.password_confirmation = 'Confirm Password wajib diisi';
            //     isValid = false;
            // } else if (inputState.password_confirmation.length < 8) {
            //     newError.password_confirmation = 'Confirm Password minimal 8 karakter';
            //     isValid = false;
            // }
        }

        if (inputState.bukan_kata_sandi && inputState.bukan_kata_sandi.length < 8) {
            newError.bukan_kata_sandi = 'Password minimal 8 karakter';
            isValid = false;
        }

        if (inputState.bukan_kata_sandi !== inputState.bukan_kata_sandi_confirmation) {
            newError.bukan_kata_sandi_confirmation = 'Confirm Password harus sama dengan Password';
            isValid = false;
        }

        if (inputState.type === 'external' && localStorage.getItem('role') === 'pic-project' && !inputState.assigned_office_id) {
            newError.assigned_office_id = 'Assigned office harus diisi'
            isValid = false
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDatatable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                newState.photo.url = newState.photo_small_url || '';
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const switchActive = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-active`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime())
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchVerify = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status Verifikasi Agent?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-verified`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti Verifikasi Agent",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime())
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleResetPassword = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin me-reset password agent ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/password/reset`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mereset password",
                                icon: 'success',
                                timer: 1000
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleUpload = (e: SyntheticEvent) => {
        setIsLoading(true)
        const target = e.target as HTMLInputElement
        const files = target.files as FileList
        if (target && files) {
            if (files[0].size >= 5000000) {
                // enqueueSnackbar('Ukuran file maksimum adalah 5MB', { variant: 'error' })
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        const url = process.env.REACT_APP_API_URL + '/agent/upload/' + inputState.id
                        let formData = new FormData()
                        formData.append('image', file)
                        DefaultAxios.post(url, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                            .then(res => {
                                Swal.fire({
                                    title: 'Berhasil upload photo',
                                    icon: 'success',
                                    timer: 1000,
                                    onAfterClose: () => {
                                        let newPhoto: IPhoto = {
                                            url: URL.createObjectURL(file),
                                            file: file
                                        }
                                        setInputState({ name: 'photo', value: newPhoto, type: 'SET_ITEM' });
                                    }
                                })
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    })
            }
        }
    }

    const handleClickExport = () => {
        DefaultAxios.get(
            AGENT_EXPORT_URL + `/agents.xlsx${window.location.search}`,
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `agents_${dateFormat(new Date(), 'YYMMDDHHmmss')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <AgentForm
                open={modalOpen}
                onClose={handleModalClose}
                state={inputState}
                errorState={errorState}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onUpload={handleUpload}
                onHandleAutocomplete={handleAutocomplete}
                onHandleAutocompleteInputChanged={handleAutocompleteInputChanged}
            />
            <ProjectPlacementModal
                isOpen={projectPlacementModalValue !== '' ? true : false}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setProjectPlacementModalValue('');
                }}
                agentId={projectPlacementModalValue}
            />
            <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                <Grid item>
                    {
                        (permissions['agent.manage'] || permissions['agent.manage-external'] || permissions['agent.manage-captain']) &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.addButton}
                            startIcon={<AddIcon />}
                            onClick={handleModalOpen}
                        >
                            Add New Agent
                        </Button>
                    }
                </Grid>

                {
                    permissions['agent.export'] &&
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: "5px" }}
                            onClick={handleClickExport}
                            startIcon={<PublishIcon />}
                        >
                            Export
                        </Button>
                    </Grid>
                }
            </Grid>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
                rowCallback={(row: any) => {
                    if (!row.captain_name && row.type === 'external') {
                        return {
                            backgroundColor: '#ff000030'
                        };
                    }

                    return null;
                }}
            />
        </Fragment>
    );
}

export default AgentList;