import React, { useState, useEffect, useMemo } from 'react';
import { Grid, TextField, Switch, Button, Typography, Box } from '@mui/material';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import { generalErrorHandler, isMobile } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';
import PublishIcon from '@mui/icons-material/Publish';

interface IProps {
}

interface IListProvince {
    id: number;
    label: string;
}

const Agent360Report = (props: IProps) => {
    const initialDateFilter = {
        date_start: moment().subtract(1, 'weeks'),
        date_end: moment()
    };

    const agentTypes = [
        {
            key: 'inhouse',
            value: 'Inhouse'
        },
        {
            key: 'inhouse_jv',
            value: 'Inhouse JV'
        },
    ];

    const [projectList, setProjectList] = useState<Array<IListProvince>>([]);
    const [isActive, setIsActive] = useState(true)
    const [projectId, setProjectId] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [dateFocus, setDateFocus] = useState<'startDate' | 'endDate' | null>(null);
    const [dateFilter, setDateFilter] = useState<{ date_start: moment.Moment | null, date_end: moment.Moment | null }>(initialDateFilter);
    const [filterDate, setFilterDate] = useState<string | null>(`${dateFilter.date_start?.format('DD/MM/YYYY')} - ${dateFilter.date_end?.format('DD/MM/YYYY')}`)

    const handleProjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectId(e.target.value)
    }

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked)
    };

    const handleDateChanged = (dates: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
        let newFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    const handleDateFocusChanged = (focusedInput: 'startDate' | 'endDate' | null) => {
        setDateFocus(focusedInput);
    }

    const handleExport = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/agent/360-report-export`,
                {
                    params:
                    {
                        date: `${dateFilter.date_start?.format('DD/MM/YYYY')} - ${dateFilter.date_end?.format('DD/MM/YYYY')}`,
                        project_id: projectId,
                        is_active: isActive ? 1 : 0
                    },
                    responseType: 'blob'
                })
            .then(res => {
                Swal.fire({
                    icon: 'info',
                    text: "Data sedang digenerate dan akan dikirimkan ke Email Admin"
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/all-project`)
            .then(res => {
                setProjectList(res.data)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateFilterDate = () => {
        if (!dateFilter.date_start && !dateFilter.date_end) {
            setFilterDate(null)
        } else {
            setFilterDate(`${dateFilter.date_start?.format('DD/MM/YYYY')} - ${dateFilter.date_end?.format('DD/MM/YYYY')}`)
        }
    }

    const customParams = useMemo(() => ({
        date: filterDate,
        project_id: projectId,
        is_active: isActive ? 1 : 0,
    }), [filterDate, projectId, isActive])

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    size="small"
                    onClick={handleExport}
                    startIcon={<PublishIcon />}
                    style={{ alignSelf: 'center', marginRight: '5px' }}
                >
                    Export
                </Button>
            </Grid>
            <Grid item xs={9}>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            label="Project"
                            name="project_id"
                            value={projectId}
                            onChange={handleProjectChange}
                            variant="outlined"
                            select
                            fullWidth
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            style={{ paddingRight: '10px' }}
                        >
                            <option aria-label="None" value="">All</option>
                            {projectList.map(data => {
                                return (
                                    <option value={data.id} key={data.id}>{data.label}</option>
                                )
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display="flex" style={{ marginTop: '10px', alignItems: 'center' }}>
                            <Switch
                                color="primary"
                                value={true}
                                checked={isActive}
                                onChange={handleSwitch}
                            />
                            <Typography>
                                Is Active
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'end' }}>
                <DateRangePicker
                    startDate={dateFilter.date_start}
                    startDateId="date_start"
                    endDate={dateFilter.date_end}
                    endDateId="date_end"
                    focusedInput={dateFocus}
                    onDatesChange={handleDateChanged}
                    onFocusChange={handleDateFocusChanged}
                    keepOpenOnDateSelect={true}
                    small={true}
                    onClose={updateFilterDate}
                    showClearDates={true}
                    reopenPickerOnClearDates={true}
                    isOutsideRange={() => false}
                    minimumNights={0}
                    withPortal={true}
                    numberOfMonths={isMobile() ? 1 : 2}
                />

            </Grid>

            <Grid item xs={12}>
                <DataTable
                    url={`${process.env.REACT_APP_API_URL}/agent/360-report`}
                    columns={[
                        {
                            name: 'unique_code',
                            label: 'Referal Code',
                            type: 'string',
                            filterable: true
                        },
                        {
                            name: 'name',
                            label: 'Name',
                            type: 'string',
                            filterable: true
                        },
                        {
                            name: 'nrk',
                            label: 'NRK',
                            type: 'string',
                            filterable: true
                        },
                        {
                            name: 'assigned_project_name',
                            label: 'Project',
                            type: 'string',
                            filterable: false,
                            render: (data: string | null) => {
                                return data || '-'
                            }
                        },
                        {
                            name: 'type',
                            label: 'Agent Type',
                            type: 'string',
                            filterable: true,
                            defaultOption: 'all',
                            options: [
                                {
                                    key: 'all',
                                    value: 'All'
                                },
                                ...agentTypes
                            ],
                            render: (data: string | null) => {
                                return agentTypes.find(agentType => agentType.key === data)?.value
                            }
                        },
                        {
                            name: 'upv',
                            label: 'UPV',
                            type: 'string',
                            filterable: false
                        },
                    ]}
                    customParams={customParams}
                />
            </Grid>
        </Grid>
    );
}

export default Agent360Report;