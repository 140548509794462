import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IAgentTeam } from '../AgentTeam'
import AsyncMultipleAutoComplete from '../../../_components/_form/AsycMultipleAutoComplete'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import LoadingScreen from '../../../_components/LoadingScreen'

type Props = {
    open: boolean
    type: string
    onClose: (refresh: boolean) => void
    data: IAgentTeam | null
}

interface IState {
    name: string,
    agents: { label: string, id: string }[]
    clusters: { label: string, id: string }[]
}

const initialState = {
    name: '',
    agents: [],
    clusters: []
}

const AgentTeamFormModal = (props: Props) => {
    const [state, setState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (name: keyof IState, value: any) => {
        if (name !== 'name') {
            return setState(prev => ({
                ...prev,
                [name]: value
            }))
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        let axios;
        let message = ""

        switch (props.type) {
            case 'name':
                if (props.data) {
                    axios = DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/agent-team/${props.data.id}`, { name: state.name })
                } else {
                    axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/agent-team`, { name: state.name })
                }
                message = "Berhasil membuat tim!"
                break;
            case 'agents':
                axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/agent-team/assign-agent`, { id: props.data?.id || "", agents: state.agents.map((data) => data.id) })
                message = "Berhasil Assign Agent!"
                break;
            case 'clusters':
                axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/agent-team/assign-cluster`, { id: props.data?.id || "", clusters: state.clusters.map((data) => data.id) })
                message = "Berhasil Assign Cluster"
                break
            default:
                return
        }

        setIsLoading(true)
        axios
            .then((res) => {
                renderToastSuccess(message)
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (props.open) {
            if (props.data !== null) {
                const agentIds = props.data.agents_id ? props.data.agents_id.split(',') : []
                const agentNames = props.data.agents_name ? props.data.agents_name.split(',') : []
                const clusterIds = props.data.clusters_id ? props.data.clusters_id.split(',') : []
                const clusterNames = props.data.clusters_name ? props.data.clusters_name.split(',') : []

                setState({
                    name: props.data.name,
                    agents: agentIds.map((id, idx) => ({
                        id: id,
                        label: agentNames[idx] || ""
                    })),
                    clusters: clusterIds.map((id, idx) => ({
                        id: id,
                        label: clusterNames[idx] || ""
                    }))
                })
            }
        } else {
            setState(initialState)
        }
    }, [props.data, props.open])


    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>
                {
                    props.type === 'name' ?
                        'Add / Edit Team' :
                        props.type === 'agents' ?
                            "Assign Agents" :
                            props.type === 'clusters' ?
                            "Assign Clusters" :
                            ""
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    {
                        props.type === "name" &&
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                name='name'
                                value={state.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e.target.name as keyof IState, e.target.value)
                                }}
                                fullWidth
                            >

                            </TextField>
                        </Grid>
                    }
                    {
                        props.type === "agents" &&
                        <Grid item xs={12}>
                            <AsyncMultipleAutoComplete
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                                label='Assign Agent'
                                name='agents'
                                onChange={handleChange}
                                value={state.agents as any}
                            />
                        </Grid>
                    }
                    {
                        props.type === "clusters" &&
                        <Grid item xs={12}>
                            <AsyncMultipleAutoComplete
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster`}
                                label='Assign Cluster'
                                name='clusters'
                                onChange={handleChange}
                                value={state.clusters as any}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Button variant='contained' onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AgentTeamFormModal