import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

/* 
* Components
*/
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'

/* 
* Utils
*/
import { generalErrorHandler, inputPhoneNumber } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'
import Swal from 'sweetalert2'

/* 
* Interfaces
*/

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    id: string
}

interface IState {
    id: string
    name: string,
    phone: string,
    cluster_id: string,
    assigned_agent_id: string,
    listing_id?: string | null,
    cluster_name?: string,
    agent_name?: string,
    listing_name?: string
}

const EditEnquiryModal = (props: IProps) => {

    const [state, setState] = useState<IState>(initialState)
    const [count, setCount] = useState(1)

    const validateNameValue = (inputName: string, inputValue: any) => {
        let name = inputName
        let value = inputValue

        if (inputName === 'phone') {
            value = inputPhoneNumber(inputValue as string)
        }

        if (inputName === 'cluster_id' || inputName === 'listing_id' || inputName === 'assigned_agent_id') {
            value = inputValue.id || null
        }

        if (inputName === 'cluster_id') {
            setState(prev => ({
                ...prev,
                listing_id: null,
                listing_name: ''
            }))
        }

        return { name, value }
    }

    const fetchData = (id: string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/enquiry/${id}`)
            .then(res => res.data)
            .then(res => res && setState(res))
            .catch(err => generalErrorHandler(err))
    }

    const submitData = (id: string) => {
        if (!state.listing_id) {
            delete state.listing_id
        }
        delete state.cluster_name
        delete state.listing_name
        delete state.agent_name

        DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/enquiry/${id}`, state)
            .then(res => res.data)
            .then(res => {
                if (res) {
                    Swal.fire('Success', 'Edit Leads Success!', 'success')
                    props.onClose(true)
                }
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleChangeInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = validateNameValue(evt.target.name, evt.target.value)

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleChangeInputAutocomplete = (e: any, inputName: string) => {
        if (!e.target.value) {
            setCount(count + 1)
            const { name, value } = validateNameValue(inputName, { id: null })

            setState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }


    const handleChangeAutoComplete = (inputName: string, inputValue: any) => {
        const { name, value } = validateNameValue(inputName, inputValue)

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    useEffect(() => {
        if (props.open && props.id) {
            fetchData(props.id)
        }
        return () => {
            setState(initialState)
        }
    }, [props.open, props.id])

    return (
        <Dialog open={props.open} onClose={() => props.onClose(false)} fullWidth maxWidth='md'>
            <DialogTitle>Edit Enquiry</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            label='Nama'
                            fullWidth
                            name='name'
                            value={state.name}
                            onChange={handleChangeInput}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label='No. Hp'
                            fullWidth
                            name='phone'
                            value={state.phone}
                            onChange={handleChangeInput}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                            label='Agent'
                            name='assigned_agent_id'
                            onInputChange={handleChangeInputAutocomplete}
                            initialQuery={state.agent_name ? state.agent_name : ''}
                            onChange={handleChangeAutoComplete}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster`}
                            label='Cluster'
                            name='cluster_id'
                            onInputChange={handleChangeInputAutocomplete}
                            initialQuery={state.cluster_name ? state.cluster_name : ''}
                            onChange={handleChangeAutoComplete}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            key={count}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/listing?cluster_id=${state.cluster_id}`}
                            name='listing_id'
                            label='Listing'
                            onInputChange={handleChangeInputAutocomplete}
                            disabled={!state.cluster_id}
                            initialQuery={state.cluster_id ? state.listing_name : ''}
                            onChange={handleChangeAutoComplete}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        submitData(props.id)
                    }}
                    color='primary'>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const initialState = {
    id: '',
    name: '',
    phone: '',
    cluster_id: '',
    assigned_agent_id: '',
    listing_id: null,
    cluster_name: '',
    listing_name: '',
    agent_name: '',
}

export default EditEnquiryModal