import React, { useEffect, useState } from 'react';
import { Button, Link, Switch } from '@mui/material';
import { Link as RouterLink, useParams } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icon
 */

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import AccountDetail from './AccountDetail';
import Search from './_components/Search';
import { usePermission } from '../../_providers/PermissionProvider';

interface IProps {
}

interface IParams {
    account_id?: string
}

export interface IState {
    file: File | null;
}

const AccountList = (props: IProps) => {
    const permissions = usePermission()
    const ACCOUNT_URL = process.env.REACT_APP_API_URL + '/account';
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDashboardState, setModalDashboardState] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams<IParams>()

    const handleOpenDashboard = () => setModalDashboardState(true);
    const handleCloseDashboard = (refetch?: boolean) => {
        setModalDashboardState(false)
        window.history.pushState([], 'Account List', `/account`);
        if (refetch) {
            setReDrawDatatable(new Date().getTime());
        }
    };

    const submitSuccess = () => {
        setReDrawDatatable(new Date().getTime());
    }

    const renderAffiliate = (data: number) => {
        let label = ''

        switch (data) {
            case 0:
                label = 'Non Affiliate'
                break;
            case 1:
                label = 'Waiting for Verify'
                break;
            case 2:
                label = 'Verified'
                break;
            case 3:
                label = 'Rejected'
        }

        return label
    }

    const columnsTable = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true,
            sortable: true,
            render: (data: any, row: any) => {
                return (
                    permissions['account.see-detail'] ?
                        <Link
                            style={{ cursor: "pointer" }}
                            component={RouterLink}
                            to={`/account/${row.id}`}
                        >
                            {data}
                        </Link>
                        : data
                );
            }
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'is_active',
            label: 'Active',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                },
            ],
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return permissions['account.toggle-active'] ?
                    <Switch
                        checked={+data === 1}
                        onChange={() => handleActive(row, setRows, rows)}
                        color="secondary"
                    />
                    : data ? 'Yes' : 'No'
            }
        },
        {
            name: 'affiliate_type_label',
            label: 'Affiliate',
            type: 'string',
            filterable: true,
            sortable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'None'
                },
                {
                    key: '1',
                    value: 'LLV'
                },
                {
                    key: '2',
                    value: 'Sinar Mas Pillar'
                },
            ],
        },
        {
            name: 'affiliate_status',
            label: 'Affiliate Status',
            type: 'string',
            filterable: true,
            sortable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Waiting for Verify'
                },
                {
                    key: '2',
                    value: 'Verified'
                },
                {
                    key: '3',
                    value: 'Rejected'
                },
            ],
            render: (data: any) => {
                return renderAffiliate(data)
            }
        },
    ]

    const handleActive = (data: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status Active?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${ACCOUNT_URL}/toggle-active`, { id: data.id })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +data.id) element['is_active'] = data.is_active ? 0 : 1;
                                return element
                            })
                            setRows(newRows);
                            renderToastSuccess('Status berhasil dirubah')
                            setReDrawDatatable(new Date().getTime())
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    useEffect(() => {
        if (typeof params.account_id !== "undefined") {
            setSelectedId(params.account_id);
            handleOpenDashboard();
        }
    }, [params]);

    const handleExport = () => {
        setIsLoading(true);
        const queryParams = window.location.search
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/account/export${queryParams}`,
            { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `account-list.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <AccountDetail
                open={modalDashboardState}
                onClose={handleCloseDashboard}
                onSubmitSuccess={submitSuccess}
                accountId={selectedId}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {permissions['account.see-detail'] &&
                    <Search />
                }
                <div style={{ marginLeft: 'auto' }}>
                    {permissions['account.export'] ?
                        <Button
                            variant='contained'
                            onClick={handleExport}
                        >
                            Export
                        </Button>
                        : null
                    }
                </div>
            </div>
            <br></br>
            <DataTable
                reDraw={reDrawDatatable}
                url={ACCOUNT_URL}
                columns={columnsTable}
            />
        </>
    )
}

export default AccountList;
