import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Dialog, AppBar, Toolbar, IconButton, Slide, Theme } from '@mui/material';

/**
 * Table
 */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../../_components/LoadingScreen';

interface IProps {
    isOpen: boolean;
    adminId: string;
    onClose: Function;
}

interface IAdmin {
    id: string;
    name: string;
}

interface IListMember {
    id: string;
    name: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

export default function AdminAgentMemberModal({ isOpen, adminId, onClose }: IProps) {
    const API_URL = process.env.REACT_APP_API_URL + '/admin-agent-member'
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [admin, setAdmin] = useState<IAdmin>({ id: '', name: "" });
    const [listMembers, setListMembers] = useState<Array<IListMember>>([]);
    const [selectedCaptain, setSelectedCaptain] = useState('');

    const addCaptain = () => {
        if (selectedCaptain === '') {
            alert('No Agent Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah captain ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    let data = {
                        admin_agent_id: admin.id,
                        captain_id: selectedCaptain
                    }
                    DefaultAxios.post(API_URL, data)
                    .then(res => {
                        loadData();
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
                }
            });
        }
    }

    const handleClose = () => {
        onClose();
    }

    const deleteCaptainMember = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus member ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                /**
                 * Delete from List
                 */
                DefaultAxios
                .delete(`${API_URL}/${id}`)
                .then(res => {
                    loadData();
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    useEffect(() => {
        if (adminId !== '') {
            loadData();
        }
        // eslint-disable-next-line
    }, [adminId]);

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(API_URL, { params: { id: adminId } })
            .then(res => {
                setAdmin(res.data.admin as IAdmin);
                setListMembers(res.data.members as Array<IListMember>)
                setSelectedCaptain('')
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedCaptain(value.id.toString());
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedCaptain('');
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <LoadingScreen open={isLoading} fullScreen />
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Admin Agent Member
                        </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Typography variant="h4">
                    Admin Agent Member - {admin.name}
                </Typography>

                <br></br>

                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setAddFormState(!addFormState) }}
                >
                    Tambah Advisor
                </Button>

                <br></br> */}

                {/* {addFormState && */}
                    <div style={{ display: "flex", marginTop: "20px", width: 500 }}>
                        <br></br>
                        <AsyncAutoComplete 
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user?roles=agent-captain`}
                            label="Tambah Captain"
                            name="captain_id"
                            initialQuery={''}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            placeholder="Masukan Nama Captain"
                        />

                        <br></br>

                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            disabled={selectedCaptain === ''}
                            onClick={addCaptain}
                        >
                            Submit
                        </Button>
                    </div>
                {/* } */}

                <br></br>
                <br></br>

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listMembers.map((row, key) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => deleteCaptainMember(row.id)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    )
}