import { Button, Grid, Theme, } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useState } from 'react';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import PublishIcon from '@mui/icons-material/Publish';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import LoadingScreen from '../../_components/LoadingScreen';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
    })
)

const LogUserLogin = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/user-login-logs';
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}/export/user-login-logs.xlsx${window.location.search}`, {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `user-login-logs.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const columns = [
        {
            name: 'datetime',
            label: 'Tanggal',
            type: 'date',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'unique_code',
            label: 'Unique Code',
            type: 'string',
            filterable: true
        },
        {
            name: 'user_name',
            label: 'Nama User',
            type: 'string',
            filterable: true
        },
        {
            name: 'ip',
            label: 'IP',
            type: 'string',
            filterable: true
        },
        {
            name: 'success',
            label: 'Success',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            filterable: true,
            render: (data: any) => {
                return +data === 1 ? 'Yes' : 'No';
            }
        },
        {
            name: 'header',
            label: 'Header',
            type: 'string',
            filterable: true
        },
        {
            name: 'notes',
            label: 'Notes',
            type: 'string',
            filterable: true
        },
    ];

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container direction="row-reverse" className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    size="small"
                    onClick={handleExport}
                    startIcon={<PublishIcon />}
                >
                    Export
                </Button>
            </Grid>
            <DataTable
                url={API_URL}
                columns={columns}
            />
        </>
    );
}

export default LogUserLogin;