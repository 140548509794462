import React, { Fragment, useState, useEffect, SyntheticEvent } from 'react';
import { Grid, Button, ButtonGroup, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../LoadingScreen';
import ImageForm from './Form';
import SiteHomepageBannerForm from '../../../_pages/_siteHomepageBanner/_components/SiteHomepageBannerForm';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generalErrorHandler, renderWarningButton, resizeAndResetOrientationImage } from '../../../_utils/Helper';
import { usePermission } from '../../../_providers/PermissionProvider';

interface Props {
    API_URL: string;
    wishForHome?: boolean;
    siteHomepage?: boolean;
    placeholder?: string;
    type?: 'cluster' | 'listing';
}

export interface IState {
    id: string;
    caption: string;
    category: string;
    category_text: string;
    alt: string;
    thumbnail: string;
    file: File | null;
    file_mobile: File | null;
    sort: number;
    url?: string;
}

export interface ICategoryList {
    key: string;
    value: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        submitButton: {
            float: 'right'
        }
    })
);

const ImageFormPage = ({ API_URL, wishForHome = false, placeholder, type, siteHomepage = false }: Props) => {
    const permissions = usePermission()
    const classes = useStyles();

    const [formStates, setFormStates] = useState<IState[]>([]);
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState('');

    const defaultState: IState = {
        id: '',
        caption: '',
        category: '',
        category_text: '',
        alt: '',
        thumbnail: '',
        file: null,
        file_mobile: null,
        sort: 0,
        url: '',
    };

    const shophouseListingCategories = [
        {
            key: 'building',
            value: 'Building'
        },
        {
            key: 'functional_room',
            value: 'Functional Room'
        },
        {
            key: 'interior',
            value: 'Interior'
        },
        {
            key: 'siteplan',
            value: 'Siteplan'
        },
    ];

    const categoryLists: any = {
        'cluster': {
            'apartment': [
                {
                    key: 'building',
                    value: 'Building'
                },
                {
                    key: 'facilities',
                    value: 'Facilities'
                },
                {
                    key: 'floor_plan',
                    value: 'Floor Plan'
                },
                {
                    key: 'masterplan',
                    value: 'Master Plan'
                }
            ],
            'residential': [
                {
                    key: 'cluster_area',
                    value: 'Cluster Area'
                },
                {
                    key: 'facilities',
                    value: 'Facilities'
                },
                {
                    key: 'unit_type',
                    value: 'Unit Type'
                },
                {
                    key: 'siteplan',
                    value: 'Site Plan'
                },
                {
                    key: 'masterplan',
                    value: 'Master Plan'
                }
            ],
            'shophouse': [
                {
                    key: 'surrounding_area',
                    value: 'Surrounding Area'
                },
                {
                    key: 'front_view',
                    value: 'Front View'
                },
                {
                    key: 'interior',
                    value: 'Interior'
                },
                {
                    key: 'siteplan',
                    value: 'Site Plan'
                }
            ]
        },
        'listing': {
            'Apartment': [
                {
                    key: 'living_room',
                    value: 'Living Room'
                },
                {
                    key: 'bedroom',
                    value: 'Bedroom'
                },
                {
                    key: 'bathroom',
                    value: 'Bathroom'
                },
                {
                    key: 'kitchen_and_dining_room',
                    value: 'Kitchen & Dining Room'
                },
                {
                    key: 'siteplan',
                    value: 'Siteplan'
                },
            ],
            'Residential': [
                {
                    key: 'frontyard',
                    value: 'Frontyard'
                },
                {
                    key: 'living_room',
                    value: 'Living Room'
                },
                {
                    key: 'bedroom',
                    value: 'Bedroom'
                },
                {
                    key: 'bathroom',
                    value: 'Bathroom'
                },
                {
                    key: 'kitchen_and_dining_room',
                    value: 'Kitchen & Dining Room'
                },
                {
                    key: 'siteplan',
                    value: 'Siteplan'
                },
            ],
            'Kavling': [
                {
                    key: 'surrounding_area',
                    value: 'Surrounding Area'
                },
                {
                    key: 'kavling_view',
                    value: 'Kavling View'
                },
                {
                    key: 'siteplan',
                    value: 'Siteplan'
                },
            ],
            'Ruko': shophouseListingCategories,
            'Business Loft': shophouseListingCategories,
            'Kiosk': shophouseListingCategories,
            'Warehouse': shophouseListingCategories,
            'Boutique SOHO': shophouseListingCategories,
        }
    };

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const imageGallery = res.data.images;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        caption: imageGallery[key].caption,
                        category: imageGallery[key].category,
                        category_text: imageGallery[key].category_text,
                        url: imageGallery[key].url,
                        alt: imageGallery[key].alt,
                        thumbnail: imageGallery[key].image,
                        file: null,
                        file_mobile: null,
                        sort: imageGallery[key].sort
                    })
                }

                setImageStates(newImageStates);

                if (typeof type !== 'undefined') {
                    setCategory(res.data[type]['category']);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        setMode('add');
        setFormStates([
            {
                ...defaultState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleChanged = async (e: SyntheticEvent, index: number) => {
        const oldStates: any[] = formStates.slice();
        const target = e.target as HTMLInputElement
        const files = target.files as FileList

        if (['file', 'file_mobile'].includes(target.name)) {
            if (target && files[0]) {
                try {
                    const file: File = await resizeAndResetOrientationImage(files[0])
                    oldStates[index]['thumbnail'] = URL.createObjectURL(file);
                    oldStates[index][target.name] = file;
                } catch (err) {
                    console.log(err)
                }
            }
        } else {
            oldStates[index][target.name] = target.value;
        }
        setFormStates(oldStates);
    }

    const handleSelect = (index: number, name: string, value: string | null) => {
        const oldStates: any[] = formStates.slice();
        oldStates[index][name] = value || ''
        setFormStates(oldStates);
    }

    const formAction = (action: string) => {
        const oldState = formStates.slice();
        switch (action) {
            case 'add':
                oldState.push({ ...defaultState });
                break;
            case 'remove':
                oldState.splice(oldState.length - 1, 1);
                break;
            default:
                break;
        }
        setFormStates(oldState);
    }

    const renderForms = () => {
        const formsEl = formStates.map((state, key) => {
            if (siteHomepage) {
                return (
                    <Fragment key={key}>
                        <SiteHomepageBannerForm
                            form={state}
                            onChange={handleChanged}
                            onSelect={handleSelect}
                            index={key}
                            placeholder={placeholder}
                        />
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment key={key}>
                        <ImageForm
                            form={state}
                            onChange={handleChanged}
                            onSelect={handleSelect}
                            index={key}
                            placeholder={placeholder}
                            wishForHome={wishForHome ? true : false}
                            categoryLists={typeof type !== 'undefined' && category !== '' ? categoryLists[type][category] : []}
                        />
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                    </Fragment>
                )
            }
        });

        const actionButtons = (
            <>
                <Grid item xs={12}>
                    {
                        mode === 'add'
                            ? <ButtonGroup disableElevation variant="contained">
                                <Button color="primary" onClick={() => formAction('add')}>+</Button>
                                <Button color="secondary" onClick={() => formAction('remove')}>-</Button>
                            </ButtonGroup>
                            : null
                    }
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )

        return (
            <>
                {formsEl}
                {formStates.length ? actionButtons : null}
            </>
        );
    }

    const handleEditImage = (imageState: IState) => {
        setMode('edit');
        setFormStates([
            {
                ...imageState,
                category: imageState.category || 'other'
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleDeleteImage = (imageState: IState) => {
        renderWarningButton("Apakah anda yakin ingin menghapus image ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${imageState.id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadImages();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const checkValidation = () => {
        let isValid = true;

        formStates.map(formState => {
            if (!formState.file && mode === 'add') {
                isValid = false;
            }

            if (siteHomepage && !formState.file_mobile && mode === 'add') {
                isValid = false;
            }

            if (!formState.caption && wishForHome === false) {
                isValid = false;
            }

            if (!formState.category && wishForHome === false) {
                isValid = false;
            }

            return true;
        });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            Swal.fire({
                title: "Error",
                text: 'Please fill caption and file on every form',
                icon: 'error'
            });
            return;
        }

        let url;
        const fd = new FormData();

        if (mode === 'add') {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append(`param[${i}][image]`, value as File);
                        } else if (key === 'file_mobile') {
                            fd.append(`param[${i}][image_mobile]`, value as File);
                        } else {
                            fd.append(`param[${i}][${key}]`, String(value));
                        }
                    }
                }
            }

            url = API_URL;
        } else {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append('image', value as File);
                        } else if (key === 'file_mobile') {
                            fd.append('image_mobile', value as File);
                        } else {
                            fd.append(`${key}`, String(value));
                        }
                    }
                }
            }

            url = API_URL + '/' + formStates[0].id;
            fd.append('_method', 'PATCH');
        }

        setIsLoading(true);
        DefaultAxios.post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Submit image berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadImages();
                        setFormStates([]);
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSortImage = (imageId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/' + imageId + '/sort-image', { dir })
            .then(res => {
                loadImages();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const checkDisableSort = (key: number, dir: 'up' | 'down') => {
        let shouldDisable = false;
        const imageState = { ...imageStates[key] };
        const category = imageState.category;

        const filteredImageStates = imageStates.filter(imageState => imageState.category === category);

        if (dir === 'up') {
            if (imageState.id === filteredImageStates[0].id) {
                shouldDisable = true;
            }
        } else {
            if (imageState.id === filteredImageStates[filteredImageStates.length - 1].id) {
                shouldDisable = true;
            }
        }

        return shouldDisable;
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                {
                    (!type || (permissions[`${type}.manage`] && permissions[`${type}.manage`])) &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNewData}
                    >
                        Add Image
                    </Button>
                }
                <hr />
            </Grid>

            {renderForms()}

            <Grid item xs={12}>
                <hr />
            </Grid>

            {/* {renderImages()} */}
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    wishForHome ?
                                        <TableCell>URL</TableCell>
                                        :
                                        <TableCell>Caption</TableCell>
                                }
                                {
                                    !wishForHome &&
                                    <TableCell>Category</TableCell>
                                }
                                <TableCell>Thumbnail</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageStates.map((imageState, key) => (
                                <TableRow key={imageState.id}>
                                    <TableCell component="th" scope="row">
                                        {wishForHome ? imageState.url : imageState.caption}
                                    </TableCell>
                                    {
                                        !wishForHome &&
                                        <TableCell>
                                            {imageState.category_text}
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <img src={imageState.thumbnail} height="100px" alt={imageState.caption} />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" color="primary" onClick={() => handleEditImage(imageState)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteImage(imageState)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'up', key)}
                                            disabled={key === 0 || checkDisableSort(key, 'up')}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'down', key)}
                                            disabled={key === imageStates.length - 1 || checkDisableSort(key, 'down')}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Fragment>
    );
}

export default ImageFormPage;
