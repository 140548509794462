import React, { useEffect, useMemo } from 'react';
import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import {useDropzone} from 'react-dropzone';

export interface DragAndDropProps {
    onChange: (files: File[]) => void
    pdfOnly?: boolean
}

const DragAndDrop = (props: DragAndDropProps) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const styles = useStyles()
    
    const files = useMemo(() => acceptedFiles.map(file => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    )), [acceptedFiles])

    useEffect(() => {
        props.onChange(acceptedFiles)
    }, [acceptedFiles, props])

    return (
        <section style={{

        }}>
            <div {...getRootProps({className: styles.dropzone})}>
                <input {...getInputProps()} />
                <p>{`Drag and drop ${!props.pdfOnly ? 'some files' : 'PDF'} here, or click to select ${!props.pdfOnly ? 'files' : 'PDF'}`}</p>
                {
                    !!files.length &&
                    <ul>{files}</ul>
                }
            </div>
        </section>
    )
}

export default DragAndDrop

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dropzone: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            cursor: 'pointer',
        }
    })
);