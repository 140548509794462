import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import DragAndDrop from '../../../_components/_formElements/DragAndDrop'

export interface UploadModalProps {
    isOpen: boolean
    onClose: () => void
    onChange: (files: File[]) => void
    onSubmit: () => void
}

const UploadModal = (props: UploadModalProps) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Add File</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <DragAndDrop
                            onChange={props.onChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={props.onSubmit}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadModal
