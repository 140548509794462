import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

interface Props {
    label?: string;
    value: Date | null;
    format?: string;
    onChange: (date: Date | null, value?: string | null | undefined) => void;
    placeholder?: string;
    error?: boolean
    helperText?: string
    disabled?: boolean
}

const LocalDatePicker = (componentProps: Props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* TODO: might not work */}
            {/* Should be working now, but leave the the comment above to search for it easier later if problems occurs */}
            <DatePicker
                value={componentProps.value}
                inputFormat={componentProps.format ? componentProps.format : "dd/MM/yyyy"}
                onChange={newValue => componentProps.onChange(newValue as Date)}
                disabled={componentProps.disabled}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        variant="outlined"
                        fullWidth
                        label={componentProps.label}
                        // KeyboardButtonProps={{
                        //     'aria-label': 'change date',
                        // }}
                        placeholder={componentProps.placeholder ? componentProps.placeholder : "dd/MM/yyyy"}
                        error={componentProps.error ? componentProps.error : false}
                        helperText={componentProps.helperText ? componentProps.helperText : ''}
                        disabled={componentProps.disabled}
                    />
                )}
            />
        </LocalizationProvider>
    );
}

export default LocalDatePicker;