import React, { Fragment, useState } from 'react';
import { Tooltip, IconButton, Button, Link, Grid, Theme, TextField, Switch } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { Link as RouterLink } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 *Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';

/**
 * Helpers
 */
import { currencyToNumber, generalErrorHandler, numberToCurrency, dateFormat, renderWarningButton } from '../../_utils/Helper';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

interface IPriceState {
	isEdit: boolean;
	value: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonContainer: {
			marginBottom: theme.spacing(3)
		},
		actionContainer: {
			display: 'flex'
		},
		addButton: {
			marginBottom: '20px'
		},
		importButton: {
			float: 'right'
		},
		errorContainer: {
			maxHeight: '200px',
			overflowY: 'auto'
		},
		priceContainer: {
			display: 'flex',
			alignItems: 'center'
		},
	})
)

const ListingList = (props: Props) => {
	const API_URL = `${process.env.REACT_APP_API_URL}/listing`;
	const classes = useStyles();
	const permissions = usePermission()
	const [isLoading, setIsLoading] = useState(false);
	const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
	const [tempPrice, setTempPrice] = useState('');
	const [priceState, setPriceState] = useState<IPriceState[]>([]);

	const handleApprove = (id: string, setRows: Function, rows: any[]) => {
		renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
			.then((result) => {
				if (result.value) {
					DefaultAxios
						.post(`${API_URL}/approve`, { id })
						.then(res => {
							setReDrawDatatable(new Date().getTime());
						})
						.catch(error => {
							generalErrorHandler(error)
						})
						.finally(() => {
							setIsLoading(false);
						})
				}
			})
	}

	const handleReject = (id: string, setRows: Function, rows: any[]) => {
		renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
			.then((result) => {
				if (result.value) {
					if (result.value) {
						DefaultAxios
							.post(`${API_URL}/reject`, { id })
							.then(res => {
								setReDrawDatatable(new Date().getTime());
							})
							.catch(error => {
								generalErrorHandler(error)
							})
							.finally(() => {
								setIsLoading(false);
							})
					}
				}
			})
	}

	const handleEditPrice = (id: string, price: string, index: number) => {
		if (+price === 0) {
			return;
		}

		setIsLoading(true);
		renderWarningButton("Apakah anda yakin ingin mengganti harga ini?")
			.then((result) => {
				if (result.value) {
					DefaultAxios
						.post(`${API_URL}/change-price`, { listing_id: id, price: price })
						.then(res => {
							Swal.fire({
								title: "Berhasil mengubah harga",
								icon: 'success',
								timer: 1000,
								onAfterClose: () => {
									setPriceState(prevState => {
										const newState = prevState.slice();
										newState[index].isEdit = !newState[index].isEdit;
										return newState;
									});
								}
							});
						})
						.catch(error => {
							generalErrorHandler(error)
						})
						.finally(() => {
							setIsLoading(false);
						})

				}
			})
	}

	const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files[0]) {
			const fd = new FormData();
			fd.append('import_file', files[0]);

			setIsLoading(true);
			DefaultAxios
				.post(`${API_URL}/import`, fd)
				.then((res: { data: string[] }) => {
					let title = 'Upload successful';
					let message = '';
					let icon: 'success' | 'warning' = 'success';

					if (res.data.length) {
						title = 'Upload with partiel error';
						icon = 'warning';
						message = '<ul class="' + classes.errorContainer + '">';
						for (let m of res.data) {
							message += `<li>${m}</li>`;
						}
						message += '</ul>';
					}

					Swal.fire({
						title: title,
						html: message,
						icon: icon
					});
					setReDrawDatatable(new Date().getTime());
				})
				.catch(err => {
					generalErrorHandler(err);
				})
				.finally(() => {
					document.querySelectorAll('[type="file"]').forEach(el => {
						(el as HTMLInputElement).value = '';
					})
					setIsLoading(false);
				});
		}
	}

	const handleExport = () => {
		setIsLoading(true);
		DefaultAxios
			.get(`${API_URL}/export`, {
				responseType: 'blob'
			})
			.then(res => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `listings.xlsx`); //or any other extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch(err => {
				generalErrorHandler(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}

	const handlePreview = (id: string) => {
		DefaultAxios
			.post(`${process.env.REACT_APP_API_URL}/generate-preview-redis`, { type: 'listing', id })
			.then(res => {
				window.open(`${process.env.REACT_APP_MAIN_URL}/preview/listing/${id}`);
			})
			.catch(err => {
				generalErrorHandler(err);
			})
	}

	const handleDelete = (id: string) => {
		renderWarningButton("Apakah anda yakin ingin menghapus listing ini?")
			.then((result) => {
				if (result.value) {
					DefaultAxios
						.delete(`${API_URL}/${id}`)
						.then(res => {
							Swal.fire({
								title: 'Delete successful',
								icon: 'success',
								onAfterClose: () => {
									setReDrawDatatable(new Date().getTime());
								},
								timer: 1000
							})
						})
						.catch(error => {
							generalErrorHandler(error)
						})
						.finally(() => {
							setIsLoading(false);
						})
				}
			})
	}

	const handleStockSwitch = (row: any, setRows: Function, rows: any[]) => {
		renderWarningButton("Apakah anda yakin ingin mengganti stock ini?")
			.then((result) => {
				if (result.value) {
					setIsLoading(true)

					DefaultAxios.post(`${API_URL}/toggle-stock`, { id: row.id })
						.then(() => {
							const newRows = rows.map((element: any) => {
								if (+element.id === +row.id) element['stock'] = element['stock'] === 0 ? 1 : 0
								return element
							})
							setRows(newRows);
							setReDrawDatatable(new Date().getTime());
						})
						.catch(generalErrorHandler)
						.finally(() => {
							setIsLoading(false)
						})
				}
			})
	}

	const getUrlParameter = (resolve?: (value?: unknown) => void) => {
		let sPageURL = decodeURIComponent(window.location.search.substring(1))
		sessionStorage.setItem('urlParam', sPageURL)
	}

	const handleDetail = (id: string) => {
		getUrlParameter()
	}

	const handleEdit = (code: string) => {
		getUrlParameter()
		window.open(`/listing/edit/${code}`)
	}

	return (
		<Fragment>
			<LoadingScreen open={isLoading} fullScreen />
			{
				permissions['listing.add'] &&
				<Grid container justifyContent="space-between" className={classes.buttonContainer}>
					<Grid item>
						{
							permissions['listing.manage'] &&
							<RouterLink to="/listing/add">
								<Button
									variant="contained"
									color="primary"
									component="label"
									size="small"
									startIcon={<AddIcon />}
								>
									Add Listing
								</Button>
							</RouterLink>
						}
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							component="label"
							onClick={handleExport}
							startIcon={<PublishIcon />}
							size="small"
							style={{ marginRight: "5px" }}
						>
							Export
						</Button>
						<Button
							variant="contained"
							color="primary"
							component="label"
							startIcon={<GetAppIcon />}
							size="small"
						>
							Import
						</Button>
						<input
							type="file"
							style={{ display: "none" }}
							accept=".xls,.xlsx"
							onChange={handleImportFileChanged}
						/>
					</Grid>
				</Grid>
			}

			<DataTable
				url={API_URL}
				reDraw={reDrawDatatable}
				columns={[
					{
						name: 'name',
						label: 'Nama',
						type: 'string',
						filterable: true,
						render: (data: any, row: any) => {
							return (
								<>
									<Link
										component={RouterLink}
										to={`/listing/detail/${row.code}`}
										onClick={() => handleDetail(row.code)}
									>
										{data}
									</Link>
								</>
							);
						}
					},
					{
						name: 'project_name',
						label: 'Project',
						type: 'string',
						filterable: true
					},
					{
						name: 'subproject_name',
						label: 'Subproject',
						type: 'string',
						filterable: true
					},
					{
						name: 'cluster_name',
						label: 'Cluster',
						type: 'string',
						filterable: true
					},
					{
						name: 'code',
						label: 'Code',
						type: 'string',
						filterable: true
					},
					{
						name: 'price',
						label: 'Price',
						type: 'number',
						filterable: true,
						style: {
							minWidth: '250px'
						},
						render: (data: any, row: any, rows: any, index: number) => {
							return <div className={classes.priceContainer}>
								<TextField
									fullWidth
									variant="outlined"
									size="small"
									name="price"
									value={numberToCurrency(priceState[index].value)}
									onChange={(e) => {
										const newPriceState = priceState.slice();
										newPriceState[index].value = currencyToNumber(e.target.value).toString();
										setPriceState(newPriceState);
									}}
									disabled={!priceState[index].isEdit}
								/>

								<Tooltip title={priceState[index].isEdit ? 'Save' : 'Edit'}>
									<IconButton
										color="primary"
										component="span"
										onClick={() => {
											if (!priceState[index].isEdit) {
												setTempPrice(priceState[index].value)

												setPriceState(prevState => {
													const newState = prevState.slice();
													newState[index].isEdit = !newState[index].isEdit;
													return newState;
												});
											} else {
												handleEditPrice(row.id, priceState[index].value, index)
											}

										}}
									>
										{
											priceState[index].isEdit
												? <DoneIcon fontSize="small" />
												: <EditIcon fontSize="small" />
										}
									</IconButton>
								</Tooltip>

								{
									priceState[index].isEdit &&
									<Tooltip title="Cancel">
										<IconButton
											color="primary"
											component="span"
											onClick={() => {
												setPriceState(prevState => {
													const newState = prevState.slice();
													newState[index].value = tempPrice;
													newState[index].isEdit = !newState[index].isEdit;
													return newState;
												})
											}}
										>
											<ClearIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								}
							</div>
						}
					},
					{
						name: 'is_star',
						label: 'Star',
						filterable: true,
						sortable: false,
						options: [
							{
								key: '',
								value: 'All'
							},
							{
								key: '1',
								value: 'Yes'
							},
							{
								key: '0',
								value: 'No'
							}
						],
						defaultOption: '',
						render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
							return !!data ? 'Yes' : 'No';
						}
					},
					{
						name: 'stock',
						label: 'In Stock',
						filterable: true,
						sortable: false,
						options: [
							{
								key: '',
								value: 'All'
							},
							{
								key: '1',
								value: 'Yes'
							},
							{
								key: '0',
								value: 'No'
							}
						],
						defaultOption: '',
						render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
							return (
								<Switch
									checked={data === 1}
									onChange={() => handleStockSwitch(row, setRows, rows)}
									color="secondary"
								/>
							)
						}
					},
					{
						name: 'last_published_at',
						label: 'Tanggal Publish',
						type: 'date',
						filterable: true,
						render: (data: any) => {
							return data !== null ? dateFormat(data, 'DD MMM YYYY HH:mm') : '-';
						}
					},
					{
						name: 'status',
						label: 'Approval Status',
						filterable: true,
						sortable: false,
						options: [
							{
								key: '',
								value: 'All'
							},
							{
								key: '0',
								value: 'Not Approved'
							},
							// {
							// 	key: '1',
							// 	value: 'Approved by Marcom'
							// },
							{
								key: '2',
								value: 'Approved by Marcom Manager'
							},
							{
								key: '3',
								value: 'Published'
							},
							{
								key: '4',
								value: 'Unpublished'
							},
						],
						defaultOption: '',
						render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
							let returnLabel = "";

							switch (data) {
								case 0:
									returnLabel = "Not Approved";
									break;
								case 1:
									returnLabel = "Approved by Marcom";
									break;
								case 2:
									returnLabel = "Approved by Marcom Manager";
									break;
								case 3:
									returnLabel = "Published";
									break;
								case 4:
									returnLabel = "Unpublished";
									break;
								default:
									break;
							}

							return returnLabel;
						}
					},
					{
						name: 'EXTRA',
						label: 'Action',
						sortable: false,
						render: (row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
							const checkPermission = function (permission_type: string) {
								return typeof permissions[permission_type] !== "undefined" && permissions[permission_type]
							}

							/**
							 * Render Approve / Reject
							 */
							const ApproveComponent = ({ label }: { label: string }) => {
								return <Tooltip title={label}>
									<IconButton
										color="primary"
										component="span"
										onClick={() => handleApprove(row.id, setRows, rows)}
									>
										<DoneIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							}

							const RejectComponent = ({ label }: { label: string }) => {
								return <Tooltip title={label}>
									<IconButton
										color="secondary"
										component="span"
										onClick={() => handleReject(row.id, setRows, rows)}
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							}

							let approvalAreaComponent = (<></>);
							switch (row.status) {
								case 0: /**Still Not Approved */
									if (checkPermission('listing.marcom-approve')) {
										approvalAreaComponent = (
											<ApproveComponent label="Approve" />
										)
									}
									break;
								case 1: /**Approved by Marcom */
									if (checkPermission('listing.marcom-manager-approve')) {
										approvalAreaComponent = (
											<ApproveComponent label="Approve" />
										)
									}

									if (checkPermission('listing.marcom-manager-approve') || checkPermission('listing.marcom-approve')) {
										approvalAreaComponent = (
											<>
												{approvalAreaComponent}
												<RejectComponent label="Reject" />
											</>
										)
									}
									break;
								case 2: /**Unpublished */
									if (checkPermission('listing.publish')) {
										approvalAreaComponent = (
											<ApproveComponent label="Publish" />
										)
									}

									if (checkPermission('listing.marcom-manager-approve')) {
										approvalAreaComponent = (
											<>
												{approvalAreaComponent}
												<RejectComponent label="Reject" />
											</>
										)
									}
									break;
								case 3: /**Published */
									if (checkPermission('listing.publish')) {
										approvalAreaComponent = (
											<>
												<RejectComponent label="Unpublish" />
											</>
										)
									}
									break;
								case 4: /**Unpublished */
									if (checkPermission('listing.publish')) {
										approvalAreaComponent = (
											<>
												<ApproveComponent label="Publish" />
												<RejectComponent label="Reject" />
											</>
										)
									}
									break;
								default:
									break;
							}

							return (
								<div className={classes.actionContainer}>
									{
										checkPermission('listing.view') &&
										<Tooltip title="Edit">
											<IconButton
												color="primary"
												component="span"
												onClick={() => handleEdit(row.code)}
											>
												<EditIcon fontSize="small" />
											</IconButton>
										</Tooltip>
									}

									{
										checkPermission('listing.delete') &&
										<Tooltip title="Delete">
											<IconButton
												color="secondary"
												component="span"
												onClick={() => handleDelete(row.id)}
											>
												<DeleteIcon fontSize="small" />
											</IconButton>
										</Tooltip>
									}

									<Tooltip title="See Preview">
										<IconButton
											size="small"
											color="primary"
											onClick={() => handlePreview(row.id)}
										>
											<VisibilityIcon />
										</IconButton>
									</Tooltip>

									{approvalAreaComponent}
								</div>
							);
						}
					}
				]}
				responseCallback={(res: any) => {
					const data: any[] = res.data;
					const newPriceState: IPriceState[] = [];

					for (let key in data) {
						newPriceState.push({
							isEdit: false,
							value: res.data[key].price
						});
					}

					setPriceState(newPriceState);
				}}
			/>
		</Fragment >
	);
}

export default ListingList;