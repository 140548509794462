import Axios from 'axios';
import Swal from 'sweetalert2';

const DefaultAxios = Axios;


if (localStorage.getItem("jwt_token")) {
    DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("jwt_token")}`;
}

DefaultAxios.interceptors.response.use(response => {
    if (typeof response.headers['should-change-password'] !== 'undefined' && !localStorage.getItem('should-change-password')) {
        localStorage.setItem('should-change-password', '1');
        if (window.location.href.indexOf('profile-setting') === -1) {
            window.location.href = '/profile-setting';
        }
    }
    return response;
}, error => {
        const isLogin = error.response.config.url.match(/login/);
        if (+error.response.status === 401 && !isLogin) { // Invalid token
            localStorage.removeItem('jwt_token');
            window.location.href = '/';
        } else if (+error.response.status === 440) { // Expired token, try to refresh first
            if (!sessionStorage.getItem('prevent_multiple_refresh')) {
                sessionStorage.setItem('prevent_multiple_refresh', '1');
                return DefaultAxios
                    .post(`${process.env.REACT_APP_API_URL}/refresh`, null, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('jwt_token')}`
                        }
                    })
                    .then(res => {
                        localStorage.setItem('jwt_token', res.data);
                        error.config.headers['Authorization'] = `Bearer ${res.data}`
                        DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data}`;
    
                        return DefaultAxios.request(error.config);
                    })
                    .finally(() => {
                        sessionStorage.removeItem('prevent_multiple_refresh');
                    })
            } else {
                error.config.headers['Authorization'] = `Bearer ${localStorage.getItem('jwt_token')}`;
                return DefaultAxios.request(error.config);
            }
        } else if (+error.response.status === 403) {
            Swal.fire({
                title: "No Access",
                icon: 'error',
                // onAfterClose: () => {
                //     window.location.href = '/';
                // },
                timer: 1000
            });
            
        }
        return Promise.reject(error);
})

export default DefaultAxios;
