import { Button, Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
import DataTable from '../../_components/_dataTable/DataTable'
import { makeStyles } from '@mui/styles';
import { Edit } from '@mui/icons-material';
import AgentTeamFormModal from './_components/AgentTeamFormModal';

type Props = {}

const useStyles = makeStyles({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
});

export interface IAgentTeam {
    id: number
    name: string
    agents_id: string
    clusters_id: string
    agents_name: string
    clusters_name: string

}

const AgentTeam = (props: Props) => {
    const classes = useStyles()
    const [modalState, setModalState] = useState('')
    const [selectedRow, setSelectedRow] = useState<IAgentTeam | null>(null)
    const [redrawDatatable, setRedrawDatatable] = useState(1)

    const URL = `${process.env.REACT_APP_API_URL}/agent-team`

    const handleChange = (name: string, rows: IAgentTeam) => {
        setModalState(name)
        setSelectedRow(rows)
    }

    const columns = [
        {
            name: 'name',
            label: 'Grup',
            type: 'string',
            filterable: true,
            render: (data: string, rows: IAgentTeam) => {
                return (
                    <div className={classes.actionContainer}>
                        <p>{data || '-'}</p>
                        <IconButton onClick={() => handleChange('name', rows)}>
                            <Edit color='primary'/>
                        </IconButton>
                    </div>
                )
            }
        },
        {
            name: 'agents_name',
            label: 'Agents',
            type: 'string',
            filterable: true,
            render: (data: string, rows: IAgentTeam) => {
                return (
                    <div className={classes.actionContainer}>
                        <p>{data || '-'}</p>
                        <IconButton onClick={() => handleChange('agents', rows)}>
                            <Edit color='primary'/>
                        </IconButton>
                    </div>
                )
            }
        },
        {
            name: 'clusters_name',
            label: 'Cluster',
            type: 'string',
            filterable: true,
            render: (data: string, rows: IAgentTeam) => {
                return (
                    <div className={classes.actionContainer}>
                        <p>{data || '-'}</p>
                        <IconButton onClick={() => handleChange('clusters', rows)}>
                            <Edit color='primary'/>
                        </IconButton>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <AgentTeamFormModal 
                open={modalState !== ''}
                type={modalState}
                data={selectedRow}
                onClose={(refresh) => {
                    if (refresh) setRedrawDatatable(new Date().getTime())

                    setModalState('')
                    setSelectedRow(null)
                }}
            />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button onClick={() => setModalState('name')} variant='contained'>
                        Add Team
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        url={URL}
                        columns={columns}
                        reDraw={redrawDatatable}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default AgentTeam