import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Dialog, AppBar, Toolbar, IconButton, Slide, Theme } from '@mui/material';

/**
 * Table
 */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../../_components/LoadingScreen';

interface IProps {
    isOpen: boolean;
    project_id: number;
    onClose: Function;
}

interface IProject {
    id: number;
    name: string;
}

interface IListAdvisor {
    id: string;
    name: string;
    project_id: number;
    user_id: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

export default function ProjectPicModal({ isOpen, project_id, onClose }: IProps) {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [project, setProject] = useState<IProject>({ id: 0, name: "" });
    const [listPic, setListPic] = useState<Array<IListAdvisor>>([]);
    const [selectedMember, setSelectedMember] = useState(0);

    const addAdvisor = () => {
        if (selectedMember === 0) {
            alert('No Advisor Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah PIC ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    let data = {
                        project_id: project.id,
                        user_id: selectedMember
                    }
                    /**
                     * Add To List, send user_id + project_id
                     */
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project/pic`, data)
                    .then(res => {
                        loadData();
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
                }
            });
        }
    }

    const handleClose = () => {
        onClose();
    }

    const deleteMember = (project_id: number, user_id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus member ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                let data = {
                    project_id: project_id,
                    user_id: user_id
                }
                /**
                 * Delete from List
                 */
                DefaultAxios
                .delete(`${process.env.REACT_APP_API_URL}/project/pic/${project_id}`, { params: data})
                .then(res => {
                    loadData();
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }

    useEffect(() => {
        if (project_id !== 0) {
            loadData();
        }
        // eslint-disable-next-line
    }, [project_id]);

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project/pic`, { params: { id: project_id } })
            .then(res => {
                setProject(res.data.project as IProject);
                setListPic(res.data.project_pics as Array<IListAdvisor>)
                setSelectedMember(0)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedMember(value.id);
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedMember(0);
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <LoadingScreen open={isLoading} fullScreen />
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Project Placement
                        </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Typography variant="h4">
                    Project Placement - {project.name}
                </Typography>

                <br></br>

                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setAddFormState(!addFormState) }}
                >
                    Tambah Advisor
                </Button>

                <br></br> */}

                {/* {addFormState && */}
                    <div style={{ display: "flex", marginTop: "20px", width: 500 }}>
                        <br></br>
                        {/* <Autocomplete
                            id="combo-box-demo"
                            options={selectMembers}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 300 }}
                            onChange={(e: any, option: any) => {
                                if (option === null) {
                                    setSelectedMember(0)
                                } else {
                                    setSelectedMember(option.value)
                                }
                            }}
                            onInputChange={(e: any, option: any) => {
                            }}
                            renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                        /> */}
                        <AsyncAutoComplete 
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user`}
                            label="Tambah PIC"
                            name="user_id"
                            initialQuery={''}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            placeholder="Masukan Nama PIC"
                        />

                        <br></br>

                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            disabled={selectedMember === 0}
                            onClick={addAdvisor}
                        >
                            Submit
                        </Button>
                    </div>
                {/* } */}

                <br></br>
                <br></br>

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listPic.map((row, key) => (
                                <TableRow key={row.user_id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => deleteMember(row.project_id, row.user_id)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    )
}