import React, { useState } from 'react';
import { Button, IconButton, Link, Tooltip, Theme, Grid, Switch } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Link as RouterLink, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import ClusterMemberModal from "./_components/ClusterMemberModal";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import ClusterPlacementModal from './_components/ClusterPlacementModal';

/**
 * Icon
 */
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { usePermission } from '../../_providers/PermissionProvider';

interface IProps {
}

export interface IState {
    file: File | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
        actionContainer: {
            display: 'flex'
        }
    })
)

const ClusterList = (props: IProps) => {
    const permissions = usePermission()
    const CLUSTER_URL = process.env.REACT_APP_API_URL + '/cluster';
    const CLUSTER_IMPORT_URL = process.env.REACT_APP_API_URL + '/cluster/import-csv';
    const CLUSTER_EXPORT_URL = process.env.REACT_APP_API_URL + '/cluster/export';
    const classes = useStyles();
    const history = useHistory();
    const [clusterMemberModalValue, setClusterMemberModalValue] = useState(0);
    const [clusterPlacementModalValue, setClusterPlacementModalValue] = useState(0);
    const [clusterPlacementModalHasTeam, setClusterPlacementModalHasTeam] = useState(false);
    const [formStates, setFormStates] = useState<IState[]>([]);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [isLoading, setIsLoading] = useState(false);

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const oldStates: any[] = formStates.slice();
        const target = event.target;

        let url = CLUSTER_IMPORT_URL

        const fd = new FormData();

        fd.append('csv', target.files![0] as File);

        DefaultAxios.post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {

                    })
            })
            .catch(err => {
                // console.log(err);
                generalErrorHandler(err);
            })
            .finally(() => {
                setReDrawDatatable(new Date().getTime());
                setIsLoading(false);
                document.querySelectorAll('[type="file"]').forEach(el => {
                    (el as HTMLInputElement).value = '';
                })
            })

        setFormStates(oldStates);
    }

    const handleApprove = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    DefaultAxios
                        .post(`${CLUSTER_URL}/approve`, { id })
                        .then(res => {
                            setReDrawDatatable(new Date().getTime());
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleReject = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    if (result.value) {
                        DefaultAxios
                            .post(`${CLUSTER_URL}/reject`, { id })
                            .then(res => {
                                setReDrawDatatable(new Date().getTime());
                            })
                            .catch(error => {
                                generalErrorHandler(error)
                            })
                            .finally(() => {
                                setIsLoading(false);
                            })
                    }
                }
            })
    }

    const handlePreview = (id: string) => {
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/generate-preview-redis`, { type: 'cluster', id })
            .then(res => {
                window.open(`${process.env.REACT_APP_MAIN_URL}/preview/cluster/${id}`);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    const getUrlParameter = (resolve?: (value?: unknown) => void) => {
        let sPageURL = decodeURIComponent(window.location.search.substring(1))
        sessionStorage.setItem('urlParam', sPageURL)
    }

    const handleDetail = (id: string) => {
        getUrlParameter()
    }

    const handleEdit = (id: string) => {
        getUrlParameter()
        history.push(`cluster/edit/${id}`)
    }

    const switchPromotion = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-promotion`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime())
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const columnsTable = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true,
            sortable: true,
            render: (data: any, row: any) => {
                return (
                    <>
                        <Link
                            component={RouterLink}
                            to={`/cluster/detail/${row.id}`}
                            onClick={() => handleDetail(row.id)}
                        // onClick={() => {
                        //     handleOpenModal(row.id, row.listing_type, row.property_id)
                        //     setOpen(true)
                        // }}
                        >
                            {data}
                        </Link>
                    </>
                );
            }
        },
        {
            name: 'project_name',
            label: 'Project',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'subproject_name',
            label: 'Sub Project',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'category',
            label: 'Category',
            type: 'boolean',
            filterable: true,
            sortable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'Residential',
                    value: 'residential'
                },
                {
                    key: 'Apartment',
                    value: 'apartment'
                },
                {
                    key: 'Shophouse',
                    value: 'shophouse'
                }
            ],
            render: (data: any, row: any) => {
                return data;
                // return (data ? `Apartment` : `Non Apartment`)
            }
        },
        {
            name: 'cluster_placement_member',
            label: 'Agents',
            filterable: true,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((advisor_data, key) => {
                            if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {advisor_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        {
                            permissions['cluster.manage-member'] &&
                            <Tooltip title="Edit Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        setClusterPlacementModalHasTeam(row.has_team === 1)
                                        setClusterPlacementModalValue(row.id)
                                    }}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                );
            }
        },
        {
            name: 'status',
            label: 'Approval',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Not Approved'
                },
                // {
                //     key: '1',
                //     value: 'Approved by Marcom'
                // },
                {
                    key: '2',
                    value: 'Approved by Marcom Manager'
                },
                {
                    key: '3',
                    value: 'Published'
                },
                {
                    key: '4',
                    value: 'Unpublished'
                },
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                let returnLabel = "";

                switch (data) {
                    case 0:
                        returnLabel = "Not Approved";
                        break;
                    case 1:
                        returnLabel = "Approved by Marcom";
                        break;
                    case 2:
                        returnLabel = "Approved by Marcom Manager";
                        break;
                    case 3:
                        returnLabel = "Published";
                        break;
                    case 4:
                        returnLabel = "Unpublished";
                        break;
                    default:
                        break;
                }

                return returnLabel;
            }
        },
        ...(permissions['cluster.toggle-promotion'] ? [{
            name: 'is_special_sort',
            label: 'Promotion',
            filterable: false,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Yes'
                },
                {
                    key: '1',
                    value: 'No'
                }
            ],
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchPromotion(row.id)}
                    color="secondary"
                />
            }
        }] : []),
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            render: (row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                const checkPermission = function (permission_type: string) {
                    return typeof permissions[permission_type] !== "undefined" && permissions[permission_type]
                }

                /**
                 * Render Approve / Reject
                 */
                const ApproveComponent = ({ label }: { label: string }) => {
                    return <Tooltip title={label}>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleApprove(row.id, setRows, rows)}
                        >
                            <DoneIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                }

                const RejectComponent = ({ label }: { label: string }) => {
                    return <Tooltip title={label}>
                        <IconButton
                            color="secondary"
                            component="span"
                            onClick={() => handleReject(row.id, setRows, rows)}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                }

                let approvalAreaComponent = (<></>);
                switch (row.status) {
                    case 0: /**Still Not Approved */
                        if (checkPermission('cluster.marcom-approve')) {
                            approvalAreaComponent = (
                                <ApproveComponent label="Approve" />
                            )
                        }
                        break;
                    case 1: /**Approved by Marcom */
                        if (checkPermission('cluster.marcom-manager-approve')) {
                            approvalAreaComponent = (
                                <ApproveComponent label="Approve" />
                            )
                        }

                        if (checkPermission('cluster.marcom-manager-approve') || checkPermission('cluster.marcom-approve')) {
                            approvalAreaComponent = (
                                <>
                                    {approvalAreaComponent}
                                    <RejectComponent label="Reject" />
                                </>
                            )
                        }
                        break;
                    case 2: /**Unpublished */
                        if (checkPermission('cluster.publish')) {
                            approvalAreaComponent = (
                                <ApproveComponent label="Publish" />
                            )
                        }

                        if (checkPermission('cluster.marcom-manager-approve')) {
                            approvalAreaComponent = (
                                <>
                                    {approvalAreaComponent}
                                    <RejectComponent label="Reject" />
                                </>
                            )
                        }
                        break;
                    case 3: /**Published */
                        if (checkPermission('cluster.publish')) {
                            approvalAreaComponent = (
                                <>
                                    <RejectComponent label="Unpublish" />
                                </>
                            )
                        }
                        break;
                    case 4:
                        if (checkPermission('cluster.publish')) {
                            approvalAreaComponent = (
                                <>
                                    <ApproveComponent label="Publish" />
                                    <RejectComponent label="Reject" />
                                </>
                            )
                        }
                        break;
                    default:
                        break;
                }

                return (
                    <div className={classes.actionContainer}>
                        <>
                            {
                                checkPermission('cluster.view') &&
                                <Tooltip title="Edit">
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => handleEdit(row.id)}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            }

                            <Tooltip title="See Preview">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => handlePreview(row.id)}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>

                            {checkPermission('cluster.manage-member') &&
                                <Tooltip title="Manage Member">
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => { setClusterMemberModalValue(row.id) }}
                                    >
                                        <GroupIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            }

                            {/* { checkPermission('cluster.delete') &&
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleDelete(row.id)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            } */}

                            {approvalAreaComponent}
                        </>
                    </div>
                );
            }
        }
    ]

    const handleClickImport = () => {
        document.getElementById('uploadFileCluster')!.click();
    }

    const handleClickExport = () => {
        DefaultAxios.get(
            CLUSTER_EXPORT_URL + '/clusters.xlsx',
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `clusters.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                // console.log(err);
                generalErrorHandler(err);
            })
            .finally(() => {
                // setReDrawDatatable(new Date().getTime());
                // setIsLoading(false);
                // document.querySelectorAll('[type="file"]').forEach(el => {
                //     (el as HTMLInputElement).value = '';
                // })
            })
    }

    // const handleDelete = (id: string) => {
    //     Swal.fire({
    //         title: 'Apakah Anda Yakin?',
    //         text: "Apakah anda yakin ingin mengganti status ini?",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya'
    //     }).then((result) => {
    //         if (result.value) {
    //             setIsLoading(true);
    //             DefaultAxios
    //                 .delete(`${process.env.REACT_APP_API_URL}/cluster/${id}`)
    //                 .then(res => {
    //                     Swal.fire({
    //                         title: 'Delete successful',
    //                         icon: 'success',
    //                         onAfterClose: () => {
    //                             setReDrawDatatable(new Date().getTime());
    //                         },
    //                         timer: 1000
    //                     })
    //                 })
    //                 .catch(error => {
    //                     generalErrorHandler(error)
    //                 })
    //                 .finally(() => {
    //                     setIsLoading(false);
    //                 })
    //         }
    //     })

    // }

    return (
        <>
            <ClusterMemberModal
                isOpen={clusterMemberModalValue !== 0 ? true : false}
                onClose={() => setClusterMemberModalValue(0)}
                cluster_id={clusterMemberModalValue}
            />

            <ClusterPlacementModal
                isOpen={clusterPlacementModalValue !== 0 ? true : false}
                hasTeam={clusterPlacementModalHasTeam}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setClusterPlacementModalValue(0);
                    setClusterPlacementModalHasTeam(false);
                }}
                cluster_id={clusterPlacementModalValue}
            />

            <LoadingScreen open={isLoading} fullScreen />
            {permissions['cluster.add'] &&
                <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                    <Grid item>
                        {
                            permissions['cluster.manage'] &&
                            <RouterLink to="/cluster/add">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    size="small"
                                    startIcon={<AddIcon />}
                                >
                                    Add Cluster
                                </Button>
                            </RouterLink>
                        }
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: "5px" }}
                            onClick={handleClickExport}
                            startIcon={<PublishIcon />}
                        >
                            Export
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<GetAppIcon />}
                            onClick={handleClickImport}
                        >
                            Import
                        </Button>
                        <input id="uploadFileCluster" type="file" name="csv" onChange={(e) => handleChanged(e)} style={{ display: "none", marginBottom: "20px" }} />
                    </Grid>
                </Grid>
            }

            <DataTable
                reDraw={reDrawDatatable}
                url={CLUSTER_URL}
                columns={columnsTable}
            // customParams={customParams}
            />
        </>
    )
}

export default ClusterList;
