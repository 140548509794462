import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createTheme, StyledEngineProvider, ThemeProvider  } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'date-fns';
import { pink } from '@mui/material/colors';

if (process.env.REACT_APP_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        autoSessionTracking: true,
        tracesSampleRate: 1.0,
    });
    if(localStorage.getItem('user_id')){
        Sentry.setUser({
            id: localStorage.getItem('user_id') ?? undefined
        })
    }
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#ef2028',
            dark: '#ab0204',
            contrastText: '#fff',
        },
        secondary: pink,
        background: {
            default: '#fff'
        }
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    }
                },
            }
        }
    },

});

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
            </LocalizationProvider >
        </ThemeProvider>
    </StyledEngineProvider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
