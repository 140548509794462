import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, MenuItem, InputAdornment, IconButton, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { IState, IErrorState, IOption } from './UserList';

//Icon
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Props {
    open: boolean;
    roles: IOption[];
    state: IState;
    errorState: IErrorState;
    onClose: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
    onUpload: (e: SyntheticEvent) => void;
}

const useStyles = makeStyles({
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    photoContainer: {
        marginBottom: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        '& .photo-icon': {
            height: 72,
            width: 72,
            borderRadius: 72,
            margin: '0 1.25rem 0 0',
            color: '#D9D9D9'
        },
        '& .photo': {
            height: 72,
            width: 72,
            borderRadius: 72,
            margin: '0 1.25rem 0 0'
        },
        '& .file-container': {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            '& .upload-button': {
                color: '#033A11',
                fontSize: 16
            },
            '& .detail': {
                color: '#9b9b9b'
            }
        }
    },
})

const UserForm = ({ open, roles, state, errorState, onClose, onChange, onSubmit, onUpload }: Props) => {
    const classes = useStyles();

    const uploadInput = useRef<HTMLInputElement>(null);

    const [visible, setVisible] = useState({
        bukan_kata_sandi: false,
        bukan_kata_sandi_confirmation: false
    });

    useEffect(() => {
        setVisible({
            bukan_kata_sandi: false,
            bukan_kata_sandi_confirmation: false
        })
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>User Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    {
                        !!state.id &&
                        <div className={classes.photoContainer}>
                            {
                                state.photo.url !== '' ?
                                    <Avatar alt="profile picture" src={state.photo.url || ''} className="photo" /> :
                                    <Avatar className="photo">{localStorage.getItem('user_name') && localStorage.getItem('user_name')![0].toUpperCase()}</Avatar>
                            }
                            <div className="file-container">
                                <span className="upload-button" onClick={() => uploadInput.current!.click()}>Pilih Foto</span>
                                {
                                    state.photo.file &&
                                    <span className="detail">{state.photo.file.name}</span>
                                }
                            </div>
                            <input
                                hidden
                                type="file"
                                name="upload[]"
                                ref={uploadInput}
                                value=""
                                accept="image/x-png,image/jpeg"
                                onChange={onUpload}
                            />
                        </div>
                    }
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama"
                            name="name"
                            value={state.name}
                            onChange={onChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="email"
                            value={state.email}
                            onChange={onChange}
                            error={!!errorState.email}
                            helperText={errorState.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Phone"
                            name="phone"
                            value={state.phone}
                            onChange={onChange}
                            placeholder="628xxxxxxxxxx"
                        />
                    </Grid>

                    {
                        localStorage.getItem('role') !== 'admin-agent' &&
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Role"
                                name="role_id"
                                value={state.role_id}
                                onChange={onChange}
                                error={!!errorState.role_id}
                                helperText={errorState.role_id}
                            >
                                {roles.map(role => <MenuItem value={role.key} key={role.key}>{role.value}</MenuItem>)}
                            </TextField>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi ? 'text' : 'password'}
                            label="Password"
                            name="bukan_kata_sandi"
                            value={state.bukan_kata_sandi}
                            onChange={onChange}
                            // error={!!errorState.bukan_kata_sandi}
                            // helperText={errorState.bukan_kata_sandi}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi: !prevState.bukan_kata_sandi }))}
                                        >
                                            {visible.bukan_kata_sandi ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi_confirmation ? 'text' : 'password'}
                            label="Confirm Password"
                            name="bukan_kata_sandi_confirmation"
                            value={state.bukan_kata_sandi_confirmation}
                            onChange={onChange}
                            error={!!errorState.bukan_kata_sandi_confirmation}
                            helperText={errorState.bukan_kata_sandi_confirmation}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi_confirmation: !prevState.bukan_kata_sandi_confirmation }))}
                                        >
                                            {visible.bukan_kata_sandi_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserForm;