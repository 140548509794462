import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from '@mui/material'

export interface FileAccessModalProps {
    isOpen: boolean
    onClose: () => void
    onSubmit: (values: FileAccessModalValues) => void
}

export interface FileAccessModalValues {
    is_internal_project_only: 0 | 1
    access_inhouse: 0 | 1
    // access_inhouse_jv: 0 | 1
    // access_digital_sales: 0 | 1
    access_external: 0 | 1
    access_public: 0 | 1
}

const FileAccessModal = (props: FileAccessModalProps) => {
    const [values, setValues] = useState<FileAccessModalValues>(initialValues)

    useEffect(() => {
        if (!props.isOpen) {
            setValues(initialValues)
        }
    }, [props.isOpen])

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>Change Access</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={values.is_internal_project_only}
                                    onChange={() => setValues(prev => prev.is_internal_project_only === 1 ? ({...prev, is_internal_project_only: 0}) : ({...initialValues, is_internal_project_only: 1}))}
                                />
                            }
                            label="Project Members"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={values.access_inhouse}
                                    onChange={() => setValues(prev => ({...prev, access_inhouse: prev.access_inhouse === 0 ? 1 : 0}))}
                                />
                            }
                            label="Inhouse"
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={values.access_inhouse_jv}
                                    onChange={() => setValues(prev => ({...prev, access_inhouse_jv: prev.access_inhouse_jv === 0 ? 1 : 0}))}
                                />
                            }
                            label="Inhouse JV"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={values.access_digital_sales}
                                    onChange={() => setValues(prev => ({...prev, access_digital_sales: prev.access_digital_sales === 0 ? 1 : 0}))}
                                />
                            }
                            label="Digital Sales"
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={values.access_external}
                                    onChange={() => setValues(prev => ({...prev, access_external: prev.access_external === 0 ? 1 : 0}))}
                                />
                            }
                            label="External"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={values.access_public}
                                    onChange={() => setValues(prev => ({...prev, access_public: prev.access_public === 0 ? 1 : 0}))}
                                />
                            }
                            label="Public"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => props.onSubmit(values)}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FileAccessModal

const initialValues: FileAccessModalValues = {
    is_internal_project_only: 0,
    access_inhouse: 0,
    // access_inhouse_jv: 0,
    // access_digital_sales: 0,
    access_external: 0,
    access_public: 0,
}
