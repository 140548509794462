import { createContext, useContext } from 'react'

interface IPermissionData {
    [key: string]: boolean | string
}

const Context = createContext<Partial<IPermissionData>>({})

export const PermissionProvider = Context.Provider
export const PermissionConsumer = Context.Consumer
export const PermissionContext = Context
export const usePermission = () => useContext(Context)
