import React, { useState } from 'react'
import ReactDates, { DateRangePicker } from 'react-dates';
import { isMobile } from '../../_utils/Helper';

interface DateRangeProps {
    dates: {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }
    setDates: (dates: {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }) => void
    /**
     * Unique identifier for the id
     */
    identifier: string
    onClose?: () => void
    small?: boolean
}

const DateRange = (props: DateRangeProps) => {
    const [focusedInput, setFocusedInput] = useState<ReactDates.FocusedInputShape | null>(null)

    const onDatesChange = (e: { startDate:moment.Moment | null, endDate:moment.Moment | null }) => {
        props.setDates(e)
    }

    const onFocusChange = (e: ReactDates.FocusedInputShape | null) => {
        setFocusedInput(e)
    }

    return (
        <DateRangePicker 
            startDate={props.dates.startDate}
            startDateId={`start_date_${props.identifier}`}
            endDate={props.dates.endDate}
            endDateId={`end_date_${props.identifier}`}
            focusedInput={focusedInput}
            onDatesChange={onDatesChange}
            onFocusChange={onFocusChange}
            keepOpenOnDateSelect={true}
            small={props.small}
            onClose={props.onClose}
            showClearDates={true}
            reopenPickerOnClearDates={true}
            isOutsideRange={() => false}
            minimumNights={0}
            withPortal={true}
            numberOfMonths={isMobile() ? 1 : 2}
        />
    )
}

export default DateRange
