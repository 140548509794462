import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';

const Search = () => {
    const history = useHistory();
    // Ref
    const searchRef = useRef<any>(null);
    
    const handleSearch = () => {
        if (searchRef.current!.value) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/account/search`, { params: { search: searchRef.current!.value } })
                .then(res => {
                    history.push(`/account/${res.data}`, { prevPath: window.location.pathname });
                })
                .catch(err => {
                    generalErrorHandler(err);
                });
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        const key = e.key
        if (key === 'Enter') {
            handleSearch();
        }
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <TextField 
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="search"
                    placeholder="Search HP, Email, atau Nomor KTP"
                    inputRef={searchRef}
                    onKeyDown={handleKeyDown}
                />
            </Grid>

            <Grid item xs={8}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
}

export default Search;