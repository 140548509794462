import React, { useState } from 'react'
import { Grid, Button, IconButton } from '@mui/material'
import DataTable from '../../_components/_dataTable/DataTable'

/**
 * Components
 */
import BlacklistSystemFormModal from './_components/BlacklistSystemFormModal';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';

/**
 * Icons
 */
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';

const BlacklistSystemList = () => {
    const [addModal, setAddModal] = useState(false)
    const [redrawDataTable, setReDrawDataTable] = useState(new Date().getTime())
    const [isLoading, setIsLoading] = useState(false)

    const handleAdd = () => {
        setAddModal(true)
    }

    const handleDeletePhone = (row: any) => {
        renderWarningButton('Apakah anda ingin menghapus phone ini dari list?')
            .then(res => {
                if (res.dismiss) return

                setIsLoading(true)
                DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/blacklisted-phone/${row.id}`)
                    .then(res => res.data)
                    .then(res => {
                        renderToastSuccess('Phone Deleted!')
                    })
                    .catch(generalErrorHandler)
                    .finally(() => [setIsLoading(false), setReDrawDataTable(new Date().getTime())])

            })
    }

    const handleClickImport = () => {
        window.document.getElementById('uploadFilePhones')!.click()
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const phones = e.target.files![0]

        let fd = new FormData()
        fd.append('import_file', phones)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/blacklisted-phone/import`, fd)
            .then(res => {
                renderToastSuccess('Import Successfully')
                setReDrawDataTable(new Date().getTime());
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false);
                document.querySelectorAll('[type="file"]').forEach(el => {
                    (el as HTMLInputElement).value = '';
                })
            })

    }

    return (
        <>
            <LoadingScreen fullScreen open={isLoading} />
            <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Button
                        variant='contained'
                        onClick={handleAdd}
                    >
                        Add
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleClickImport}
                        startIcon={<GetAppIcon />}
                    >
                        Import
                    </Button>
                    <input id="uploadFilePhones" type="file" name="phone" onChange={handleChangeFile} style={{ display: "none", marginBottom: "20px" }} />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <DataTable
                        reDraw={redrawDataTable}
                        columns={[
                            { name: 'phone', label: 'Phone', filterable: true },
                            // { name: 'reason', label: 'Reason', filterable: true },
                            {
                                name: 'EXTRA', label: 'Action', filterable: false,
                                render: (row: any) => {
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <IconButton
                                                onClick={() => handleDeletePhone(row)}
                                                color='secondary'
                                                title='Delete Phone'
                                            >
                                                <DeleteIcon />
                                            </IconButton>

                                        </div>
                                    )
                                }
                            }
                        ]}
                        url={`${process.env.REACT_APP_API_URL}/blacklisted-phone`}
                    />
                </Grid>
            </Grid>

            <BlacklistSystemFormModal
                open={addModal}
                onClose={(refresh) => {
                    setAddModal(false)

                    if (refresh) {
                        setReDrawDataTable(new Date().getTime())
                    }
                }}
            />
        </>
    )
}

export default BlacklistSystemList