import React, { useState, useReducer, useEffect } from 'react';
import { Grid, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export interface IState {
    bukan_kata_sandi_lama: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    showPasswordLama: boolean;
    showPassword: boolean;
    showPasswordConfirm: boolean;
}

export interface IAction {
    name: string,
    value: any,
    type: string
}

export const useStyles = makeStyles({
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    }
})

export const API_URL = `${process.env.REACT_APP_API_URL}/profile/change-password`;

const ProfileSetting = () => {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                bukan_kata_sandi_lama: '',
                bukan_kata_sandi: '',
                bukan_kata_sandi_confirmation: '',
                showPasswordLama: false,
                showPassword: false,
                showPasswordConfirm: false,
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        bukan_kata_sandi_lama: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        showPasswordLama: false,
        showPassword: false,
        showPasswordConfirm: false,
    });

    useEffect(() => {
        return () => {
            if (localStorage.getItem('should-change-password')) {
                localStorage.removeItem('should-change-password');
            }
        }
    }, []);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const submitForm = () => {
        let axios;
        axios = DefaultAxios.post(`${API_URL}`, inputState);

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Password Changed",
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        window.location.reload();
                    }
                });
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(error => {
                generalErrorHandler(error);
                setIsLoading(false);
            });
    }

    const handleClickShowPassword = (name:string, value:boolean) => {
        setInputState({
            ...inputState,
            name: name, value: !value, type: 'SET_ITEM'
        });
    };

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <h1 className={classes.borderBottomLine}>Profile Setting</h1>
            </Grid>

            {
                localStorage.getItem('should-change-password') &&
                <Grid item xs={12}>
                    <h3 style={{ color: 'red' }}>Please change your password before continue access the cms</h3>
                </Grid>
            }

            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Old Password"
                            type={inputState.showPasswordLama ? 'text' : 'password'}
                            name="bukan_kata_sandi_lama"
                            value={inputState.bukan_kata_sandi_lama}
                            onChange={handleInputChanged}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showPasswordLama', inputState.showPasswordLama)}
                                        >
                                            {inputState.showPasswordLama ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Password"
                            type={inputState.showPassword ? 'text' : 'password'}
                            name="bukan_kata_sandi"
                            value={inputState.bukan_kata_sandi}
                            onChange={handleInputChanged}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showPassword', inputState.showPassword)}
                                        >
                                            {inputState.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Confirm Password"
                            type={inputState.showPasswordConfirm ? 'text' : 'password'}
                            name="bukan_kata_sandi_confirmation"
                            value={inputState.bukan_kata_sandi_confirmation}
                            onChange={handleInputChanged}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showPasswordConfirm', inputState.showPasswordConfirm)}
                                        >
                                            {inputState.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={submitForm}
                        >
                            Submit
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default ProfileSetting;