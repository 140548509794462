import React from 'react'
import { ListItemIcon, ListItemText, Menu, MenuItem, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles';
import { Delete, Edit, Folder } from '@mui/icons-material'
import { DriveFolder } from '../DrivePage'

export interface FolderCardProps extends DriveFolder {
    onClick: () => void
    onEdit: (folder: DriveFolder) => void
    onDelete: (id: number) => void
}

const FolderCard = (props: FolderCardProps) => {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)
    const open = Boolean(anchorEl)
    
    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget)

        return false // to prevent the default context menu popping up
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }
  
    return (
        <div>
            <div
                className={classes.container}
                onClick={() => props.onClick()}
                onContextMenu={handleRightClick}
            >
                <Folder color='primary' />
                <p className={classes.name}>
                    {props.name}
                </p>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    props.onEdit(props)
                }}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    props.onDelete(props.id)
                }}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default FolderCard

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            border: '1px solid #C8C8C8',
            borderRadius: 5,
            padding: 12,
            display: 'flex',
            alignItems: 'center',
            transition: '150ms border-color linear',
            cursor: 'pointer',
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
        },
        name: {
            margin: 0,
            paddingLeft: 12,
            fontSize: 14,
            color: '#484848',
            fontWeight: 'bold',
        },
    })
);