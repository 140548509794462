import React, { useState, useEffect, useReducer, SyntheticEvent } from 'react';
import { Button, Theme, Tooltip, IconButton, Switch, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import UserForm from './UserForm';
import AgentMemberModal from './_components/AgentMemberModal';
import AdminAgentMemberModal from './_components/AdminAgentMemberModal';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, inputPhoneNumber, isValidEmail, renderWarningButton, resizeAndResetOrientationImage } from '../../_utils/Helper';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import GroupIcon from '@mui/icons-material/Group';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

export interface IOption {
    key: string;
    value: string;
}

interface IPhoto {
    url: string
    file: File | null
}

export interface IState {
    id: string;
    name: string;
    email: string;
    phone: string;
    role_id: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    sap_code: string;
    photo: IPhoto;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
    email: string;
    role_id: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    sap_code: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        addButton: {
            marginBottom: '20px'
        },
        importButton: {
            float: 'right'
        },
        errorContainer: {
            maxHeight: '200px',
            overflowY: 'auto'
        }
    })
);

const UserList = (props: Props) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/user';
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [adminAgentMemberModalValue, setAdminAgentMemberModalValue] = useState('');
    const [agentMemberModalValue, setAgentMemberModalValue] = useState('');
    const [agentMemberType, setAgentMemberType] = useState('');
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [roles, setRoles] = useState<IOption[]>([]);

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        // {
        //     name: 'phone',
        //     label: 'Phone',
        //     type: 'string',
        //     filterable: true
        // },
        // {
        //     name: 'unique_code',
        //     label: 'Unique Code',
        //     type: 'string',
        //     filterable: true
        // },
        {
            name: 'role_name',
            label: 'Role',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...roles
            ],
            filterable: true,
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'In-Active'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchActive(row.id)}
                    color="secondary"
                />
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {
                            permissions['agent.reset-password'] &&
                            <Tooltip title="Reset Password">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleResetPassword(row.id)}
                                >
                                    <RefreshIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            ['Agent Captain', 'Sales Manager'].includes(row['role_name']) &&
                            <Tooltip title="Agent Member">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        setAgentMemberModalValue(row.id)
                                        setAgentMemberType(row.role_name);
                                    }}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            ['Admin Agent'].includes(row['role_name']) && permissions['admin-agent.assign'] &&
                            <Tooltip title="Admin Agent Member">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        setAdminAgentMemberModalValue(row.id)
                                    }}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                );
            }
        },
    ];

    const initialState = {
        id: '',
        name: '',
        email: '',
        phone: '',
        role_id: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        sap_code: '',
        photo: {
            url: '',
            file: null
        }
    };

    const initialErrorState = {
        name: '',
        email: '',
        role_id: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        sap_code: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        DefaultAxios.get(`${API_URL}/role-list`)
            .then(res => {
                const options = [];

                for (let role of res.data) {
                    options.push({
                        key: role.id,
                        value: role.name
                    });
                }

                setRoles(options);
            })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (['bukan_kata_sandi', 'bukan_kata_sandi_confirmation'].includes(name)) {
            value = value.replace(/\s*/g, '');
        } else if (name === 'phone') {
            value = inputPhoneNumber(value);
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.email) {
            newError.email = 'Email wajib diisi';
            isValid = false;
        } else if (!isValidEmail(inputState.email)) {
            newError.email = 'Format email tidak benar';
            isValid = false;
        }

        if (localStorage.getItem('role') !== 'admin-agent') {
            if (!inputState.role_id) {
                newError.role_id = 'Role wajib diisi';
                isValid = false;
            }
        }

        if (!inputState.id) {
            // if (!inputState.bukan_kata_sandi) {
            //     newError.bukan_kata_sandi = 'Password wajib diisi';
            //     isValid = false;
            // }

            // if (!inputState.password_confirmation) {
            //     newError.password_confirmation = 'Confirm Password wajib diisi';
            //     isValid = false;
            // } else if (inputState.password_confirmation.length < 8) {
            //     newError.password_confirmation = 'Confirm Password minimal 8 karakter';
            //     isValid = false;
            // }
        }

        // if (inputState.bukan_kata_sandi && inputState.bukan_kata_sandi.length < 8) {
        //     newError.bukan_kata_sandi = 'Password minimal 8 karakter';
        //     isValid = false;
        // }

        if (inputState.bukan_kata_sandi !== inputState.bukan_kata_sandi_confirmation) {
            newError.bukan_kata_sandi_confirmation = 'Confirm Password harus sama dengan Password';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDatatable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                newState.sap_code = newState.sap_code || '';
                newState.photo.url = newState.photo_small_url || '';
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const switchActive = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-active`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime())
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            const fd = new FormData();
            fd.append('import_file', files[0]);

            setIsLoading(true);
            DefaultAxios
                .post(`${API_URL}/import`, fd)
                .then((res: { data: string[] }) => {
                    let title = 'Upload successful';
                    let message = '';
                    let icon: 'success' | 'warning' = 'success';

                    if (res.data.length) {
                        title = 'Upload with partiel error';
                        icon = 'warning';
                        message = '<ul class="' + classes.errorContainer + '">';
                        for (let m of res.data) {
                            message += `<li>${m}</li>`;
                        }
                        message += '</ul>';
                    }

                    Swal.fire({
                        title: title,
                        html: message,
                        icon: icon
                    });
                    setReDrawDatatable(new Date().getTime());
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = '';
                    })
                    setIsLoading(false);
                });
        }
    }

    const handleResetPassword = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin me-reset password user ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/password/reset`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mereset password",
                                icon: 'success',
                                timer: 1000
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleUpload = (e: SyntheticEvent) => {
        setIsLoading(true)
        const target = e.target as HTMLInputElement
        const files = target.files as FileList
        if (target && files) {
            if (files[0].size >= 5000000) {
                // enqueueSnackbar('Ukuran file maksimum adalah 5MB', { variant: 'error' })
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        const url = process.env.REACT_APP_API_URL + '/user/upload/' + inputState.id
                        let formData = new FormData()
                        formData.append('image', file)
                        DefaultAxios.post(url, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                            .then(res => {
                                Swal.fire({
                                    title: 'Berhasil upload photo',
                                    icon: 'success',
                                    timer: 1000,
                                    onAfterClose: () => {
                                        let newPhoto: IPhoto = {
                                            url: URL.createObjectURL(file),
                                            file: file
                                        }
                                        setInputState({ name: 'photo', value: newPhoto, type: 'SET_ITEM' });
                                    }
                                })
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    })
            }
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <UserForm
                open={modalOpen}
                onClose={handleModalClose}
                roles={roles}
                state={inputState}
                errorState={errorState}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onUpload={handleUpload}
            />

            <AgentMemberModal
                isOpen={agentMemberModalValue !== ''}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setAgentMemberModalValue('');
                }}
                captainId={agentMemberModalValue}
                userType={agentMemberType}
            />

            <AdminAgentMemberModal
                isOpen={adminAgentMemberModalValue !== ''}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setAdminAgentMemberModalValue('');
                }}
                adminId={adminAgentMemberModalValue}
            />

            <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                <Grid item>
                    {
                        permissions['user.manage'] &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.addButton}
                            startIcon={<AddIcon />}
                            onClick={handleModalOpen}
                        >
                            Add User
                        </Button>
                    }
                </Grid>

                <Grid item>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        size="small"
                        className={classes.importButton}
                        startIcon={<GetAppIcon />}
                    >
                        Import

                    </Button>
                    <input
                        type="file"
                        style={{ display: "none" }}
                        accept=".xls,.xlsx"
                        onChange={handleImportFileChanged}
                    />
                </Grid>
            </Grid>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </>
    );
}

export default UserList;