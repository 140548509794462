import React, { useState, useReducer, useRef, RefObject, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Grid,
    TextField,
    Typography,
    Switch,
    // Collapse,
    Button,
    // Link
} from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import TextArea from '../../_components/_form/TextArea';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Assets
import defaultThumbnail from '../../Assets/Images/png/default-thumbnail-unit.png';
import { generalErrorHandler, isValidLatLng } from '../../_utils/Helper';
import TextEditor from '../../_components/_form/TextEditor';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
    projectId: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    name: string;
    office_address: string;
    is_apartment_project: boolean;
    province_id: string;
    city_id: string;
    hectare: string;
    latitude: string;
    longitude: string;
    information_url: string;
    highlights: string;
    pic_name: string;
    banner_desktop: {
        name: string;
        file: File | null;
    };
    banner_mobile: {
        name: string;
        file: File | null;
    };
    thumbnail: {
        name: string;
        file: File | null;
    };
    banner_desktop_url: string | null;
    banner_mobile_url: string | null;
    thumbnail_url: string | null;
    meta_title: string;
    meta_description: string;
    article: string;
    cluster_direct_sap_id: string
    cluster_direct_sap_name: string
}

interface IErrorState {
    name: string;
    province_id: string;
    city_id: string;
    latitude: string;
    longitude: string;
    banner_desktop: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IRefs {
    [key: string]: RefObject<HTMLInputElement>
}

interface IListPlace {
    id: number;
    label: string;
}

const useStyles = makeStyles({
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    collapse: {
        paddingLeft: '12px',
        paddingRight: '12px',
        width: '100%',
        '& .MuiCollapse-wrapper': {
            paddingTop: '12px',
            paddingBottom: '12px'
        }
    },
})

let cache_cities: Array<Array<IListPlace>> = []; //NOTE: cache_cities itu isinya array dari data yang udah di fetch, makanya interface Arraynya dua kali

const ProjectDetailForm = (props: Props) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/project';
    const params: any = useParams();
    const history = useHistory();
    const classes = useStyles();

    const booleanAttributes = [
        'is_apartment_project'
    ];

    const refs: IRefs = {
        name: useRef<HTMLInputElement>(null),
        province_id: useRef<HTMLInputElement>(null),
        city_id: useRef<HTMLInputElement>(null),
        hectare: useRef<HTMLInputElement>(null),
        latitude: useRef<HTMLInputElement>(null),
        longitude: useRef<HTMLInputElement>(null),
    }

    const initialState = {
        name: '',
        office_address: '',
        is_apartment_project: false,
        province_id: '',
        city_id: '',
        latitude: '',
        longitude: '',
        hectare: '',
        information_url: '',
        highlights: '',
        pic_name: '',
        banner_desktop: {
            name: "",
            file: null,
        },
        banner_mobile: {
            name: "",
            file: null,
        },
        thumbnail: {
            name: "",
            file: null,
        },
        banner_desktop_url: null,
        banner_mobile_url: null,
        thumbnail_url: null,
        meta_title: '',
        meta_description: '',
        article: '',
        cluster_direct_sap_id: '',
        cluster_direct_sap_name: ''
    };

    const initialErrorState = {
        name: '',
        province_id: '',
        city_id: '',
        latitude: '',
        longitude: '',
        banner_desktop: '',
    };

    // Input Reducer
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [isLoading, setIsLoading] = useState(false);
    const [submitDone, setSubmitDone] = useState(new Date().getTime());
    const [provinceList, setProvinceList] = useState<Array<IListPlace>>([]);
    const [cityList, setCityList] = useState<Array<IListPlace>>([]);
    const [areaAutoCompleteLoading, setAACL] = useState(true);

    useEffect(() => {
        if (params.id) {
            loadData();
        } else {
            loadFormData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitDone]);

    const mustNull = ['banner_desktop_url', 'banner_mobile_url', 'thumbnail_url'];

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const resData = res.data;
                const newState: any = {};

                for (let [key, value] of Object.entries(resData)) {
                    if (booleanAttributes.includes(key)) {
                        newState[key] = !!value;
                    } else if (value === null && (!mustNull.includes(key))) {
                        newState[key] = "";
                    } else {
                        newState[key] = value;
                    }
                }

                loadFormData(newState);
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loadFormData = (state?: IState) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project/form-data`)
            .then(res => {
                setProvinceList(res.data.provinces);
                setAACL(false);

                if (typeof state !== 'undefined') {
                    const province_id: any = state.province_id;
                    DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/city`, { params: { province_id: province_id } })
                        .then(res => {
                            let list_of_cities = res.data;
                            cache_cities[province_id] = list_of_cities;
                            setCityList(list_of_cities);
                        });
                }
            });
    }

    const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
        window.scrollTo({ top: ref.current!.offsetTop - 64, behavior: 'smooth' });
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'banner_desktop' || name === 'banner_mobile' || name === 'thumbnail') {
            const oldBanner = { ...inputState[name] };

            if (target.files && target.files[0]) {
                oldBanner.file = target.files[0];
            } else {
                oldBanner.file = null;
            }
            value = oldBanner;
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleEditorChanged = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { ...inputState };
        newState.is_apartment_project = event.target.checked;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    };

    const handleProvinceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: 'province_id', value: e.target.value, type: 'SET_ITEM' });

        if (e.target.value === "") {
            resetCityList();
        } else {
            let province_id: number = Number(e.target.value); //Explicitly set as number so Typescript will shut up

            if (province_id in cache_cities) {
                setCityList(cache_cities[province_id]);
            } else {
                resetCityList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/city`, { params: { province_id: province_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_cities[province_id] = list_of_cities;
                        setCityList(list_of_cities);
                        setAACL(false);
                    });
            }
        }
    }

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: 'city_id', value: e.target.value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    // const handleEditorChanged = (name: string, value: string) => {
    //     setInputState({ name, value, type: 'SET_ITEM' });
    // }

    const resetCityList = () => {
        setInputState({ name: 'city_id', value: "", type: 'SET_ITEM' });
        setCityList([]);
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            if (!firstError) firstError = 'name';
            newError.name = 'Name wajib diisi';
            isValid = false;
        }

        if (!inputState.city_id) {
            if (!firstError) firstError = 'city_id';
            newError.city_id = 'City wajib diisi';
            isValid = false;
        }

        if (inputState.is_apartment_project) {
            if (!inputState.latitude) {
                if (!firstError) firstError = 'latitude';
                newError.latitude = 'Latitude wajib diisi';
                isValid = false;
            } else if (!isValidLatLng(inputState.latitude)) {
                if (!firstError) firstError = 'latitude';
                newError.latitude = 'Format latitude tidak valid';
                isValid = false;
            }

            if (!inputState.longitude) {
                if (!firstError) firstError = 'longitude';
                newError.longitude = 'Longitude wajib diisi';
                isValid = false;
            } else if (!isValidLatLng(inputState.longitude)) {
                if (!firstError) firstError = 'longitude';
                newError.longitude = 'Format longitude tidak valid';
                isValid = false;
            }
        }

        if (firstError) scrollToRef(refs[firstError]);
        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        const fd = new FormData();

        for (let [key, value] of Object.entries(inputState)) {
            if (['banner_desktop_url', 'banner_mobile_url', 'thumbnail_url'].includes(key)) {
                continue;
            } else if (key === 'banner_desktop') {
                if (typeof inputState['banner_desktop'].file !== "undefined") {
                    fd.append(key, (inputState['banner_desktop'].file as File));
                }
            } else if (key === 'banner_mobile') {
                if (typeof inputState['banner_mobile'].file !== "undefined") {
                    fd.append(key, (inputState['banner_mobile'].file as File));
                }
            } else if (key === 'thumbnail') {
                if (typeof inputState['thumbnail'].file !== "undefined") {
                    fd.append(key, (inputState['thumbnail'].file as File));
                }
            } else {
                fd.append(key, value!.toString());
            }
        }

        if (params.id) {
            fd.append('_method', 'PATCH');
            axios = DefaultAxios.post(`${API_URL}/${params.id}`, fd);
        } else {
            axios = DefaultAxios.post(API_URL, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {
                            // loadData();
                            setSubmitDone(new Date().getTime());
                            document.querySelectorAll('[type="file"]').forEach(el => {
                                (el as HTMLInputElement).value = '';
                            })
                        } else {
                            history.push('/project');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <Grid item xs={12} className={classes.switchContainer}>
                <Typography>
                    Project Apartment
                </Typography>
                <Switch
                    color="primary"
                    value={true}
                    checked={inputState.is_apartment_project}
                    onChange={handleSwitch}
                />
            </Grid>

            {
                params.id && permissions['project.edit-cluster-direct-sap-id'] &&
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Cluster Direct SAP"
                        name="cluster_direct_sap_id"
                        initialQuery={inputState.cluster_direct_sap_name}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster/direct-sap-project?project_id=${params.id}`}
                        iconSearch
                    />
                </Grid>
            }

            <Grid item xs={12}>
                <TextField
                    label="Nama"
                    name="name"
                    value={inputState.name}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.name}
                    helperText={errorState.name}
                    ref={refs.name}
                />
            </Grid>

            <Grid item xs={12}>
                <TextArea
                    label="Alamat Kantor"
                    name="office_address"
                    value={inputState.office_address}
                    onChange={handleChanged}
                    rows={3}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Luas (hectare)"
                    name="hectare"
                    value={inputState.hectare}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>

            {/* <Collapse in={inputState.is_apartment_project} className={classes.collapse}> */}
            <Grid item xs={12}>
                <TextField
                    label="Latitude"
                    name="latitude"
                    value={inputState.latitude}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.latitude}
                    helperText={errorState.latitude}
                    ref={refs.latitude}
                    placeholder="Ex: -6.198049"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Longitude"
                    name="longitude"
                    value={inputState.longitude}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.longitude}
                    helperText={errorState.longitude}
                    ref={refs.longitude}
                    placeholder="Ex: 106.7626502"
                />
            </Grid>
            {/* </Collapse> */}
            <Grid item xs={12}>
                <TextField
                    label="Provinsi"
                    name="province_id"
                    value={inputState.province_id}
                    onChange={handleProvinceChange}
                    disabled={areaAutoCompleteLoading}
                    error={!!errorState.province_id}
                    helperText={errorState.province_id}
                    variant="outlined"
                    select
                    fullWidth
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    ref={refs.province_id}
                >
                    <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                    {provinceList.map(data => {
                        return (
                            <option value={data.id} key={data.id}>{data.label}</option>
                        )
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Kota"
                    name="city_id"
                    value={inputState.city_id}
                    onChange={handleCityChange}
                    disabled={areaAutoCompleteLoading ? true : (!!inputState.province_id ? false : true)}
                    error={!!errorState.city_id}
                    helperText={errorState.city_id}
                    variant="outlined"
                    select
                    fullWidth
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    ref={refs.city_id}
                >
                    <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                    {
                        cityList.length ?
                            cityList.map(data => {
                                return (
                                    <option value={data.id} key={data.id}>{data.label}</option>
                                )
                            })

                            : <></>
                    }
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Information URL"
                    name="information_url"
                    value={inputState.information_url}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>

            <Grid item xs={12}>
                <TextArea
                    label="Highlights"
                    name="highlights"
                    value={inputState.highlights}
                    onChange={handleChanged}
                    rows={3}
                />
            </Grid>

            <Grid item xs={12}>
                <h3>Article</h3>
                <TextEditor
                    name="article"
                    value={inputState.article}
                    onChange={handleEditorChanged}
                    imagesUpload
                />
            </Grid>

            {/* {
                localStorage.getItem('role') === 'superadmin' &&
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="PIC Project"
                        name="pic_id"
                        initialQuery={inputState.pic_name}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/user?roles=pic-project`}
                        iconSearch
                    />
                </Grid>
            } */}

            <Grid item xs={12}>
                <h3>Upload Banner Desktop (rekomendasi 1400x520px)</h3>
                {inputState.banner_desktop_url !== null
                    ? <>
                        <img src={inputState.banner_desktop_url} style={{ width: "350px", height: "130px" }} alt="banner desktop" />
                        <br></br>
                    </>
                    : <div style={{ width: "350px", height: "130px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                        <img src={defaultThumbnail} style={{ height: "66%" }} alt="banner desktop" />
                    </div>
                }

                <input type="file" name="banner_desktop" onChange={handleChanged} style={{ marginBottom: '10px' }} />
            </Grid>

            <Grid item xs={12}>
                <h3>Upload Banner Mobile (rekomendasi size 420x450px)</h3>
                {inputState.banner_mobile_url !== null
                    ? <>
                        <img src={inputState.banner_mobile_url} style={{ width: "210px", height: "225px" }} alt="banner mobile" />
                        <br></br>
                    </>
                    : <div style={{ width: "210px", height: "225px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                        <img src={defaultThumbnail} style={{ height: "40%" }} alt="banner mobile" />
                    </div>
                }

                <input type="file" name="banner_mobile" onChange={handleChanged} style={{ marginBottom: '10px' }} />
            </Grid>

            <Grid item xs={12}>
                <h3>Upload Thumbnail (rekomendasi size 300x280px)</h3>

                {inputState.thumbnail_url !== null
                    ? <>
                        <img src={inputState.thumbnail_url} style={{ width: "150px", height: "140px" }} alt="thumbnail" />
                        <br></br>
                    </>
                    : <div style={{ width: "150px", height: "140px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                        <img src={defaultThumbnail} style={{ height: "66%" }} alt="thumbnail" />
                    </div>
                }

                <input type="file" name="thumbnail" onChange={handleChanged} style={{ marginBottom: '10px' }} />
            </Grid>

            {/* <Grid item xs={12}>
                <TextField
                    label="Meta Title"
                    name="meta_title"
                    value={inputState.meta_title}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Meta Description"
                    name="meta_description"
                    value={inputState.meta_description}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid> */}

            {/* <Grid item xs={12}>
                <h3>Article</h3>
                <TextEditor
                    name="article"
                    value={inputState.article}
                    onChange={handleEditorChanged}
                    imagesUpload
                />
            </Grid> */}

            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </>
    );
}

export default ProjectDetailForm;