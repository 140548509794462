import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { Grid, Theme, ButtonGroup, Button, Tooltip, Fab, Card, CardContent } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import ClusterDetailForm from './ClusterDetailForm';
import ImageFormPage from '../../_components/_form/ImageForm/Page';
import VideoFormPage from '../../_components/_form/VideoForm/Page';
import Photo360FormPage from '../../_components/_form/Photo360Form/Page';
import FileFormPage from '../../_components/_form/FileForm/Page';
import ModalDetailCluster from './ModalDetailCluster'
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '800px',
            marginBottom: '70px',
            boxShadow: '0px 3px 15px #0000001A',
            '& .Mui-disabled': {
                backgroundColor: '#eee'
            }
        },
        rootWider: {
            maxWidth: '100%',
            marginBottom: '70px',
            boxShadow: '0px 3px 15px #0000001A',
            '& .Mui-disabled': {
                backgroundColor: '#eee'
            }
        },
        borderBottomLine: {
            borderBottom: '1px solid #eee'
        },
        fab: {
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            display: 'none',
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            }
        },
        fabSpacing: {
            marginRight: '1rem',
        },
    })
);

const ClusterForm = (props: Props) => {
    const permissions = usePermission()
    const classes = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const history = useHistory();

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');

    const renderForm = () => {
        if (params.id) {
            if (type === 'details') {
                return <ClusterDetailForm
                    clusterId={params.id}
                    mode="edit"
                />
            } else if (type === 'image') {
                return <ImageFormPage
                    API_URL={`${process.env.REACT_APP_API_URL}/cluster/${params.id}/image`}
                    type="cluster"
                />
            } else if (type === '360-photo') {
                return <Photo360FormPage
                    API_URL={`${process.env.REACT_APP_API_URL}/cluster/${params.id}/360-photo`}
                    type="cluster"
                />
            } else if (type === 'video') {
                return <VideoFormPage
                    API_URL={`${process.env.REACT_APP_API_URL}/cluster/${params.id}/videos`}
                    type="cluster"
                />
            } else if (type === 'files') {
                return <FileFormPage
                    API_URL={`${process.env.REACT_APP_API_URL}/cluster/${params.id}/files`}
                    fileTypes={[
                        {
                            value: 'brochure',
                            label: 'Brochure'
                        },
                        {
                            value: 'other',
                            label: 'Lainnya'
                        }
                    ]}
                />
            }
        } else {
            return <ClusterDetailForm
                clusterId={null}
                mode="add"
            />
        }
    }

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                history.push(`/cluster/edit/${params.id}`);
            } else {
                history.push(`/cluster/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    useEffect(() => {

        document.body.style.backgroundColor = '#eee';
        let timeout: any;

        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Card className={type === 'details' ? classes.root : classes.rootWider}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.borderBottomLine}>Cluster - {location.pathname === '/cluster/add' ? 'Add' : 'EDIT'}</h1>
                    </Grid>

                    {
                        location.pathname !== '/cluster/add'
                            ? <Grid item xs={12}>
                                <ButtonGroup
                                    color="primary"
                                    aria-label="outlined primary button group"
                                    fullWidth
                                >
                                    <Button
                                        variant={type === 'details' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('details')}
                                    >
                                        Details
                                    </Button>
                                    <Button
                                        variant={type === 'image' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('image')}
                                    >
                                        Images
                                    </Button>
                                    <Button
                                        variant={type === '360-photo' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('360-photo')}
                                    >
                                        360 Photo
                                    </Button>
                                    <Button
                                        variant={type === 'video' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('video')}
                                    >
                                        Video
                                    </Button>
                                    {
                                        permissions['cluster-file.view'] &&
                                        <Button
                                            variant={type === 'files' ? 'contained' : 'outlined'}
                                            onClick={() => handleNavigation('files')}
                                        >
                                            Files
                                        </Button>
                                    }
                                </ButtonGroup>
                            </Grid>
                            : null
                    }

                    <Grid item xs={12}>
                        {renderForm()}
                    </Grid>

                    {
                        !!params.id &&
                        <>
                            <div className={classes.fab}>
                                <Tooltip title="Show Detail" className={classes.fabSpacing} placement="top" arrow>
                                    <Fab aria-label="Show Detail" color="primary" onClick={() => setIsDetailModalOpen(true)}>
                                        <VisibilityIcon />
                                    </Fab>
                                </Tooltip>
                            </div>
                            {
                                (isDetailModalOpen && !!params.id) &&
                                <ModalDetailCluster id={params.id} close={() => setIsDetailModalOpen(false)} />
                            }
                        </>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ClusterForm;