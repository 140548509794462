import React from 'react';
import classes from '../Assets/CSS/com.module.css';

export interface PhotoPreviewProps {
    src: string,
    onRemoveFile: (idx: number) => void,
    index: number
    style?: React.CSSProperties
    disabled?: boolean
}

export interface PhotoPreviewState {
    prevHeight: number,
    src: string
}

class PhotoPreview extends React.Component<PhotoPreviewProps, PhotoPreviewState> {
    imgPrev = React.createRef<HTMLDivElement>();

    constructor(props: PhotoPreviewProps) {
        super(props)
        this.state = {
            prevHeight: 0,
            src: this.props.src
        }
    }

    componentDidMount() {
        if (this.imgPrev.current) {
            this.setState({ prevHeight: this.imgPrev.current.offsetWidth - 4 });
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        if (this.imgPrev.current) {
            this.setState({ prevHeight: this.imgPrev.current.offsetWidth - 4 });
        }
    }

    render() {

        return (
            <div className={classes.phPrev} ref={this.imgPrev} style={{ backgroundImage: `url(${this.state.src})`, height: this.state.prevHeight, opacity: this.props.disabled ? 0.7 : 1, ...this.props.style }}>
                {
                    this.props.disabled ?
                        null
                        :
                        <button onClick={this.props.onRemoveFile.bind(this, this.props.index)}>
                            <span></span>
                            <span></span>
                        </button>
                }
            </div>
        )
    }
}

export default PhotoPreview;