import React, { useMemo } from 'react'
import { ListItemIcon, ListItemText, Menu, MenuItem, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles';
import { CloudDownload, Delete, Edit } from '@mui/icons-material'
import { DriveFile } from '../DrivePage'

import docx from '../../../Assets/Images/drive/docx.png'
import excel from '../../../Assets/Images/drive/excel.png'
import image from '../../../Assets/Images/drive/image.png'
import movie from '../../../Assets/Images/drive/movie.png'
import pdf from '../../../Assets/Images/drive/pdf.png'
import documentIcon from '../../../Assets/Images/drive/document.svg'
import others from '../../../Assets/Images/drive/others.svg'

export interface FileContextProps extends DriveFile {
    onEdit: (file: DriveFile) => void
    onDelete: (isMultiple: boolean, id?: number) => void
    setIsLoading: (isLoading: boolean) => void
    setImgUrl: (value: string | undefined) => void
}

const FileContext = (props: FileContextProps) => {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)
    const open = Boolean(anchorEl)

    const handleClick = () => {
        if (props.type === 'image') {
            props.setImgUrl(props.filepath)
        } else if (props.extension === 'pdf') {
            let anchor = document.createElement("a")
            document.body.appendChild(anchor)
            anchor.setAttribute('href', props.filepath)
            anchor.setAttribute('target', '_blank')
            anchor.setAttribute('rel', 'noopener norefferer')
            anchor.click()
            document.body.removeChild(anchor)
        } else {
            let anchor = document.createElement("a")
            document.body.appendChild(anchor)
            anchor.setAttribute('href', props.filepath_download)
            anchor.click()
            document.body.removeChild(anchor)
        }
    }
    
    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget)

        return false // to prevent the default context menu popping up
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDownload = () => {
        let anchor = document.createElement("a")
        document.body.appendChild(anchor)
        anchor.setAttribute('href', props.filepath_download)
        anchor.click()
        document.body.removeChild(anchor)
    }

    const renderFileIcon = useMemo(() => {
        switch (props.extension) {
            case 'doc':
                return docx
            case 'docx':
                return docx
            case 'xls':
                return excel
            case 'xlsx':
                return excel
            case 'pdf':
                return pdf
        }

        switch (props.type) {
            case 'image':
                return image
            case 'video':
                return movie
            case 'document':
                return documentIcon
            default:
                return others
        }
    }, [props.extension, props.type])

    const isNew = useMemo(() => {
        const oneDay = 60 * 60 * 24 * 1000
        const createdDate = new Date(props.created_at)
        const currentDate = new Date()
        
        return currentDate.getTime() - createdDate.getTime() < oneDay
    }, [props.created_at])
  
    return (
        <div>
            <div
                className={classes.container}
                onClick={handleClick}
                onContextMenu={handleRightClick}
            >
                <img src={renderFileIcon} alt="file icon" className={classes.icon} width={22} height={22} />
                <p
                    className={classes.name}
                >
                    {props.name}.{props.extension}
                </p>
                {
                    isNew &&
                    <div className={classes.newDot}>
                        New
                    </div>
                }
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    props.onEdit(props)
                }}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    props.onDelete(false, props.id)
                }}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    handleDownload()
                }}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                        <CloudDownload fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Download</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default FileContext

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        icon: {
            marginRight: 12,
        },
        name: {
            margin: 0,
        },
        newDot: {
            padding: 4,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4,
            marginLeft: 8,
            color: 'white',
            fontSize: 10,
            lineHeight: 1,
            fontWeight: 'bold',
        },
    })
);