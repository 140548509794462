import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import GeneralModal from './GeneralModal'
import TextFieldSelect from '../_form/TextFieldSelect'
import AsyncAutoComplete, { IAutoCompleteOption } from '../_form/AsyncAutoComplete'
import DatePicker from '../_form/DatePicker'

/**
 * Utils
 */
import { Grid, TextField, Typography } from '@mui/material'
import { CustomRulesOuput, IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import PhotoPreview from '../PhotoPreview'
import ButtonUpload from '../ButtonUpload'
import Swal from 'sweetalert2'
import { getBase64, resizeAndResetOrientationImage } from '../../_utils/Helper'

interface GeneralFormModalProps {
    title: string
    open: boolean
    onClose: () => void
    onSubmit: (
        state: GeneralFormState,
        onFinal: () => void
    ) => void
    forms: Form[]
}

export type GeneralFileType = {
    file: File | null
    url: string
}

type Form = {
    name: string
    label: string
    placeholder?: string
    defaultValue: string | null | Date | GeneralFileType
    type: 'input' | 'textArea' | 'select' | 'datePicker' | 'autocomplete' | 'image'
    /**
     * Process original value received from onChange
     * @param value original value that received from onChange
     * @returns processed value to be saved
     */
    processValue?: (value: string | null | Date | GeneralFileType) => string | null | Date | GeneralFileType
    // Autocompelte url
    url?: string
    // Autocomplete initial query
    initialQuery?: string
    // Select form options
    options?: {
        value: string
        label: string
        disabled?: boolean
    }[]
    required?: boolean
    /**
     * Extra validation other than required
     * @example otherValidation="email|min-char=3"
     */
    otherValidation?: string
    customValidation?: (value: string | null | Date | GeneralFileType) => CustomRulesOuput
    // Alias for validation
    alias?: string
    // Minimum rows for text area
    minRows?: number
    // Skip the resize step on upload image
    skipCompress?: boolean
}

export type GeneralFormState = {
    [key: string]: string | null | Date | GeneralFileType
}

const GeneralFormModal = (props: GeneralFormModalProps) => {
    const [isLoading, setIsLoading] = useState(false)

    // Get initial state based on the formst defaultValue
    const initialState = useMemo(() => props.forms.reduce((prev, next) => ({ ...prev, [next.name]: next.defaultValue }), {}), [props.forms])

    const [state, setState] = useState<GeneralFormState>(initialState)
    const [error, setError] = useState<IValidationErrors<GeneralFormState>>({})

    // Reset state, error and isLoading value if form is closed
    useEffect(() => {
        if (!props.open) {
            setState(initialState)
            setError({})
            setIsLoading(false)
        }
        // eslint-disable-next-line
    }, [props.open])

    useEffect(() => {
        setState(initialState)
    }, [initialState])

    // Generate form rules based on the required and otherValidation props
    const rules = useMemo(() => {
        const newRules: IValidationRules = {}

        props.forms.forEach(form => {
            if (form.customValidation) {
                newRules[form.name] = form.customValidation
            } else if (form.required || form.otherValidation) {
                newRules[form.name] = `${form.required ? 'required' : ''}${form.otherValidation ? `${form.required ? '|' : ''}${form.otherValidation}` : ''}`
            }
        })

        return newRules
    }, [props.forms])

    // Generate form alias, fallback using the form name
    const alias = useMemo(() => {
        const newRules: IValidationAlias = {}

        props.forms.forEach(form => {
            if (form.alias) {
                newRules[form.name] = form.alias || form.name
            }
        })

        return newRules
    }, [props.forms])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, form: Form) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: form.processValue ? form.processValue(value) : value, // Process the value if needed
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteChange = (name: string, option: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [name]: option.id.toString(),
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setState(prev => ({
            ...prev,
            [name]: null,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleDateChange = (name: string, date: Date | null) => {
        setState(prev => ({
            ...prev,
            [name]: date,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleFileChange = (name: string, file: File | null, skipCompress?: boolean) => {
        if (file) {
            if (file.size >= 5000000) {
                Swal.fire({
                    title: 'Error!',
                    text: `Failed: Max uploaded file is 5MB`,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            } else {
                const processBase64 = (file: File) => {
                    getBase64(file)
                        .then(base64 => {
                            setState(prev => ({
                                ...prev,
                                [name]: {
                                    file,
                                    url: base64 as string,
                                },
                            }))
                        })

                    setError(prev => ({
                        ...prev,
                        [name]: '',
                    }))
                }

                if (skipCompress) {
                    processBase64(file)
                } else {
                    resizeAndResetOrientationImage(file)
                        .then((file: File) => {
                            processBase64(file)
                        })
                }
            }
        } else {
            setState(prev => ({
                ...prev,
                [name]: {
                    file: null,
                    url: '',
                },
            }))

            setError(prev => ({
                ...prev,
                [name]: '',
            }))
        }
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, rules, alias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            // Pass the validated state to the parent and pass the setIsLoading(false) function
            props.onSubmit(state, () => {
                setIsLoading(false)
            })
        }
    }

    const renderForm = (form: Form) => {
        switch (form.type) {
            case 'input':
                return (
                    <TextField
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        value={form.processValue ? form.processValue(state[form.name]) : state[form.name]}
                        onChange={e => handleInputChange(e, form)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                        fullWidth
                    />
                )
            case 'textArea':
                return (
                    <TextField
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        value={form.processValue ? form.processValue(state[form.name]) : state[form.name]}
                        onChange={e => handleInputChange(e, form)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                        fullWidth
                        multiline
                        minRows={form.minRows || 3}
                    />
                )
            case 'select':
                return (
                    <TextFieldSelect
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        value={form.processValue ? form.processValue(state[form.name]) : state[form.name]}
                        onChange={e => handleInputChange(e, form)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                        fullWidth
                    >
                        {
                            form.options!.map((option, index) => (
                                <option key={index} value={option.value} disabled={option.disabled}>{option.label}</option>
                            ))
                        }
                    </TextFieldSelect>
                )
            case 'autocomplete':
                return (
                    <AsyncAutoComplete
                        label={form.label}
                        name={form.name}
                        placeholder={form.placeholder}
                        initialQuery={form.initialQuery}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteInputChange}
                        errorText={error[form.name]}
                        url={form.url!}
                        disabled={isLoading}
                    />
                )
            case 'datePicker':
                return (
                    <DatePicker
                        label={form.label}
                        value={state[form.name] as Date | null}
                        onChange={date => handleDateChange(form.name, date)}
                        error={!!error[form.name]}
                        helperText={error[form.name]}
                        disabled={isLoading}
                    />
                )
            case 'image':
                return (
                    <>
                        <Typography variant='h6' fontSize={16} sx={{ mb: 1 }}>
                            {form.label}
                        </Typography>
                        {
                            (state[form.name] as GeneralFileType).url ?
                                <PhotoPreview
                                    index={1}
                                    onRemoveFile={() => handleFileChange(form.name, null)}
                                    src={(state[form.name] as GeneralFileType).url}
                                    style={{
                                        margin: 0,
                                    }}
                                    disabled={isLoading}
                                />
                                :
                                <ButtonUpload
                                    onChange={(e) => handleFileChange(form.name, (e as React.ChangeEvent<HTMLInputElement>).target.files![0], form.skipCompress)}
                                    name="image_thumbnail"
                                    style={{
                                        margin: 0,
                                    }}
                                />
                        }
                        {
                            error[form.name] ?
                                <Typography sx={{ mt: 1 }} color='#d32f2f' fontSize={12}>
                                    {error[form.name]}
                                </Typography>
                                : null
                        }
                    </>
                )
            default: return 'Form type is not supported'
        }
    }

    return (
        <GeneralModal
            title={props.title}
            open={props.open}
            onClose={isLoading ? () => { } : props.onClose}
            buttons={[
                {
                    text: 'Submit',
                    color: 'primary',
                    onClick: handleSubmit,
                    isLoading,
                }
            ]}
            maxWidth='xs'
        >
            <Grid container spacing={2}>
                {
                    props.forms.map(form =>
                        <Grid item xs={12} key={form.name}>
                            {renderForm(form)}
                        </Grid>
                    )
                }
            </Grid>
        </GeneralModal>
    )
}

export default GeneralFormModal
