import React, { useState } from 'react';
import { Button, Switch, IconButton, Tooltip, Grid, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import ProjectPlacementModal from './_components/ProjectPlacementModal';
import ProjectPicModal from './_components/ProjectPicModal';

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import GroupIcon from '@mui/icons-material/Group';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
        actionContainer: {
            display: 'flex'
        },
        addButton: {
            marginBottom: '20px'
        },
        importButton: {
            marginBottom: '20px'
        },
        errorContainer: {
            maxHeight: '200px',
            overflowY: 'auto'
        }
    })
)

const ProjectList = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/project';
    const permissions = usePermission()
    const classes = useStyles();
    const history = useHistory();
    const [projectPlacementModalValue, setProjectPlacementModalValue] = useState(0);
    const [projectPicModalValue, setProjectPicModalValue] = useState(0);

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'project_pics',
            label: 'PIC',
            filterable: true,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((agent_data, key) => {
                            if (agent_data.includes('IS_NOT_ACTIVE')) {
                                let agent_name = agent_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {agent_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{agent_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const agent_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {agent_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        {
                            permissions['project.manage-pic'] &&
                            <Tooltip title="Edit Project PIC">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setProjectPicModalValue(row.id)}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                );
            }
        },
        {
            name: 'project_placement_member',
            label: 'Agents',
            filterable: true,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((agent_data, key) => {
                            if (agent_data.includes('IS_NOT_ACTIVE')) {
                                let agent_name = agent_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {agent_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{agent_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const agent_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {agent_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        {
                            permissions['project.manage-member'] &&
                            <Tooltip title="Edit Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setProjectPlacementModalValue(row.id)}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                );
            }
        },
        {
            name: 'is_apartment_project',
            label: 'Is Apartment',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return +data ? 'Yes' : 'No';
            }
        },
        {
            name: 'is_published',
            label: 'Is Published',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return permissions['project.publish']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            render: (row: any) => {
                // const checkPermission = function (permission_type: string) {
                //     return typeof permissions[permission_type] !== "undefined" && permissions[permission_type]
                // }

                return (
                    <div className={classes.actionContainer}>
                        {
                            permissions['project.view'] &&
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => history.push(`project/edit/${row.id}`)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        }

                        <Tooltip title="See Preview">
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handlePreview(row.id)}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>

                        {
                            permissions['project.delete'] ?
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleDelete(row.id)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton> :
                                <></>
                        }
                    </div>
                );
            }
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handlePreview = (id: string) => {
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/generate-preview-redis`, { type: 'project', id })
            .then(res => {
                window.open(`${process.env.REACT_APP_MAIN_URL}/preview/project/${id}`);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                console.log(result)
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/project/${id}`)
                        .then(res => {
                            Swal.fire({
                                title: 'Delete successful',
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime());
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })

    }

    const handlePublishSwitch = (project: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-publish`, { id: project.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +project.id) element['is_published'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            const fd = new FormData();
            fd.append('import_file', files[0]);

            setIsLoading(true);
            DefaultAxios
                .post(`${API_URL}/import`, fd)
                .then((res: { data: string[] }) => {
                    let title = 'Upload successful';
                    let message = '';
                    let icon: 'success' | 'warning' = 'success';

                    if (res.data.length) {
                        title = 'Upload with partiel error';
                        icon = 'warning';
                        message = '<ul class="' + classes.errorContainer + '">';
                        for (let m of res.data) {
                            message += `<li>${m}</li>`;
                        }
                        message += '</ul>';
                    }

                    Swal.fire({
                        title: title,
                        html: message,
                        icon: icon
                    });
                    setReDrawDatatable(new Date().getTime());
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = '';
                    })
                    setIsLoading(false);
                });
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <ProjectPlacementModal
                isOpen={projectPlacementModalValue !== 0 ? true : false}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setProjectPlacementModalValue(0);
                }}
                project_id={projectPlacementModalValue}
            />

            <ProjectPicModal
                isOpen={projectPicModalValue !== 0 ? true : false}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setProjectPicModalValue(0);
                }}
                project_id={projectPicModalValue}
            />

            <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                <Grid item>
                    {localStorage.getItem('role') !== 'pic-project' && permissions['project.manage'] &&
                        <Link to="/project/add">
                            <Button
                                variant="contained"
                                color="primary"
                                component="label"
                                style={{ marginBottom: 24 }}
                                size="small"
                                startIcon={<AddIcon />}
                            >
                                Add Project
                            </Button>
                        </Link>
                    }
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        style={{ marginBottom: 24 }}
                        size="small"
                        startIcon={<GetAppIcon />}
                    >
                        Import
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".xls,.xlsx"
                            onChange={handleImportFileChanged}
                        />
                    </Button>
                </Grid>
            </Grid>


            <DataTable
                url={API_URL}
                reDraw={reDrawDatatable}
                columns={columns}
            />
        </>
    );
}

export default ProjectList;