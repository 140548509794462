import React, { Fragment, useState, useReducer, useRef, RefObject, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Material UI
import { createStyles, makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, FormGroup, Switch, Divider, Collapse, Theme } from '@mui/material';
// import Autocomplete from '@mui/lab/Autocomplete';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isValidLatLng, renderWarningButton } from '../../_utils/Helper';
import LocalDatePicker from '../../_components/_form/DatePicker';
import { format } from 'date-fns';

interface Props {
    clusterId: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    name: string;
    project_id: string;
    project_name: string;
    subproject_id: string;
    subproject_name: string;
    category: string;
    lead_type: string;
    sap_lead_code: string;
    address: string;
    latitude: string;
    longitude: string;
    matterport: string;
    sinarmas_landing_url: string;
    aerial_view: string;
    salescandy: string;
    meta_title: string;
    meta_description: string;
    meta_keyword: string;
    zip_code: string;
    area: string;
    is_additional_data: boolean;
    is_meta: boolean;
    f_club_house: boolean;
    f_park: boolean;
    f_double_layer_parking: boolean
    f_security: boolean;
    f_grocery_store: boolean;
    f_lounge: boolean;
    f_internet: boolean;
    f_jogging_track: boolean;
    f_pool: boolean;
    f_playground_indoor: boolean;
    f_playground_outdoor: boolean;
    f_shophouse: boolean;
    f_sky_pool: boolean;
    f_pool_bar: boolean;
    f_tree_house: boolean;
    f_nursery: boolean;
    f_mini_golf: boolean;
    f_junior_climbing: boolean;
    f_bbq_area: boolean;
    f_kidz_pool: boolean;
    f_mall: boolean;
    f_sky_garden: boolean;
    f_garden: boolean;
    f_atm: boolean;
    f_basket_court: boolean;
    f_tv_cable: boolean;
    f_tennis_court: boolean;
    f_pet_allowed: boolean;
    f_movie_room: boolean;
    f_gym_indoor: boolean;
    f_gym_outdoor: boolean;
    f_spa: boolean;
    f_sauna: boolean;
    f_jacuzzi: boolean;
    f_business_center: boolean;
    f_library: boolean;
    f_mini_market: boolean;
    f_ampli_theater: boolean;
    f_virtual_game_room: boolean;
    f_function_room: boolean;
    f_study_room: boolean;
    f_private_dining_room: boolean;
    tags: any;
    article: string;
    highlights: string;
    promotion_banner_path: File | null | string
    promotion_banner_caption: string
    small_banner_path: File | null | string
    small_banner_url: string
    last_updated_aerial_view: Date | null
}

interface IErrorState {
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    promotion_banner_caption: string;
    small_banner_url: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IRefs {
    [key: string]: RefObject<HTMLInputElement>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switchContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        leadTypeContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        collapse: {
            paddingLeft: '12px',
            paddingRight: '12px',
            width: '100%',
            '& .MuiCollapse-wrapper': {
                paddingTop: '12px',
                paddingBottom: '12px'
            }
        },
        formContainer: {
            marginTop: '10px',
            marginLeft: '20px',
            marginRight: '20px',
            display: "flex"
        },
        fileList: {
            marginBottom: 8,
            display: 'flex'
        },
        filePreview: {
            width: '65px',
            height: '65px',
            overflow: 'hidden',
            float: 'left',
            '& img': {
                width: '100%',
                objectFit: 'contain',
            }
        },
        fileDetail: {
            width: '100%',
            marginLeft: '10px',
            '& p': {
                margin: 0
            }
        },
        borderBottomLine: {
            borderBottom: '1px solid #eee',
            marginBottom: 0
        },
    }),
);

const ClusterDetailForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/cluster';
    const params: any = useParams();
    const history = useHistory();
    const classes = useStyles();

    const [staticImagesData, setStaticImagesData] = useState({
        promotionBanner: {
            path: '',
            caption: '',
        },
        smallBanner: {
            path: '',
        },
    })

    const booleanAttributes = [
        'is_apartment_project',
        'f_club_house',
        'f_park',
        'f_double_layer_parking',
        'f_security',
        'f_grocery_store',
        'f_lounge',
        'f_internet',
        'f_jogging_track',
        'f_pool',
        'f_playground_indoor',
        'f_playground_outdoor',
        'f_shophouse',
        'f_sky_pool',
        'f_pool_bar',
        'f_tree_house',
        'f_nursery',
        'f_mini_golf',
        'f_junior_climbing',
        'f_bbq_area',
        'f_kidz_pool',
        'f_mall',
        'f_sky_garden',
        'f_garden',
        'f_atm',
        'f_basket_court',
        'f_tv_cable',
        'f_tennis_court',
        'f_pet_allowed',
        'f_movie_room',
        'f_gym_indoor',
        'f_gym_outdoor',
        'f_spa',
        'f_sauna',
        'f_jacuzzi',
        'f_business_center',
        'f_library',
        'f_mini_market',
        'f_ampli_theater',
        'f_virtual_game_room',
        'f_function_room',
        'f_study_room',
        'f_private_dining_room',
    ];

    const refs: IRefs = {
        name: useRef<HTMLInputElement>(null),
        address: useRef<HTMLInputElement>(null),
        latitude: useRef<HTMLInputElement>(null),
        longitude: useRef<HTMLInputElement>(null),
        promotion_banner_caption: useRef<HTMLInputElement>(null),
        small_banner_url: useRef<HTMLInputElement>(null),
    }

    const initialState = {
        name: '',
        category: '',
        lead_type: 'salescandy',
        sap_lead_code: '',
        address: '',
        project_id: '',
        project_name: '',
        subproject_id: '',
        subproject_name: '',
        latitude: '',
        longitude: '',
        matterport: '',
        sinarmas_landing_url: '',
        aerial_view: '',
        salescandy: '',
        meta_title: '',
        meta_description: '',
        meta_keyword: '',
        zip_code: '',
        area: '',
        is_additional_data: false,
        is_meta: false,
        f_club_house: false,
        f_park: false,
        f_double_layer_parking: false,
        f_security: false,
        f_grocery_store: false,
        f_lounge: false,
        f_internet: false,
        f_jogging_track: false,
        f_pool: false,
        f_playground_indoor: false,
        f_playground_outdoor: false,
        f_shophouse: false,
        f_sky_pool: false,
        f_pool_bar: false,
        f_tree_house: false,
        f_nursery: false,
        f_mini_golf: false,
        f_junior_climbing: false,
        f_bbq_area: false,
        f_kidz_pool: false,
        f_mall: false,
        f_sky_garden: false,
        f_garden: false,
        f_atm: false,
        f_basket_court: false,
        f_tv_cable: false,
        f_tennis_court: false,
        f_pet_allowed: false,
        f_movie_room: false,
        f_gym_indoor: false,
        f_gym_outdoor: false,
        f_spa: false,
        f_sauna: false,
        f_jacuzzi: false,
        f_business_center: false,
        f_library: false,
        f_mini_market: false,
        f_ampli_theater: false,
        f_virtual_game_room: false,
        f_function_room: false,
        f_study_room: false,
        f_private_dining_room: false,
        tags: [],
        article: '',
        highlights: '',
        promotion_banner_path: null,
        promotion_banner_caption: '',
        small_banner_path: null,
        small_banner_url: '',
        last_updated_aerial_view: null
    };

    const initialErrorState = {
        name: '',
        address: '',
        latitude: '',
        longitude: '',
        promotion_banner_caption: '',
        small_banner_url: '',
    };

    // Input Reducer
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    // const [projectList, setProjectList] = useState<Array<IListProject>>([]);
    // const [tagList, setTagList] = useState<Array<IListTag>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [warningPromotionBanner, setWarningPromotionImage] = useState(false);
    const [warningSmallBanner, setWraningSmallBanner] = useState(false);
    const [disableCategory, setDisableCategory] = useState(false);

    useEffect(() => {
        if (params.id) {
            loadData();
        } else {
            loadFormData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const resData = res.data;
                const newState: any = {};

                for (let [key, value] of Object.entries(resData)) {
                    if (booleanAttributes.includes(key)) {
                        newState[key] = !!value;
                    } else if (key === 'lead_type') {
                        newState[key] = !value ? 'salescandy' : value;
                    } else {
                        newState[key] = value;
                    }
                }

                setStaticImagesData({
                    promotionBanner: {
                        path: resData.promotion_banner_path || '',
                        caption: resData.promotion_banner_caption || '',
                    },
                    smallBanner: {
                        path: resData.small_banner_path || '',
                    }
                })
                // setDisableCategory(!!resData.is_apartment_project);
                setDisableCategory(true);
                loadFormData(newState);
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loadFormData = (state?: IState) => {
        // DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/form-data`)
        //     .then(res => {
        //         setProjectList(res.data.project);
        //         // setTagList(res.data.tags);
        //     });
    }

    const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
        window.scrollTo({ top: ref.current!.offsetTop - 64, behavior: 'smooth' });
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        switch (name) {
            case 'promotion_banner_path': {
                let oldBanner = inputState.promotion_banner_path
                if (target.files && target.files[0]) {
                    oldBanner = target.files[0];
                    checkImageDimension(target.files[0], 'promotion_banner');
                } else {
                    oldBanner = null;
                    setWarningPromotionImage(false);
                }
                value = oldBanner;
                name = 'promotion_banner_path';
                break
            }
            case 'small_banner_path': {
                let oldBanner = inputState.small_banner_path
                if (target.files && target.files[0]) {
                    oldBanner = target.files[0];
                    checkImageDimension(target.files[0], 'small_banner');
                } else {
                    oldBanner = null;
                    setWarningPromotionImage(false);
                }
                value = oldBanner;
                name = 'small_banner_path';
                break
            }
            default: {
                if (target.type === 'checkbox') {
                    value = target.checked;
                }
                break
            }
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { ...inputState };
        newState.lead_type = event.target.checked ? 'sap' : 'salescandy';

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    };

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.project_id = String(value.id);

        if (value.is_apartment_project) {
            newState.category = 'apartment';
        }

        setDisableCategory(!!value.is_apartment_project);
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteSubproject = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.subproject_id = String(value.id);
        newState.subproject_name = value.label

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkImageDimension = (file: File, type: 'promotion_banner' | 'small_banner') => {
        let img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = (e: { target: any }) => {
            if (e.target) {
                if (type === 'promotion_banner') {
                    setWarningPromotionImage(+e.target.width !== 460 || +e.target.height !== 500);
                } else {
                    setWraningSmallBanner(+e.target.width !== 460 || +e.target.height !== 500);
                }
                URL.revokeObjectURL(objectUrl);
            }
        };
        img.src = objectUrl;
    }

    const handleEditorChanged = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            if (!firstError) firstError = 'name';
            newError.name = 'Name wajib diisi';
            isValid = false;
        }

        if (!inputState.latitude) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Latitude wajib diisi';
            isValid = false;
        } else if (!isValidLatLng(inputState.latitude)) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Format latitude tidak valid';
            isValid = false;
        }

        if (!inputState.longitude) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Longitude wajib diisi';
            isValid = false;
        } else if (!isValidLatLng(inputState.longitude)) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Format longitude tidak valid';
            isValid = false;
        }

        if (inputState.promotion_banner_path && !inputState.promotion_banner_caption) {
            newError.promotion_banner_caption = 'Promotion Banner Caption harus diisi apabila ingin menambahkan Promotion Banner';
            isValid = false;
            if (!firstError) firstError = 'promotion_banner_caption';
        } else {
            newError.promotion_banner_caption = '';
        }

        if (!inputState.small_banner_url && inputState.small_banner_path) {
            newError.small_banner_url = 'Small Banner Url harus diisi';
            isValid = false;
        } else {
            if (inputState.small_banner_url && !inputState.small_banner_url.includes('https:')) {
                newError.small_banner_url = 'Url harus menggunakan protocol https';
                isValid = false;
            } else {
                newError.small_banner_url = '';
            }
        }

        if (firstError) scrollToRef(refs[firstError]);
        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const apartmentFacilities = {
        // Apartment
        'f_shophouse': 'Shophouse',
        'f_sky_pool': 'Sky Pool',
        'f_pool_bar': 'Pool Bar',
        'f_tree_house': 'Tree House',
        'f_nursery': 'Nursery',
        'f_mini_golf': 'Mini Golf',
        'f_junior_climbing': 'Junior Climbing',
        'f_bbq_area': 'BBQ Area',
        'f_kidz_pool': 'Kids Pool',
        'f_mall': 'Mall',
        'f_sky_garden': 'Sky Garden',
        'f_garden': 'Garden',
        'f_atm': 'ATM',
        'f_basket_court': 'Basket Court',
        'f_tv_cable': 'TV Cable',
        'f_tennis_court': 'Tennis Court',
        'f_pet_allowed': 'Pet Allowed',
        'f_movie_room': 'Movie Room',
        'f_gym_indoor': 'Gym Indoor',
        'f_gym_outdoor': 'Gym Outdoor',
        'f_spa': 'Spa',
        'f_sauna': 'Sauna',
        'f_jacuzzi': 'Jacuzzi',
        'f_business_center': 'Business Center',
        'f_library': 'Library',
        'f_mini_market': 'Mini Market',
        'f_ampli_theater': 'Amphitheater',
        'f_virtual_game_room': 'Virtual Game Room',
        'f_function_room': 'Function Room',
        'f_study_room': 'Study Room',
        'f_private_dining_room': 'Private Dining Room',
    }

    const facilities = useMemo(() => {
        let facilitiesLocal: { [key: string]: string } = {
            'f_club_house': 'Club House',
            'f_park': 'Park',
            // Regular
            'f_security': 'Security',
            'f_grocery_store': 'Grocery Store',
            'f_lounge': 'Lounge',
            'f_internet': 'Internet',
            'f_jogging_track': 'Jogging Track',
            'f_pool': 'Swimming Pool',
            'f_playground_indoor': 'Playground Indoor',
            'f_playground_outdoor': 'Playground Outdoor',
        }

        if (inputState.category === 'residential') {
            facilitiesLocal['f_double_layer_parking'] = 'Double Layer Parking'
        }

        return facilitiesLocal
    }, [inputState.category])

    const handleChangeFacilities = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.checked;

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }


    const renderOptions = (odd: boolean) => {
        var rows: any = [];
        let i = 0;

        const orderedFacilities: { [k: string]: string } = Object.entries(facilities)
            .sort(([, a], [, b]) => (a < b) ? -1 : (a > b) ? 1 : 0)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

        for (let [key, value] of Object.entries(orderedFacilities)) {
            const condition = odd ? i % 2 !== 0 : i % 2 === 0;
            if (condition) {
                let checked = false;
                let key_fac = key;
                for (let [key, value] of Object.entries(inputState)) {
                    if (key_fac === key) {
                        checked = value as boolean;
                    }
                }
                rows.push(<div style={{ display: "block", width: "100%", marginLeft: '20px' }} key={key}><FormControlLabel
                    control={<Checkbox
                        checked={checked}
                        onChange={handleChangeFacilities}
                        name={key} />}
                    label={value}
                /></div>)
            }
            i++;
        }
        return rows;
    }

    const renderOptionsApartment = (leftOver: number) => {
        var rows: any = [];
        let i = 0;

        const orderedFacilities: { [k: string]: string } = Object.entries(apartmentFacilities)
            .sort(([, a], [, b]) => (a < b) ? -1 : (a > b) ? 1 : 0)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

        for (let [key, value] of Object.entries(orderedFacilities)) {
            const condition = i % 3 === leftOver;
            if (condition) {
                let checked = false;
                let key_fac = key;
                for (let [key, value] of Object.entries(inputState)) {
                    if (key_fac === key) {
                        checked = value as boolean;
                    }
                }
                rows.push(<div key={key} style={{ display: "block", width: "100%", marginLeft: '20px' }}><FormControlLabel
                    control={<Checkbox
                        checked={checked}
                        onChange={handleChangeFacilities}
                        name={key} />}
                    label={value}
                /></div>)
            }
            i++;
        }
        return rows;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;
        const fd = new FormData();
        const convertDate = ['last_updated_aerial_view']

        for (let [key, value] of Object.entries(inputState)) {
            if (key === 'promotion_banner_path') {
                if (inputState.promotion_banner_path) {
                    fd.append('promotion_banner_path', inputState.promotion_banner_path);
                    fd.append('promotion_banner_caption', inputState.promotion_banner_caption);
                }
            } else if (key === 'small_banner_path') {
                if (inputState.small_banner_path) {
                    fd.append('small_banner_path', inputState.small_banner_path);
                }
            } else if (convertDate.includes(key)) {
                fd.append(key, value ? format(new Date(value), 'yyyy-MM-dd') : '')
            } else if (value === null) {
                fd.append(key, '');
            } else {
                fd.append(key, value);
            }
        }

        if (params.id) {
            fd.append('_method', 'patch');
            axios = DefaultAxios.post(`${API_URL}/${params.id}`, fd);
        } else {
            axios = DefaultAxios.post(API_URL, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {
                            history.push('/cluster');
                        } else {
                            history.push('/cluster');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDeleteAsset = (type: 'promotion_banner' | 'small_banner') => {
        renderWarningButton("Apakah anda yakin ingin menghapus asset ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/${params.id}${type === 'promotion_banner' ? '/delete-promotion-banner' : '/delete-small-banner'}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadData();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    useEffect(() => {
        if (inputState.meta_description || inputState.meta_title || inputState.meta_keyword) {
            setInputState({ name: 'is_meta', value: true, type: 'SET_ITEM' });
        }
    }, [inputState.meta_description, inputState.meta_title, inputState.meta_keyword])

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <h2 className={classes.borderBottomLine} style={{ marginTop: 0 }}>Unit Details</h2>
                </Grid>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Project"
                        name="project_id"
                        initialQuery={inputState.project_name}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/project`}
                        iconSearch
                    />
                </Grid>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Sub Project"
                        name="subproject_id"
                        initialQuery={inputState.subproject_name}
                        onChange={handleAutocompleteSubproject}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/subproject?project_id=${inputState.project_id}`}
                        iconSearch
                        disabled={!inputState.project_id}
                    />
                </Grid>
                <Grid item xs={!params.id && inputState.subproject_id ? 6 : 12}>
                    <TextField
                        label="Nama"
                        name="name"
                        value={inputState.name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.name}
                        helperText={errorState.name}
                        ref={refs.name}
                    />
                </Grid>

                {
                    (!params.id && inputState.subproject_id) &&
                    <Grid item xs={6}>
                        <TextField
                            label=""
                            name=""
                            value={inputState.subproject_name ? `at ${inputState.subproject_name}` : ''}
                            variant="outlined"
                            fullWidth
                            onChange={handleChanged}
                            disabled
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <TextField
                        label="Address"
                        name="address"
                        value={inputState.address}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.address}
                        helperText={errorState.address}
                        ref={refs.address}
                    />
                </Grid>

                {/* <Grid item xs={12}>
                <TextField
                    id="outlined-select-currency-native"
                    name="project_id"
                    select
                    label="Select project"
                    value={inputState.project_id}
                    fullWidth
                    onChange={handleChanged}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                >
                    <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                    {projectList.map(data => {
                        return (
                            <option value={data.id} key={data.id}>{data.name}</option>
                        )
                    })}
                </TextField>
            </Grid> */}

                <Grid item xs={12}>
                    <TextField
                        id="outlined-select-currency-native"
                        name="category"
                        select
                        label="Kategori"
                        value={inputState.category}
                        fullWidth
                        onChange={handleChanged}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                        disabled={disableCategory}
                    >
                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                        <option aria-label="category" value="residential">Residential</option>
                        <option aria-label="category" value="apartment">Apartment</option>
                        <option aria-label="category" value="shophouse">Shophouse</option>
                    </TextField>
                </Grid>

                {/* <Collapse in={inputState.is_apartment_project} className={classes.collapse}> */}
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                label="Latitude"
                                name="latitude"
                                value={inputState.latitude}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                                error={!!errorState.latitude}
                                helperText={errorState.latitude}
                                ref={refs.latitude}
                                placeholder="Ex: -6.198049"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Longitude"
                                name="longitude"
                                value={inputState.longitude}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                                error={!!errorState.longitude}
                                helperText={errorState.longitude}
                                ref={refs.longitude}
                                placeholder="Ex: 106.7626502"
                            />
                        </Grid>

                    </Grid>
                    {/* <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        value={inputState.tags}
                        options={tagList}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            handleChangeTag(newValue)
                        }}
                        getOptionSelected={(option: any, value: any) => option.id === value.id}
                        filterSelectedOptions
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Category"
                            style={{marginTop: '30px'}}
                            placeholder="Choose Category"
                            onKeyDown={newTag}
                            helperText="Press enter to add new category. Notes: new category will save after submit form"
                        />
                        )}
                    />
                </Grid> */}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Zip Code"
                        name="zip_code"
                        value={inputState.zip_code}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Area"
                        name="area"
                        value={inputState.area}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Matterport Link"
                        name="matterport"
                        value={inputState.matterport}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Contoh: https://my.matterport.com/show/?m=Lu8V2q29ju9"
                    // error={!!errorState.matterport}
                    // helperText={errorState.matterport}
                    // ref={refs.matterport}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Landing URL"
                        name="sinarmas_landing_url"
                        value={inputState.sinarmas_landing_url}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Contoh: https://sinarmasland.com/development/residential/hylands"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Aerial View"
                        name="aerial_view"
                        value={inputState.aerial_view}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        InputLabelProps={{ shrink: true }}
                        placeholder="URL Aerial View"
                    />
                </Grid>

                <Grid item xs={12}>
                    <LocalDatePicker
                        label='Last Updated Aerial View'
                        value={inputState.last_updated_aerial_view}
                        onChange={(date) => {
                            setInputState({
                                name: 'last_updated_aerial_view',
                                type: 'SET_ITEM',
                                value: date
                            })
                        }}

                    />
                </Grid>

                <Grid item xs={12}>
                    <h2 className={classes.borderBottomLine}>Lead Type</h2>
                </Grid>
                {
                    localStorage.getItem('role') === 'superadmin' ||
                        (localStorage.getItem('role') === 'pic-project' && !params.id)
                        ? <>
                            {/* <Grid item xs={12}>
                            <Typography>
                                <strong>Lead Type</strong>
                            </Typography>
                        </Grid> */}
                            <Grid item xs={12} className={classes.leadTypeContainer}>
                                <Typography>
                                    Salescandy
                                </Typography>
                                <Switch
                                    value={true}
                                    checked={inputState.lead_type === 'sap'}
                                    onChange={handleSwitch}
                                />
                                <Typography>
                                    SAP
                                </Typography>
                            </Grid>
                        </>
                        : null
                }

                {
                    inputState.lead_type === 'salescandy'
                        ? <Grid item xs={12} key="salescandy">
                            <TextField
                                label="Salescandy Link"
                                name="salescandy"
                                value={inputState.salescandy}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                            // error={!!errorState.salescandy}
                            // helperText={errorState.salescandy}
                            // ref={refs.salescandy}
                            />
                        </Grid>
                        : <Grid item xs={12} key="sap">
                            <TextField
                                label="SAP Lead Code"
                                name="sap_lead_code"
                                value={inputState.sap_lead_code}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                            // error={!!errorState.salescandy}
                            // helperText={errorState.salescandy}
                            // ref={refs.salescandy}
                            />
                        </Grid>
                }

                <Grid item xs={12}>
                    <h2 className={classes.borderBottomLine}>Facilities</h2>
                </Grid>

                <Grid item xs={6}>
                    <FormGroup>
                        {renderOptions(false)}
                    </FormGroup>
                </Grid>

                <Grid item xs={6}>
                    <FormGroup>
                        {renderOptions(true)}
                    </FormGroup>
                </Grid>

                {
                    (inputState.category === 'apartment') ?
                        <>
                            <Grid item xs={12}>
                                <Typography>
                                    <strong>Apartment Facilities</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup>
                                    {renderOptionsApartment(0)}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup>
                                    {renderOptionsApartment(1)}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup>
                                    {renderOptionsApartment(2)}
                                </FormGroup>
                            </Grid>
                        </>
                        : <></>
                }
                {/* <Grid item xs={12}>
                <TextField
                    label="Meta Title"
                    name="meta_title"
                    value={inputState.meta_title}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Meta Description"
                    name="meta_description"
                    value={inputState.meta_description}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                />
            </Grid> */}

                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                            <h2>Additional Datas</h2>

                            <Switch
                                color="primary"
                                name="is_additional_data"
                                checked={inputState.is_additional_data}
                                onChange={handleChanged}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>
                        <Divider variant="fullWidth" />
                    </Grid>
                    <Collapse in={inputState.is_additional_data} timeout="auto" unmountOnExit>
                        <Grid container spacing={3} style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <h3>Highlights</h3>
                                <TextEditor
                                    name="highlights"
                                    value={inputState.highlights}
                                    onChange={handleEditorChanged}
                                    imagesUpload
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <h3>Article</h3>
                                <TextEditor
                                    name="article"
                                    value={inputState.article}
                                    onChange={handleEditorChanged}
                                    imagesUpload
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <h3>Upload Promotion Banner</h3>
                                {
                                    staticImagesData.promotionBanner.path ?
                                        <div className={classes.fileList}>
                                            <div className={classes.filePreview}>
                                                <img src={staticImagesData.promotionBanner.path} alt={staticImagesData.promotionBanner.caption} />
                                            </div>
                                            <div className={classes.fileDetail}>
                                                <p>{staticImagesData.promotionBanner.caption}</p>
                                                <Link
                                                    component="button"
                                                    color="secondary"
                                                    onClick={() => handleDeleteAsset('promotion_banner')}
                                                >
                                                    Delete
                                                </Link>
                                            </div>
                                        </div>
                                        : null
                                }
                                <input type="file" name="promotion_banner_path" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                                <br />
                                <i style={{ paddingBottom: 8, display: 'block' }}>Recommended dimension : 460 x 500</i>
                                {
                                    warningPromotionBanner &&
                                    <>
                                        <br />
                                        <i style={{ color: 'red' }}>
                                            Gambar yang di upload tidak memiliki dimensi yang sama dengan yang direkomendasikan.
                                            <br />
                                            Hal ini akan menyebabkan gambar terpotong atau tidak sesuai yang diharapkan saat tampil di halaman utama
                                        </i>
                                        <br />
                                        &nbsp;
                                    </>
                                }
                                <TextField
                                    label="Promotion Banner Caption"
                                    name="promotion_banner_caption"
                                    value={inputState.promotion_banner_caption}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    error={!!errorState.promotion_banner_caption}
                                    helperText={errorState.promotion_banner_caption}
                                    ref={refs.promotion_banner_caption}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <h3>Upload Small Banner</h3>
                                {
                                    staticImagesData.smallBanner.path ?
                                        <div className={classes.fileList}>
                                            <div className={classes.filePreview}>
                                                <img src={staticImagesData.smallBanner.path} alt='Small Banner' />
                                            </div>
                                            <div className={classes.fileDetail}>
                                                <p>Small Banner</p>
                                                <Link
                                                    component="button"
                                                    color="secondary"
                                                    onClick={() => handleDeleteAsset('small_banner')}
                                                >
                                                    Delete
                                                </Link>
                                            </div>
                                        </div>
                                        : null
                                }
                                <input type="file" name="small_banner_path" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                                <br />
                                <i style={{ paddingBottom: 8, display: 'block' }}>Recommended dimension : 460 x 500</i>
                                {
                                    warningSmallBanner &&
                                    <>
                                        <br />
                                        <i style={{ color: 'red' }}>
                                            Gambar yang di upload tidak memiliki dimensi yang sama dengan yang direkomendasikan.
                                            <br />
                                            Hal ini akan menyebabkan gambar terpotong atau tidak sesuai yang diharapkan saat tampil di halaman utama
                                        </i>
                                        <br />
                                        &nbsp;
                                    </>
                                }
                                <TextField
                                    label="Small Banner Url"
                                    name="small_banner_url"
                                    value={inputState.small_banner_url}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    error={!!errorState.small_banner_url}
                                    helperText={errorState.small_banner_url}
                                    ref={refs.small_banner_url}
                                    disabled={!inputState.small_banner_path}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                            <h2>Meta</h2>

                            <Switch
                                color="primary"
                                name="is_meta"
                                checked={inputState.is_meta}
                                onChange={handleChanged}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>
                        <Divider variant="fullWidth" />
                    </Grid>

                    <Collapse in={inputState.is_meta} timeout="auto" unmountOnExit>
                        <Grid container spacing={3} style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField
                                    name="meta_title"
                                    label="Meta Title"
                                    value={inputState.meta_title}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Title"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="meta_description"
                                    title="Meta Description"
                                    value={inputState.meta_description}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Description"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="meta_keyword"
                                    label="Meta Keyword"
                                    value={inputState.meta_keyword}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Keyword"
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ClusterDetailForm;