import React, { useState, useEffect } from 'react';
import { Dialog, Theme, AppBar, Toolbar, IconButton, Typography, Container, Box, Grid, Paper, Divider } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';

// Components
import CommentList, { IUser, IComment } from '../../_components/CommentList';
import LoadingScreen from '../../_components/LoadingScreen';
import InfoTable from '../_listingList/InfoTable';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

interface ICommentState {
    comments: IComment[];
    users: IUser[];
}

interface IProps {
    id?: string
    close?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // backgroundColor: "#f3f3f3",
            // padding: theme.spacing(2),
            // height: "100%"
        },
        action: {
            display: 'inline-flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRoot: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            // overflow: 'hidden'
        },
        appBar: {
            position: 'relative'
        },
        dialog: {
            backgroundColor: '#333333'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        box: {
            padding: theme.spacing(2)
        },
        boxCenter: {
            padding: theme.spacing(2),
            textAlign: "center"
        },
        textWithIcon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(1)
        },
        paper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        h6_title: {
            color: theme.palette.primary.main,
        },
        footer: {
            display: 'flex',
            // minHeight: '500px',
            backgroundColor: '#eee',
            padding: '10px',
        }
    })
)

const ModalDetailListing = (props: IProps) => {
    const classes = useStyles();
    const history = useHistory();
    const params: any = useParams();
    const id = props.id || params.id
    const COMMENT_API_URL = `${process.env.REACT_APP_API_URL}/comment/listing/${id}`;

    const initialCommentState = {
        comments: [],
        users: []
    }

    const initialListingDetail = {
        name: '',
        code: '',
        property_type: '',
        cluster_label: '',
        project_label: '',
        is_star: false,
        address: '',
        f_private_pool: false,
        f_indoor_pool: false,
        f_outdoor_pool: false,
        f_air_conditioner: false,
        f_water_heater: false,
        f_access_card: false,
        f_stove: false,
        f_hood: false,
        f_oven: false,
        f_kitchen_cabinet: false,
        f_kitchen_island: false,
        f_sink: false,
        f_wardrobe: false,
        f_bathtube: false,
        f_shower: false,
        f_closet: false,
        f_basin: false,
        f_vanity: false,
        f_mirror: false,
        f_balcony: false
    }

    const facilities = {
        'f_private_pool': 'Private Pool',
        'f_indoor_pool': 'Indoor Pool',
        'f_outdoor_pool': 'Outdoor Pool',
        'f_air_conditioner': 'AC',
        'f_water_heater': 'Water Heater',
        'f_access_card': 'Access Card',
        'f_stove': 'Stove',
        'f_hood': 'Hood',
        'f_oven': 'Oven',
        'f_kitchen_cabinet': 'Kitchen Cabinet',
        'f_kitchen_island': 'Kitchen Island',
        'f_sink': 'Sink',
        'f_wardrobe': 'Wardrobe',
        'f_bathtube': 'Bathtub',
        'f_shower': 'Shower',
        'f_closet': 'Closet',
        'f_basin': 'Basin',
        'f_vanity': 'Vanity',
        'f_mirror': 'Mirror',
        'f_balcony': 'Balcony'
    }

    const [isLoading, setIsLoading] = useState(false);
    const [listingDetail, setListingDetail] = useState(initialListingDetail);
    const [commentState, setCommentState] = useState<ICommentState>(initialCommentState);

    useEffect(() => {
        setIsLoading(true);
        loadComment();
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing/${id}`)
            .then(res => {
                let data = res.data
                setListingDetail(data);
            })
            .finally(() => {
                setIsLoading(false)
            })
        // eslint-disable-next-line
    }, [id])

    const handleClose = () => {
        if (props.close) {
            props.close()
        } else {
            const urlParam = sessionStorage.getItem('urlParam')
            history.push(`/listing?${urlParam}`);
        }
    }

    const loadComment = () => {
        DefaultAxios.get(COMMENT_API_URL)
            .then(res => {
                let data = res.data
                setCommentState(data);
            })
    }

    const handleReply = (value: string, mentionData: any) => {
        setIsLoading(true);
        DefaultAxios
            .post(COMMENT_API_URL, { value, mentionData })
            .then(res => {
                setTimeout(loadComment, 100);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const renderFacilities = () => {
        var rows: any = [];
        for (let [key, value] of Object.entries(facilities)) {
            let checked = false;
            let key_fac = key;
            for (let [key, value] of Object.entries(listingDetail)) {
                if (key_fac === key) {
                    checked = value as boolean;
                }
            }
            if (checked) {
                rows.push(<li>{value}</li>)
            }
        }
        return (
            <ul style={{ margin: '0', padding: 'inherit' }}>
                {rows}
            </ul>
        )
    }
    
    return (
        <Dialog
            fullScreen
            open={true}
            className={classes.dialog}
        >
            <LoadingScreen open={isLoading} fullScreen />
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Listing Details
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth={false} className={classes.root}>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6" className={classes.h6_title}>
                                    Informasi Dasar
                                </Typography>

                                <Divider></Divider>
                                <Box className={classes.box}>
                                    <InfoTable
                                        data={[
                                            ["Nama Listing", listingDetail.name, false],
                                            ["Code Listing", listingDetail.code, false],
                                            ["Property Type", listingDetail.property_type, false],
                                            ["Nama Cluster", listingDetail.cluster_label, false],
                                            ["Nama Project", listingDetail.project_label, false],
                                            ["Alamat", listingDetail.address, false],
                                            ["Star", listingDetail.is_star ? 'Yes' : 'No', false],
                                            ["Facilities", renderFacilities(), false],
                                        ]}
                                    />
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6" className={classes.h6_title}>
                                    Komentar
                                </Typography>

                                <Divider></Divider>

                                <div className={classes.footer}>
                                    <CommentList
                                        comments={commentState.comments}
                                        users={commentState.users}
                                        onReply={handleReply}
                                    />
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Dialog>
    );
}

export default ModalDetailListing;