import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

// Interfaces
import { IState } from '../../../_components/_form/ImageForm/Page';

interface Props {
    form: IState;
    onChange: Function;
    onSelect: Function
    index: number;
    placeholder?: string;
}

const SiteHomepageBannerForm = (props: Props) => {
    return (
        <Grid container spacing={2} style={{ marginLeft: 4, marginRight: 4 }}>
            <Grid item xs={12} md={12}>
                <Typography>Desktop Image</Typography>
                <input type="file" name="file" onChange={(e) => props.onChange(e, props.index)} />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography>Mobile Image</Typography>
                <input type="file" name="file_mobile" onChange={(e) => props.onChange(e, props.index)} />
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="url"
                            variant="outlined"
                            value={props.form.url}
                            onChange={(e) => props.onChange(e, props.index)}
                            label="URL"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {props.placeholder ?
                            <i>{props.placeholder}</i>
                            :
                            <i>Recommended dimension : 1500 x 1000 or larger with the same ratio</i>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SiteHomepageBannerForm;
