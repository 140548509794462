import React from 'react';
import { Grid, TextField } from '@mui/material';

// Interfaces
import { IState } from './Page';

interface Props {
    form: IState;
    onChange: Function;
    onSelect: Function
    index: number;
}

const VideoForm = (props: Props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="name"
                            variant="outlined"
                            value={props.form.name}
                            onChange={(e) => props.onChange(e, props.index)}
                            placeholder="Max 50 karakter"
                            label="Name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="url"
                            variant="outlined"
                            style={{cursor: 'pointer'}}
                            value={props.form.url}
                            onChange={(e) => props.onChange(e, props.index)}
                            label="URL"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default VideoForm;
