import React from 'react';

class Dashboard extends React.Component {
    render() {
        return (
            <>
            {console.log('a')}
                <h1>Work Hard, Play Hardest</h1>
            </>
        )
    }
}

export default Dashboard;