// For react and material ui
import React, { useState, useEffect } from 'react';
import {
    useParams,
    useHistory
} from "react-router-dom";

import { makeStyles, createStyles } from "@mui/styles";
import {
    Theme,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Grid,
    Paper,
    Container,
    Box,
    Divider,
    Tabs,
    Tab,
    Button,
    DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// For react and material ui stop

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isJson } from '../../_utils/Helper';

// Import Custom component
import LoadingScreen from '../../_components/LoadingScreen';
import InfoTable from "../_listingList/InfoTable";
import CommentList from '../../_components/CommentList';
import DataTable from '../../_components/_dataTable/DataTable';

interface IProps {
    id?: string
    close?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // backgroundColor: "#f3f3f3",
            // padding: theme.spacing(2),
            // height: "100%"
        },
        action: {
            display: 'inline-flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRoot: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            // overflow: 'hidden'
        },
        appBar: {
            position: 'relative'
        },
        dialog: {
            backgroundColor: '#333333'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        box: {
            padding: theme.spacing(2)
        },
        boxCenter: {
            padding: theme.spacing(2),
            textAlign: "center"
        },
        textWithIcon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(1)
        },
        paper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            position: 'relative'
        },
        h6_title: {
            color: theme.palette.primary.main,
        },
        footer: {
            display: 'flex',
            // minHeight: '500px',
            backgroundColor: '#eee',
            padding: '10px',
        }
    })
)

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & { children?: React.ReactElement },
//     ref: React.Ref<unknown>,
// ) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const ModalDetailCluster = (props: IProps) => {
    const classes = useStyles();
    const params: any = useParams();
    const history = useHistory();
    const id = props.id || params.id
    const COMMENT_API_URL = `${process.env.REACT_APP_API_URL}/comment/cluster/${id}`;
    const LOG_API_URL = `${process.env.REACT_APP_API_URL}/log/cluster/${id}`;

    const initialClusterDetail = {
        id: "",
        name: "",
        project_name: "",
        address: "",
        is_apartment: "",
        latitude: "",
        longitude: "",
        f_club_house: false,
        f_park: false,
        f_security: false,
        f_grocery_store: false,
        f_lounge: false,
        f_internet: false,
        f_jogging_track: false,
        f_pool: false,
        f_playground_indoor: false,
        f_playground_outdoor: false,
        f_shophouse: false,
        f_sky_pool: false,
        f_pool_bar: false,
        f_tree_house: false,
        f_nursery: false,
        f_mini_golf: false,
        f_junior_climbing: false,
        f_bbq_area: false,
        f_kidz_pool: false,
        f_mall: false,
        f_sky_garden: false,
        f_garden: false,
        f_atm: false,
        f_basket_court: false,
        f_tv_cable: false,
        f_tennis_court: false,
        f_pet_allowed: false,
        f_movie_room: false,
        f_gym_indoor: false,
        f_gym_outdoor: false,
        f_spa: false,
        f_sauna: false,
        f_jacuzzi: false,
        f_business_center: false,
        f_library: false,
        f_mini_market: false,
        f_ampli_theater: false,
        f_virtual_game_room: false,
        f_function_room: false,
        f_study_room: false,
        f_private_dining_room: false,
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFooterLoading, setIsFooterLoading] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState('comment');
    const [footer, setFooter] = useState<any>(null);
    const [clusterDetail, setClusterDetail] = useState(initialClusterDetail);
    const [selectedLog, setSelectedLog] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        setIsLoading(true);
        // loadComment();
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/${id}`)
            .then(res => {
                let data = res.data
                setClusterDetail(data);
            })
            .finally(() => {
                setIsLoading(false)
            })
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        loadFooter();
        // eslint-disable-next-line
    }, [activeTab]);

    const handleClose = () => {
        if (props.close) {
            props.close()
        } else {
            const urlParam = sessionStorage.getItem('urlParam')
            history.push(`/cluster?${urlParam}`);
        }
    }

    const facilities = {
        'f_club_house': 'Club House',
        'f_park': 'Park',
        // Regular
        'f_security': 'Security',
        'f_grocery_store': 'Grocery Store',
        'f_lounge': 'Lounge',
        'f_internet': 'Internet',
        'f_jogging_track': 'Jogging Track',
        'f_pool': 'Swimming Pool',
        'f_playground_indoor': 'Playground Indoor',
        'f_playground_outdoor': 'Playground Outdoor',
        // Apartment
        'f_shophouse': 'Shophouse',
        'f_sky_pool': 'Sky Pool',
        'f_pool_bar': 'Pool Bar',
        'f_tree_house': 'Tree House',
        'f_nursery': 'Nursery',
        'f_mini_golf': 'Mini Golf',
        'f_junior_climbing': 'Junior Climbing',
        'f_bbq_area': 'BBQ Area',
        'f_kidz_pool': 'Kids Pool',
        'f_mall': 'Mall',
        'f_sky_garden': 'Sky Garden',
        'f_garden': 'Garden',
        'f_atm': 'ATM',
        'f_basket_court': 'Basket Court',
        'f_tv_cable': 'TV Cable',
        'f_tennis_court': 'Tennis Court',
        'f_pet_allowed': 'Pet Allowed',
        'f_movie_room': 'Movie Room',
        'f_gym_indoor': 'Gym Indoor',
        'f_gym_outdoor': 'Gym Outdoor',
        'f_spa': 'Spa',
        'f_sauna': 'Sauna',
        'f_jacuzzi': 'Jacuzzi',
        'f_business_center': 'Business Center',
        'f_library': 'Library',
        'f_mini_market': 'Mini Market',
        'f_ampli_theater': 'Ampli Theater',
        'f_virtual_game_room': 'Virtual Game Room',
        'f_function_room': 'Function Room',
        'f_study_room': 'Study Room',
        'f_private_dining_room': 'Private Dining Room',
    }

    const renderFacilities = () => {
        var rows: any = [];
        for (let [key, value] of Object.entries(facilities)) {
            let checked = false;
            let key_fac = key;
            for (let [key, value] of Object.entries(clusterDetail)) {
                if (key_fac === key) {
                    checked = value as boolean;
                }
            }
            if (checked) {
                rows.push(<li>{value}</li>)
            }
        }
        return (
            <ul style={{ margin: '0', padding: 'inherit' }}>
                {rows}
            </ul>
        )
    }

    const loadFooter = () => {
        let url: string = '';

        switch (activeTab) {
            case 'comment':
                url = COMMENT_API_URL;
                break;
            case 'log':
                url = LOG_API_URL;
                break;
            default:
                break;
        }

        if (activeTab === 'log') {
            setFooter(
                <DataTable 
                    url={url}
                    columns={
                        [
                            {
                                name: 'datetime',
                                label: 'Tanggal',
                                type: 'date',
                                filterable: false,
                                sortable: false
                            },
                            {
                                name: 'user_name',
                                label: 'User',
                                type: 'string',
                                filterable: false,
                                sortable: false
                            },
                            {
                                name: 'label',
                                label: 'Tipe',
                                type: 'string',
                                filterable: false,
                                sortable: false
                            },
                            {
                                name: 'EXTRA',
                                label: 'Action',
                                type: 'string',
                                filterable: false,
                                sortable: false,
                                render: (data: any) => {
                                    return <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setSelectedLog(isJson(data.log) ? JSON.parse(data.log) : data.log);
                                            handleModalOpen();
                                        }}
                                    >
                                        See Log
                                    </Button>
                                }
                            },
                        ]
                    }
                />
            )
            return;
        }

        if (!url || !id) {
            return;
        }

        setIsFooterLoading(true);
        DefaultAxios
            .get(url)
            .then(res => {
                switch (activeTab) {
                    case 'comment':
                        setFooter(
                            <CommentList
                                comments={res.data.comments}
                                users={res.data.users}
                                onReply={handleReply}
                            />
                        )
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleReply = (value: string, mentionData: any) => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(COMMENT_API_URL, { value, mentionData })
            .then(res => {
                setTimeout(loadFooter, 100);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {selectedLog ? JSON.stringify(selectedLog, null, 2) : 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={true}
                className={classes.dialog}
            >
                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Cluster Details
                            </Typography>
                    </Toolbar>
                </AppBar>

                <Container maxWidth={false} className={classes.root}>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6" className={classes.h6_title}>
                                        Informasi Dasar
                                            </Typography>

                                    <Divider></Divider>
                                    <Box className={classes.box}>
                                        <InfoTable
                                            data={[
                                                ["Nama Cluster", clusterDetail.name, false],
                                                ["Nama Project", clusterDetail.project_name, false],
                                                ["Alamat", clusterDetail.address, false],
                                                // ["Latitude", clusterDetail.is_apartment ? clusterDetail.latitude : '-', false],
                                                // ["Longitude", clusterDetail.is_apartment ? clusterDetail.longitude : '-', false],
                                                ["Category", clusterDetail.is_apartment ? 'Apartment' : 'Non Apartment', false],
                                                ["Facilities", renderFacilities(), false],
                                            ]}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <LoadingScreen open={isFooterLoading} />
                                    <Tabs
                                        value={activeTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={(e, v) => setActiveTab(v)}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label="Komentar" value="comment" />
                                        <Tab label="Log" value="log" />
                                    </Tabs>
                                    <div className={classes.footer}>
                                        {footer}
                                    </div>
                                    {/* <Typography variant="h6" className={classes.h6_title}>
                                        Komentar
                                    </Typography>

                                    <Divider></Divider>

                                    <div className={classes.footer}>
                                        <CommentList
                                            comments={commentState.comments}
                                            users={commentState.users}
                                            onReply={handleReply}
                                        />
                                    </div> */}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Dialog>
        </>
    )
}

export default ModalDetailCluster;