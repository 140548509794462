import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

/**
 * Utils 
 * */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import TextArea from '../../_components/_form/TextArea';

interface IAgent {
    id: string;
    name: string;
    profile_description: string
    clusters: ICluster[]
}

interface ICluster {
    id: string;
    name: string;
}

const Top5Agent = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/site-setting/top-5-agents';

    const initialClusters = [
        {
            id: '',
            name: '',
        },
        {
            id: '',
            name: '',
        },
        {
            id: '',
            name: '',
        },
    ];

    const initialAgents = [
        {
            id: '',
            name: '',
            profile_description: '',
            clusters: initialClusters.slice()
        },
        {
            id: '',
            name: '',
            profile_description: '',
            clusters: initialClusters.slice()
        },
        {
            id: '',
            name: '',
            profile_description: '',
            clusters: initialClusters.slice()
        },
        {
            id: '',
            name: '',
            profile_description: '',
            clusters: initialClusters.slice()
        },
        {
            id: '',
            name: '',
            profile_description: '',
            clusters: initialClusters.slice()
        },
    ];

    const initialErrorState = [
        {
            id: '',
            profile_description: '',
        },
        {
            id: '',
            profile_description: '',
        },
        {
            id: '',
            profile_description: '',
        },
        {
            id: '',
            profile_description: '',
        },
        {
            id: '',
            profile_description: '',
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [agents, setAgents] = useState<IAgent[]>(initialAgents);
    const [errors, setErrors] = useState(initialErrorState);

    useEffect(() => {
        loadData();

        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                const newState = agents.slice();

                res.data.map((agent: IAgent, index: number) => {
                    newState[index] = { ...newState[index], ...agent };

                    newState[index].clusters.map((cluster, i) => {
                        cluster.id = cluster.id || '';
                        cluster.name = cluster.name || '';

                        return cluster;
                    });

                    return agent;
                });

                setAgents(newState);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (index: number, name: string, value: IAutoCompleteOption) => {
        setAgents(prevState => {
            const newState = prevState.slice();
            newState[index].id = String(value.id);
            newState[index].name = value.label;

            return newState;
        });
    }

    const handleAutocompleteCluster = (index: number, agentIndex: number, name: string, value: IAutoCompleteOption) => {
        setAgents(prev => prev.map((agent, mapIndex) => ({
            ...agent,
            clusters: agent.clusters.map((cluster, clusterMapIndex) => {
                if (mapIndex === agentIndex && index === clusterMapIndex) {
                    return {
                        id: String(value.id),
                        name: value.label,
                    }
                }
                
                return cluster
            })
        })))
    }

    const handleAutocompleteInputChanged = (index: number, e: any, name: string) => {
        setAgents(prevState => {
            const newState = prevState.slice();
            newState[index].id = '';
            newState[index].name = '';

            return newState;
        });
    }

    const handleAutocompleteInputClusterChanged = (index: number, agentIndex: number, e: any, name: string) => {
        setAgents(prevState => {
            const newState = prevState.slice();
            newState[agentIndex].clusters[index].id = '';
            newState[agentIndex].clusters[index].name = '';

            return newState;
        });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = initialErrorState.slice();

        agents.map((agent, index) => {
            if (!agent.id) {
                newError[index].id = 'Agent wajib diisi';
                isValid = false;
            }
            if (!agent.profile_description) {
                newError[index].profile_description = 'Profile agent wajib diisi';
                isValid = false;
            }
            return agent;
        });

        setErrors(newError);
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        setIsLoading(true);
        DefaultAxios.post(API_URL, {
            'agents': agents,
        })
            .then(res => {
                Swal.fire({
                    title: 'Submit berhasil',
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: loadData
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const value = e.target.value

        setAgents(prevState => {
            const newState = prevState.slice();
            newState[index].profile_description = value;

            return newState;
        });
    }

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={6}>
                <Grid container spacing={3}>
                    {
                        agents.map(
                            (agent, index) =>
                            <React.Fragment key={index}>
                                <Grid item xs={12}>
                                    <span>
                                        Agent {index + 1}
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutoComplete
                                        label="Agent"
                                        name="agent_id"
                                        initialQuery={agent.name}
                                        onChange={(name: string, value: IAutoCompleteOption) => handleAutocomplete(index, name, value)}
                                        onInputChange={(e: any, name: string) => handleAutocompleteInputChanged(index, e, name)}
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                                        errorText={errors[index].id}
                                        iconSearch
                                    />
                                </Grid>
                                <Grid item xs ={12}>
                                    <TextArea
                                        label="Deskripsi Profile"
                                        name="profile_description"
                                        value={agent.profile_description}
                                        onChange={(e) => handleChange(e, index)}
                                        rows={3}
                                        error={!!errors[index].profile_description}
                                        helperText={errors[index].profile_description}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <span>
                                        Top Cluster
                                    </span>
                                </Grid>
                                {
                                    agent.clusters.map(
                                        (cluster, i) => 
                                        <React.Fragment key={Number(`${index}${i}`)}>
                                            <Grid item xs={12}>
                                                <span>
                                                    Cluster {i + 1}
                                                </span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AsyncAutoComplete
                                                    label="Cluster"
                                                    name="cluster_id"
                                                    initialQuery={cluster.name}
                                                    onChange={(name: string, value: IAutoCompleteOption) => handleAutocompleteCluster(i, index, name, value)}
                                                    onInputChange={(e: any, name: string) => handleAutocompleteInputClusterChanged(i, index, e, name)}
                                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster`}
                                                    iconSearch
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    index !== 4 &&
                                    <Grid item xs={12}>
                                        <hr />
                                    </Grid>
                                }
                            </React.Fragment>
                        )
                    }
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Top5Agent;