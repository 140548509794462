import React, { useState, useEffect } from "react"
import { copyToClipboard } from "../_utils/Helper"
import ModalContainer from "./_modals/ModalContainer"
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from "@mui/material"
import { makeStyles, createStyles } from '@mui/styles';

interface IProps {
    title?: string
    description?: string
    /** custom url if it's not for current page url */
    uri?: string
    buttonType?: number
    setIsShareOpen?: (isOpen: boolean) => void
}

const ShareDropdown = ({ title, description, uri, buttonType, setIsShareOpen }: IProps) => {
    const [isOpen, setOpen] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [url, setUrl] = useState('')

    const classes = useStyles()

    useEffect(() => {
        window.addEventListener('click', closeDropDown)
        setUrl(uri || window.location.href)
        return () => {
            window.removeEventListener('click', closeDropDown)
        }
        // eslint-disable-next-line
    }, [])

    const toggleDropDown = (e: any) => {
        e.stopPropagation()
        setOpen(prev => !prev)
    }

    const toggleModal = (e: any) => {
        e.stopPropagation()
        if (!!navigator.share) {
            navigator.share({
                title: title || 'PLACEHOLDER_TITLE',
                text: description || 'PLACEHOLDER_DESCRIPTION',
                url
            })
        } else {
            setModalOpen(prev => !prev)
            if (setIsShareOpen) setIsShareOpen(!isOpen)
        }
    }

    const closeDropDown = () => {
        setOpen(false)
    }

    const closeModal = () => {
        setModalOpen(false)
        if (setIsShareOpen) setIsShareOpen(false)
    }

    return (
        <div className={`${buttonType === 3 ? '' : classes['mx-3']} ${classes.wrapper}`} style={{position: 'relative'}}>
            <button onClick={toggleDropDown} className={classes.desktopButtonTwo}>
                <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/share-alt.svg`} alt="Share" className={classes['mr-2']} style={{ filter: 'brightness(0) saturate(100%) invert(15%) sepia(89%) saturate(3982%) hue-rotate(349deg) brightness(101%) contrast(95%)' }} />
                <p className={classes.shareText}>Share</p>
            </button>
            <button onClick={toggleModal} className={classes.mobileButtonTwo} style={{ paddingLeft: buttonType === 3 ? '0.5rem' : '1.5rem', paddingRight: buttonType === 3 ? '0.5rem' : '1.5rem' }}>
                <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/share-alt.svg`} alt="Share" className={classes['mr-2']} style={{ filter: 'brightness(0) saturate(100%) invert(15%) sepia(89%) saturate(3982%) hue-rotate(349deg) brightness(101%) contrast(95%)' }} />
                <p className={classes.shareText}>Share</p>
            </button>
            <div className={`${classes.dropdownContainer} ${ isOpen ? `${classes.hidden} ${classes.lg_block}` : classes.hidden } `} style={{ width: 'max-content' }}>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="__blank" className={classes.dropdownMiddle}>
                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/facebook-alt.svg`} alt="facebook" className={classes["mr-3"]} style={{ height: 15 }} />
                    <p>Facebook</p>
                </a>
                <a href={`https://twitter.com/intent/tweet?url=${url}`} target="__blank" className={classes.dropdownMiddle}>
                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/twitter.svg`} alt="twitter" className={classes["mr-3"]} style={{ height: 15 }} />
                    <p>Twitter</p>
                </a>
                <a href={`whatsapp://send?text=${url}`} target="__blank" className={classes.dropdownMiddle}>
                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/whatsapp-border-alt.svg`} alt="whatsapp" className={classes["mr-3"]} style={{ height: 15 }} />
                    <p>Whatsapp</p>
                </a>
                <a href={`mailto:?body=${url}&subject=${title || 'PLACEHOLDER_TITLE'}`} target="__blank" className={classes.dropdownMiddle}>
                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/mail.svg`} alt="email" className={classes["mr-3"]} style={{ height: 15 }} />
                    <p>Email</p>
                </a>
                <span onClick={() => copyToClipboard(url)} className={classes.dropdownMiddle}>
                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/link.svg`} alt="link" className={classes["mr-3"]} style={{ height: 15 }} />
                    <p>Copy Link</p>
                </span>
            </div>
            {
                <span className="lg:hidden">
                    <ModalContainer
                        isOpen={isModalOpen}
                        fullScreen={true}
                        close={closeModal}
                        noPadding
                    >
                        <div style={{ backgroundColor: 'white' }}>
                            <div className={classes.modalContainer}>
                                <h4 className={classes.modalShareText}>Share</h4>
                                {/* <FontAwesomeIcon onClick={closeModal} icon="times" width="0" className="absolute right-0 mr-5" /> */}
                                <CloseIcon onClick={closeModal} style={{ position: 'absolute', right: 0, marginRight: '1.25rem', top: 0, marginTop: '1.25rem' }} />
                            </div>
                            <div className="flex flex-col">
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="__blank" className={classes.modalDropdownMiddle}>
                                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/facebook-alt.svg`} alt="facebook" className={`${classes["h-5"]} ${classes["mr-3"]}`} />
                                    <p>Facebook</p>
                                </a>
                                <a href={`https://twitter.com/intent/tweet?url=${url}`} target="__blank" className={classes.modalDropdownMiddle}>
                                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/twitter.svg`} alt="twitter" className={`${classes["h-5"]} ${classes["mr-3"]}`} />
                                    <p>Twitter</p>
                                </a>
                                <a href={`whatsapp://send?text=${url}`} target="__blank" className={classes.modalDropdownMiddle}>
                                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/whatsapp-border-alt.svg`} alt="whatsapp" className={`${classes["h-5"]} ${classes["mr-3"]}`} />
                                    <p>Whatsapp</p>
                                </a>
                                <a href={`mailto:?body=${url}&subject=${title || 'PLACEHOLDER_TITLE'}`} target="__blank" className={classes.modalDropdownMiddle}>
                                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/mail.svg`} alt="email" className={`${classes["h-5"]} ${classes["mr-3"]}`} />
                                    <p>Email</p>
                                </a>
                                <span onClick={() => copyToClipboard(url)} className={classes.modalDropdownMiddle}>
                                    <img src={`${process.env.REACT_APP_MAIN_URL}/static/images/svg/link.svg`} alt="link" className={`${classes["h-5"]} ${classes["mr-3"]}`} />
                                    <p>Copy Link</p>
                                </span>
                            </div>
                        </div>
                    </ModalContainer>
                </span>
            }
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
        wrapper: {
            '& p': {
                margin: 0,
            },
            '& a': {
                color: '#484848',
                textDecoration: 'none'
            },
        },
        desktopButtonTwo: {
            display: 'none',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            alignItems: 'center',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            fontSize: 16,
            borderWidth: 0,
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            },
            '&:focus': {
                outline: 'none'
            },
        },
        mobileButtonTwo: {
            display: 'flex',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            alignItems: 'center',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            fontSize: 16,
            borderWidth: 0,
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        shareText: {
            fontSize: 14,
            fontWeight: 600,
        },
        dropdownContainer: {
            position: 'absolute',
            fontSize: 14,
            bottom: 0,
            right: 0,
            marginBottom: '-0.5rem',
            backgroundColor: 'white',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            transform: 'translateY(100%)',
            borderRadius: '0.25rem',
            zIndex: 5,
        },
        dropdownMiddle: {
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid #e2e8f0',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            paddingLeft: '0.75rem',
            paddingRight: '4rem',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#e6f2ff'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        modalContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        modalShareText: {
            fontSize: '1.5rem',
            color: '#484848',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            fontWeight: 300,
        },
        modalDropdownMiddle: {
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid #e2e8f0',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            paddingLeft: '1.5rem',
            paddingRight: '4rem',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#e6f2ff'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        'mr-2': {
            marginRight: '0.5rem',
        },
        'mr-3': {
            marginRight: '0.75rem'
        },
        'mx-3': {

        },
        'h-5': {
            height: '1.25rem'
        },
        hidden: {
            display: 'none'
        },
        lg_block: {
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        }
    })
)

export default ShareDropdown
