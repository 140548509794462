import React, { ReactElement, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Link, useHistory } from "react-router-dom";

/**
 * List
 */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CollapsibleList from './CollapsibleList';

/**
 * Icon
 */
import ArticleIcon from '@mui/icons-material/Article';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import logo from '../Assets/Images/Logo.png';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build'
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// import * as DefaultNotification from "../_utils/DefaultNotification";
// import DefaultEcho from '../_utils/DefaultEcho';
import { checkNotification } from '../_utils/Helper';
import { CloudCircle } from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import { usePermission } from '../_providers/PermissionProvider';

interface Links extends Array<LinksItem | false> { }

interface LinksItem {
  url: string;
  type?: string;
  label: string;
  logo: ReactElement | null;
  children?: Links | false;
  exact?: boolean;
}

interface Props {
  title: string;
  container: any;
  children: any;
}

function generateLinks(links: Links) {
  return links.map((data, index) => {
    if (!data) {
      return null;
    }
    if (data.type === 'parent' && data.children) {
      const childrenLength = data.children.filter(item => item).length;
      if (childrenLength) {
        return (
          <CollapsibleList
            label={data.label}
            logo={data.logo}
            lists={data.children}
            key={index}
          />
        )
      } else {
        return null;
      }
    } else {
      // console.log(data);
      return (
        <Link to={data.url} style={{ textDecoration: 'none', color: 'black' }} key={data.label}>
          <ListItem>
            {data.logo &&
              <ListItemIcon>
                {data.logo}
              </ListItemIcon>
            }
            <ListItemText primary={data.label} />
          </ListItem>
        </Link>
      )
    }
  })
}


const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    '& .MuiListItemIcon-root': {
      minWidth: '45px'
    }
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: "#ef2028",
    boxShadow: '0px 3px 15px #0000001A',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menubarHeaderContainer: {
    height: '64px',
    // backgroundColor: '#009be5',
    // color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #eee'
  },
  logo: {
    height: '50px'
  }
}));

// function Notif() {
//   const history = useHistory();

//   return <NotificationContext.Consumer>
//       {context => (
//           <IconButton
//               color="inherit"
//               onClick={() => history.push('/notification')}
//           >
//               <Badge variant="dot" color="error" overlap="circle" invisible={!context.isUnread}>
//                   <NotificationsIcon />
//               </Badge>
//           </IconButton>
//       )}
//   </NotificationContext.Consumer>
// }

function ResponsiveDrawer(props: Props) {
  const history = useHistory();
  const { container } = props;
  const permissions = usePermission()
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isUnread, setIsUnread] = React.useState(false);

  const connectEcho = () => {
    // DefaultEcho.private(`App.Models.User.${localStorage.getItem("user_id")}`)
    //   .notification((notification: any) => {
    //     navigator.serviceWorker.ready.then(function (registration) {
    //       registration.showNotification(notification.title, notification);
    //     });
    //     setIsUnread(true)
    //   });
  }

  useEffect(() => {
    if (localStorage.getItem("jwt_token") !== null) {
      connectEcho()
      checkNotification()
        .then(res => {
          setIsUnread(res as boolean)
        })
    }
  }, [])

  const salesLeaderUrlLinks: Links = [
    { url: '/', label: 'Dashboard', logo: <DashboardIcon />, exact: true },
    { url: '/profile-setting', label: 'Profile Setting', logo: <SettingsApplicationsIcon />, exact: true },
    { url: '/drive', label: 'My Drive', logo: <CloudCircle />, exact: true }
  ];

  const url_links: Links = [
    { url: '/', label: 'Dashboard', logo: <DashboardIcon />, exact: true },
    {
      url: '#', type: 'parent', label: 'Report', logo: <AssessmentIcon />, children: [
        permissions['agent-report.view'] ? { url: '/agent-weekly-report', label: 'Agent Weekly Report', logo: null, exact: true } : false,
        permissions['agent-360.view'] ? { url: '/agent-360-report', label: '360 Sales Report', logo: null, exact: true } : false,
        permissions['agent.report'] ? { url: '/agent-performance-report', label: 'Agent Performance Report', logo: null, exact: true } : false,
        permissions['agent-captain.report'] ? { url: '/captain-performance-report', label: 'Captain Performance Report', logo: null, exact: true } : false,
        permissions['agent-office.report'] ? { url: '/agency-performance-report', label: 'Agency Performance Report', logo: null, exact: true } : false,
      ]
    },
    {
      url: '#', type: 'parent', label: 'Master Data', logo: <StorageIcon />, children: [
        { url: '/enquiry', label: 'Enquiry', logo: null },
        permissions['city.view'] ? { url: '/city', label: 'City', logo: null } : false,
        permissions['project.view'] ? { url: '/project', label: 'Project', logo: null } : false,
        permissions['subproject.view'] ? { url: '/subproject', label: 'Sub Project', logo: null } : false,
        permissions['cluster.view'] ? { url: '/cluster', label: 'Cluster', logo: null } : false,
        permissions['listing.view'] ? { url: '/listing', label: 'Listing', logo: null } : false,
        permissions['agent.view'] || permissions['agent.view-external'] || permissions['agent.view-captain'] ? { url: '/agent', label: 'Agent', logo: null, exact: true } : false,
        permissions['agent-office.view'] ? { url: '/agent-office', label: 'Agent Office', logo: null, exact: true } : false,
        permissions['agent-team.manage'] ? { url: '/agent-team', label: 'Agent Team', logo: null, exact: true } : false,
        permissions['user.view'] ? { url: '/user-list', label: 'User', logo: null, exact: true } : false,
        permissions['account.list'] ? { url: '/account', label: 'Account', logo: null, exact: true } : false,
      ]
    },


    /**
     * Settings
     */
    {
      url: '#', type: 'parent', label: 'Settings', logo: <SettingsIcon />, children: [
        permissions['promotion-sort.view'] ? { url: '/sort-promotion', label: 'Sort Promotion', logo: null } : false,
        permissions['site.view'] ? { url: '/site-setting', label: 'Site Setting', logo: null, exact: true } : false,
        permissions['wish-for-home.view'] ? { url: '/wish-for-home', label: 'Wish For Home Page', logo: null, exact: true } : false,
        permissions['site.view'] ? { url: '/site-homepage-banner', label: 'Homepage Banners', logo: null, exact: true } : false,
        { url: '/top-5-agents', label: 'Top 5 Agents', logo: null, exact: true },
      ]
    },

    /**
     * Tools
     */
    {
      url: '#', type: 'parent', label: 'Tools', logo: <BuildIcon />, children: [
        permissions['website-feedback.list'] ? { url: '/website-feedback', label: 'Website Feedback', logo: null } : false,
        permissions['site.view'] ? { url: '/agent-login-logs', label: 'Agent Login Logs', logo: null } : false,
        permissions['site.view'] ? { url: '/user-login-logs', label: 'User Login Logs', logo: null } : false,
        permissions['site.view'] ? { url: '/kpr-logs', label: 'KPR Logs', logo: null } : false,
        permissions['site.view'] ? { url: '/kpr-logs-old', label: 'KPR Logs (Old Data)', logo: null } : false,
        permissions['blacklisted-phone.manage'] ? { url: '/blacklisted-phone', label: 'Blacklisted Phone', logo: null } : false,
        // permissions['blacklisted-phone.manage'] ? { url: '/blacklisted-phone', label: 'Blacklisted Phone', logo: null } : false,
        permissions['page-tracking.export'] ? { url: '/page-tracking', label: 'Page Tracking', logo: null } : false,
        permissions['account-tracking.manage'] ? { url: '/account-tracking', label: 'Account Tracking', logo: null } : false,
        permissions['wishlist.export'] ? { url: '/wishlist-export', label: 'Wishlist Export', logo: null } : false,
        permissions['pilar-project.manage'] ? { url: '/pilar-project', label: 'Pilar Project', logo: null } : false,
        permissions['pilar-project.manage'] ? { url: '/pilar-export', label: 'Pilar Enquiry Export', logo: null } : false,
      ]
    },
    /**
     * Blog
     */
    {
      url: '#', type: 'parent', label: 'Blog', logo: <ArticleIcon />, children: [
        permissions['blog.manage'] ? { url: '/lp-article', label: 'LP Article', logo: null } : false,
        permissions['blog.view'] ? { url: '/article', label: 'Blog / Promotion', logo: null } : false,
      ]
    },

    { url: '/profile-setting', label: 'Profile Setting', logo: <SettingsApplicationsIcon />, exact: true },
    permissions['drive.view'] ? { url: '/drive', label: 'My Drive', logo: <CloudCircle />, exact: true } : false,

    // permissions['bsd.assign-agent'] ? { url: '/assign-agent-bsd', label: 'Assign BSD Agent', logo: <AssignmentIndIcon />, exact: true } : false,
  ];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.menubarHeaderContainer}>
        {/* <Typography variant="h3" align="center">
          JD360
        </Typography> */}
        <img src={logo} className={classes.logo} alt="logo" />
      </div>
      {/* <Divider></Divider> */}
      <List>
        {generateLinks(localStorage.getItem('role') === 'sales-leader' ? salesLeaderUrlLinks : url_links)}
      </List>
    </div>
  );

  const onLogout = () => {
    // DefaultEcho.disconnect();
    // DefaultNotification.unsubscribe();
    localStorage.clear();
    history.push('/login');
  }

  return (
    <div className={classes.root}>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography>
          <IconButton
            color="inherit"
            edge="end"
            style={{ marginLeft: 'auto' }}
            onClick={() => history.push('/notification')}
          >
            <Badge variant="dot" color="error" overlap="circular" invisible={!isUnread}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            edge="end"
            onClick={onLogout}
          // style={{ marginLeft: 'auto' }}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {
          isMobile ?
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
            :
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
        }
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;