import React, { Fragment, useState, useEffect } from 'react';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import PublishIcon from '@mui/icons-material/Publish';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generalErrorHandler } from '../../_utils/Helper';

interface Props {
}

export interface IState {
    id: string;
    label: string;
    promotion_banner: string;
    sort_promotion: number;
}

const SortPromotion = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/cluster'
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        // setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}/list-promotion`)
            .then(res => {
                const imageGallery = res.data;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        label: imageGallery[key].name,
                        promotion_banner: imageGallery[key].promotion_banner,
                        sort_promotion: imageGallery[key].sort_promotion,
                    })
                }

                setImageStates(newImageStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSortImage = (imageId: string, dir: 'up' | 'down' | 'top' | 'bottom', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/sort-promotion', { dir, id: imageId })
            .then(res => {
                loadImages();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                // setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />

            <Grid item xs={12}>
                <hr />
            </Grid>

            {/* {renderImages()} */}
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sort</TableCell>
                                <TableCell>Thumbnail</TableCell>
                                <TableCell>Label</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageStates.map((imageState, key) => (
                                <TableRow key={imageState.id}>
                                    <TableCell>
                                        {imageState.sort_promotion}
                                    </TableCell>
                                    <TableCell>
                                        <img src={imageState.promotion_banner} height="100px" alt={`[Gambar ${imageState.label}]`} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {imageState.label}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'top', key)}
                                            disabled={key === 0}
                                        >
                                            <PublishIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'up', key)}
                                            disabled={key === 0}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'down', key)}
                                            disabled={key === imageStates.length - 1}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'bottom', key)}
                                            disabled={key === imageStates.length - 1}
                                        >
                                            <GetAppIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Fragment>
    );
}

export default SortPromotion;
