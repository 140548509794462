import React, { useState } from 'react';

/**
 * Components
*/
import { Button, Grid, Theme, } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios';
import { converNumberFormat, dateFormat, generalErrorHandler } from '../../_utils/Helper';

/**
 * Icons
 */
import PublishIcon from '@mui/icons-material/Publish';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
    })
)

const LogKPROld = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/kpr-tracking';
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}/export${window.location.search}`, {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `kpr-logs.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const columns = [
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return dateFormat(data, 'DD MMM YYYY HH:mm')
            }
        },
        {
            name: 'listing_name',
            label: 'Listing Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'down_payment',
            label: 'DP',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return data ? converNumberFormat(data) : '-'
            }
        },
        {
            name: 'down_payment_percentage',
            label: 'DP Percentage',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return `${data}%`
            }
        },
        {
            name: 'duration',
            label: 'Duration',
            type: 'number',
            filterable: true
        },
        {
            name: 'interest',
            label: 'Interest',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return `${data}%`
            }
        },
        {
            name: 'installment',
            label: 'Installment',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return data ? converNumberFormat(data) : '-'
            }
        },
        {
            name: 'installment_duration',
            label: 'Installment Duration',
            type: 'number',
            filterable: true
        },
        {
            name: 'installment_interest',
            label: 'Installment Interest',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return `${data}%`
            }
        },
        {
            name: 'ip',
            label: 'IP',
            type: 'string',
            filterable: true
        },
    ];

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container direction="row-reverse" className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    size="small"
                    onClick={handleExport}
                    startIcon={<PublishIcon />}
                >
                    Export
                </Button>
            </Grid>
            <DataTable
                url={API_URL}
                columns={columns}
            />
        </>
    );
}

export default LogKPROld;