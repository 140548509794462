import React from 'react';
import { Grid } from '@mui/material';

// Components
import ImageFormPage from '../../_components/_form/ImageForm/Page';

interface Props {
}

const SiteHomepageBannerPage = (props: Props) => {

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h1 style={{ borderBottom: '1px solid #eee' }}>Homepage Banners</h1>
            </Grid>
            <ImageFormPage
                placeholder="Recommended dimension : 1130 x 300 desktop, 420 x 300 mobile"
                API_URL={`${process.env.REACT_APP_API_URL}/site-homepage-banner`}
                siteHomepage
                wishForHome
            />
        </Grid>
    );
}

export default SiteHomepageBannerPage;