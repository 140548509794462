import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';

export interface TagFormModalProps {
    isOpen: boolean
    state: {
        cluster_id: string
        cluster_label: string
    }
    onClose: () => void
    onSubmit: () => void
    onHandleAutocomplete: (name: string, value: IAutoCompleteOption) => void;
    onHandleAutocompleteInputChanged: (e: any, name: string) => void;
}

const TagFormModal = (props: TagFormModalProps) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>Tag</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label="Nama Cluster"
                            name="cluster_id"
                            initialQuery={props.state.cluster_label|| ''}
                            onChange={props.onHandleAutocomplete}
                            onInputChange={props.onHandleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/project-drive-folder-cluster`}
                            iconSearch
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={props.onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TagFormModal
