import { Button, Typography, Theme, Grid, FormControl, Select, MenuItem, Tooltip, IconButton, TextField } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import ShareDropdown from '../../_components/ShareDropdown';
import DeleteEnquiryModal from './_components/DeleteEnquiryModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { copyToClipboard, dateFormat, generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditEnquiryModal from './_components/EditEnquiryModal';
import HistoryIcon from '@mui/icons-material/History';
import EnquiryLog from './_components/EnquiryLog';
import { usePermission } from '../../_providers/PermissionProvider';

interface IProps {
}

interface ISAPState {
    isEdit: boolean;
    value: string;
}

interface IChangeableState {
    isEdit: boolean;
    value: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: "100%",
        },
        actionContainer: {
            display: 'flex'
        },
        addButton: {
            marginBottom: '20px'
        },
        importButton: {
            float: 'right'
        },
        errorContainer: {
            maxHeight: '200px',
            overflowY: 'auto'
        },
        sapContainer: {
            display: 'flex',
            alignItems: 'center'
        },
    })
)

const EnquiryList = (props: IProps) => {
    const permissions = usePermission()
    const API_URL = `${process.env.REACT_APP_API_URL}/enquiry`;
    const classes = useStyles();

    const enquiryStatuses = [
        {
            key: 'open',
            value: 'Open'
        },
        {
            key: 'accepted',
            value: 'Accepted',
        },
        {
            key: 'opportunity',
            value: 'Opportunity',
        },
        {
            key: 'converted',
            value: 'Converted'
        },
        {
            key: 'duplicate',
            value: 'Duplicate'
        },
        {
            key: 'drop',
            value: 'Dropped'
        },
        {
            key: 'fresh',
            value: 'Fresh'
        },
        {
            key: 'hot_prospect',
            value: 'Hot Prospect'
        },
        {
            key: 'visit',
            value: 'Visit'
        },
        {
            key: 'closing',
            value: 'Closing'
        },
        {
            key: 'won',
            value: 'Won'
        },

        {
            key: 'ask',
            value: 'Tanya'
        },
        {
            key: 'prospect',
            value: 'Prospect'
        },
        {
            key: 'junk',
            value: 'Junk Leads'
        },
    ];

    const enquiryTypes = [
        {
            key: 'whatsapp-direct',
            value: 'Whatsapp (Antrian)'
        },
        {
            key: 'whatsapp-direct-not-assigned',
            value: 'Whatsapp Direct Not Assigned'
        },
        {
            key: 'direct-enquiry',
            value: 'Whatsapp Direct ke UPV Agent'
        },
        {
            key: 'direct-seller-page',
            value: 'Whatsapp Direct dari Seller Page'
        },
        {
            key: 'direct-blog-page',
            value: 'Whatsapp Direct dari Article Page'
        },
        {
            key: 'unassigned-no-salescandy',
            value: 'Not Assigned'
        },
        {
            key: 'sap-direct',
            value: 'SAP Direct Agent'
        },
        {
            key: 'sap-not-sent',
            value: 'SAP Not Sent'
        },
        {
            key: 'salescandy',
            value: 'SalesCandy'
        },
        {
            key: 'sap',
            value: 'SAP'
        },
        {
            key: 'web',
            value: 'Web (Unique Code)'
        },
        {
            key: 'brochure-direct-inhouse',
            value: 'Brochure Direct Inhouse'
        },
        {
            key: 'brochure-direct-jv',
            value: 'Brochure Direct JV'
        },
        {
            key: 'brochure-direct-external',
            value: 'Brochure Direct External'
        },
        {
            key: 'brochure-digital-sales',
            value: 'Brochure Digital Sales'
        },
        {
            key: 'brochure-inhouse',
            value: 'Brochure Inhouse'
        },
        {
            key: 'brochure-jv',
            value: 'Brochure JV'
        },
        {
            key: 'brochure-undefined',
            value: 'Brochure Undefined'
        },
        {
            key: 'brochure',
            value: 'Brochure'
        },
        {
            key: 'direct-submit-agent',
            value: 'Direct Submit Agent'
        },
        {
            key: 'contact-agent',
            value: 'Contact Agent'
        },
        {
            key: 'contact-agent-direct',
            value: 'Contact Agent (Direct)'
        },
    ];

    const agentTypes = [
        {
            key: 'inhouse',
            value: 'Inhouse'
        },
        {
            key: 'inhouse_jv',
            value: 'Inhouse JV'
        },
        {
            key: 'external',
            value: 'External'
        },
        {
            key: 'digital_sales',
            value: 'Digital Sales'
        },
    ];

    const [uniqueCode, setUniqueCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tempSAP, setTempSAP] = useState('');
    const [sapState, setSAP] = useState<ISAPState[]>([]);
    const [tempOpportunityId, setTempOpportunityId] = useState('');
    const [opportunityIdState, setOpportunityIdState] = useState<IChangeableState[]>([]);
    const [tempDropped, setTempDropped] = useState('');
    const [droppedState, setDroppedState] = useState<IChangeableState[]>([]);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [logId, setLogId] = useState('');
    const [modalLogOpen, setModalLogOpen] = useState(false);
    const [editEnquiry, setEditEnquiry] = useState({
        id: '',
        modal: false
    })
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState({
        reason: '',
        id: ''
    });

    const handleEdit = (id: string, value: string, index: number, type: 'sap' | 'dropped' | 'opportunity_id' | 'opportunity_reason') => {
        let text = '';
        let endpoint = '';
        let payload = {};
        let callback = () => { };

        const passed_value = value ? value : ""

        switch (type) {
            case 'sap':
                text = 'SAP Lead ID';
                endpoint = 'add-sap-lead';
                payload = { sap_lead_id: passed_value };
                callback = () => {
                    setSAP(prevState => {
                        const newState = prevState.slice();
                        newState[index].isEdit = !newState[index].isEdit;
                        return newState;
                    });
                }
                break;
            case 'dropped':
                text = 'Dropped Reason';
                endpoint = 'change-dropped-reason';
                payload = { dropped_reason: passed_value };
                callback = () => {
                    setDroppedState(prevState => {
                        const newState = prevState.slice();
                        newState[index].isEdit = !newState[index].isEdit;
                        return newState;
                    });
                }
                break;
            case 'opportunity_id':
                text = 'Opportunity ID';
                endpoint = 'change-opportunity-id';
                payload = { opportunity_id: passed_value };
                callback = () => {
                    setOpportunityIdState(prevState => {
                        const newState = prevState.slice();
                        newState[index].isEdit = !newState[index].isEdit;
                        return newState;
                    });
                }
                break;
        }

        renderWarningButton(`Apakah anda yakin ingin mengganti ${text}?`)
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/${id}/${endpoint}`, payload)
                        .then(res => {
                            Swal.fire({
                                title: `Berhasil mengubah ${text}`,
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: callback
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })

                }
            })
    }

    const handleEditEnquiry = (id: string) => {
        setEditEnquiry({
            id: id as string,
            modal: true
        })
    }

    useEffect(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/enquiry/unique-code`).then((res) => {
            setUniqueCode(res.data);
        });
    }, []);

    const handleModalLogOpen = () => setModalLogOpen(true);
    const handleModalLogClose = () => {
        setLogId('');
        setModalLogOpen(false);
    }

    const handleLog = (id: string) => {
        setLogId(id);
        handleModalLogOpen();
    }

    const columns: any[] = [
        {
            name: 'created_at',
            label: 'Tanggal Enquiry',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'is_sap_sent',
            label: 'Is SAP Sent',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes',
                },
                {
                    key: '0',
                    value: 'No',
                },
            ],
            render: (data: any) => {
                return !!data ? 'Yes' : 'No';
            }
        },
        {
            name: 'is_organic',
            label: 'Lead Nature',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'Indirect',
                    value: 'Indirect',
                },
                {
                    key: 'Organic',
                    value: 'Organic',
                },
                {
                    key: 'Unique URL',
                    value: 'Unique URL',
                },
            ],
        },
        {
            name: 'duplicate_id',
            label: 'Is Duplicate',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes',
                },
                {
                    key: '0',
                    value: 'No',
                },
            ],
            render: (data: any) => {
                return !!data ? 'Yes' : 'No';
            }
        },
        {
            name: 'sap_lead_id',
            label: 'SAP Lead ID',
            type: 'string',
            filterable: true,
            style: {
                minWidth: '160px'
            },
            render: (data: any, row: any, rows: any, index: number) => {
                return (
                    permissions['enquiry.admin-editor'] ?
                        <div className={classes.sapContainer}>
                            {!sapState[index].isEdit ?
                                <>
                                    {sapState[index].value}
                                </>
                                :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="sap_lead_id"
                                    style={{ minWidth: "100px" }}
                                    value={sapState[index].value}
                                    onChange={(e) => {
                                        const newSAPState = sapState.slice();
                                        newSAPState[index].value = e.target.value.toString();
                                        setSAP(newSAPState);
                                    }}
                                    disabled={!sapState[index].isEdit}
                                />
                            }

                            <Tooltip title={sapState[index].isEdit ? 'Save' : 'Edit'}>
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        if (!sapState[index].isEdit) {
                                            setTempSAP(sapState[index].value)

                                            setSAP(prevState => {
                                                const newState = prevState.slice();
                                                newState[index].isEdit = !newState[index].isEdit;
                                                return newState;
                                            });
                                        } else {
                                            handleEdit(row.id, sapState[index].value, index, 'sap')
                                        }

                                    }}
                                >
                                    {
                                        sapState[index].isEdit
                                            ? <DoneIcon fontSize="small" />
                                            : <EditIcon fontSize="small" />
                                    }
                                </IconButton>
                            </Tooltip>

                            {
                                sapState[index].isEdit &&
                                <Tooltip title="Cancel">
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => {
                                            setSAP(prevState => {
                                                const newState = prevState.slice();
                                                newState[index].value = tempSAP;
                                                newState[index].isEdit = !newState[index].isEdit;
                                                return newState;
                                            })
                                        }}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        :
                        !!data ? data : '-'
                )
            }
        },
        {
            name: 'opportunity_id',
            label: 'Opportunity ID',
            type: 'string',
            filterable: true,
            style: {
                minWidth: '150px'
            },
            render: (data: any, row: any, rows: any, index: number) => {
                return (
                    row.status === 'opportunity' ?
                        <div className={classes.sapContainer}>
                            {!opportunityIdState[index].isEdit ?
                                <>
                                    {opportunityIdState[index].value}
                                </>
                                :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    style={{ minWidth: "200px" }}
                                    name="opportunity_id"
                                    value={opportunityIdState[index].value}
                                    onChange={(e) => {
                                        const newOpportunityIdState = opportunityIdState.slice();
                                        newOpportunityIdState[index].value = e.target.value.toString();
                                        setOpportunityIdState(newOpportunityIdState);
                                    }}
                                    disabled={!opportunityIdState[index].isEdit}
                                />
                            }

                            <Tooltip title={opportunityIdState[index].isEdit ? 'Save' : 'Edit'}>
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        if (!opportunityIdState[index].isEdit) {
                                            setTempOpportunityId(opportunityIdState[index].value)

                                            setOpportunityIdState(prevState => {
                                                const newState = prevState.slice();
                                                newState[index].isEdit = !newState[index].isEdit;
                                                return newState;
                                            });
                                        } else {
                                            handleEdit(row.id, opportunityIdState[index].value, index, 'opportunity_id')
                                        }

                                    }}
                                >
                                    {
                                        opportunityIdState[index].isEdit
                                            ? <DoneIcon fontSize="small" />
                                            : <EditIcon fontSize="small" />
                                    }
                                </IconButton>
                            </Tooltip>

                            {
                                opportunityIdState[index].isEdit &&
                                <Tooltip title="Cancel">
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => {
                                            setOpportunityIdState(prevState => {
                                                const newState = prevState.slice();
                                                newState[index].value = tempOpportunityId;
                                                newState[index].isEdit = !newState[index].isEdit;
                                                return newState;
                                            })
                                        }}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        :
                        !!data ? data : '-'
                )
            }
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...enquiryTypes
            ],
            render: (data: any) => {
                return enquiryTypes.find(enquiryType => enquiryType.key === data)?.value;
            }
        },
        {
            name: 'agent_type',
            label: 'Agent Type',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...agentTypes
            ],
            render: (data: any) => {
                return agentTypes.find(agentType => agentType.key === data)?.value;
            }
        },
        ...(
            localStorage.getItem('role') !== 'agent-captain'
                ? [
                    {
                        name: 'agent_captain',
                        label: 'Captain',
                        type: 'string',
                        filterable: true
                    }
                ]
                : []
        ),
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'phone',
            label: 'No Hp',
            type: 'string',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'cluster_name',
            label: 'Nama Cluster',
            type: 'string',
            filterable: true
        },
        {
            name: 'listing_code',
            label: 'Kode Listing',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return !!data ?
                    (
                        <a href={`${process.env.REACT_APP_MAIN_URL}/properti/${data}`} target="_blank" rel="noopener noreferrer">
                            {data}
                        </a>
                    )
                    : '-';
            }
        },
        {
            name: 'agent_name',
            label: 'Agent',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return !!data ? data : '-';
            }
        },
        {
            name: 'agent_email',
            label: 'Agent Email',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return !!data ? data : '-';
            }
        },
        {
            name: 'agent_unique_code',
            label: 'Unique Code',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return !!data ? data : '-';
            }
        },
        {
            name: 'today_score',
            label: 'Today Score',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return !!data ? Math.round(data) : "-";
            }
        },
        {
            name: 'yesterday_score',
            label: 'Yesterday Score',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return !!data ? Math.round(data) : "-";
            }
        },
        {
            name: 'status',
            label: 'Status',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...enquiryStatuses
            ],
            render: (data: any, row: any[]) => renderStatus(row)
        },
        {
            name: 'dropped_reason',
            label: 'Dropped Reason',
            type: 'string',
            filterable: true,
            style: {
                minWidth: '150px'
            },
            render: (data: any, row: any, rows: any, index: number) => {
                return (
                    permissions['enquiry.admin-editor'] && row.status === 'drop' ?
                        <div className={classes.sapContainer}>
                            {!droppedState[index].isEdit ?
                                <>
                                    {droppedState[index].value}
                                </>
                                :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    style={{ minWidth: "200px" }}
                                    name="dropped_reason"
                                    value={droppedState[index].value}
                                    onChange={(e) => {
                                        const newDroppedState = droppedState.slice();
                                        newDroppedState[index].value = e.target.value.toString();
                                        setDroppedState(newDroppedState);
                                    }}
                                    disabled={!droppedState[index].isEdit}
                                />
                            }

                            <Tooltip title={droppedState[index].isEdit ? 'Save' : 'Edit'}>
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        if (!droppedState[index].isEdit) {
                                            setTempDropped(droppedState[index].value)

                                            setDroppedState(prevState => {
                                                const newState = prevState.slice();
                                                newState[index].isEdit = !newState[index].isEdit;
                                                return newState;
                                            });
                                        } else {
                                            handleEdit(row.id, droppedState[index].value, index, 'dropped')
                                        }

                                    }}
                                >
                                    {
                                        droppedState[index].isEdit
                                            ? <DoneIcon fontSize="small" />
                                            : <EditIcon fontSize="small" />
                                    }
                                </IconButton>
                            </Tooltip>

                            {
                                droppedState[index].isEdit &&
                                <Tooltip title="Cancel">
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => {
                                            setDroppedState(prevState => {
                                                const newState = prevState.slice();
                                                newState[index].value = tempDropped;
                                                newState[index].isEdit = !newState[index].isEdit;
                                                return newState;
                                            })
                                        }}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        :
                        !!data ? data : '-'
                )
            }
        },
        {
            name: 'blog_slug_label',
            label: 'Blog Slug Label',
            type: 'string',
            filterable: false,
            render: (data: any) => {
                return data ?
                    <a
                        href={`${process.env.REACT_APP_MAIN_URL}/article/${data}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {process.env.REACT_APP_MAIN_URL}/article/{data}
                    </a>
                    : '-'
            }
        },
        ...(
            permissions['enquiry.delete']
                ? [
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <div className={classes.actionContainer}>
                                    {
                                        permissions['enquiry.log'] &&
                                        <Tooltip title="Log">
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => handleLog(row.id)}
                                            >
                                                <HistoryIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        permissions['enquiry.admin-editor'] &&
                                        <Tooltip title="Edit">
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => handleEditEnquiry(row.id)}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>

                                    }
                                    <Tooltip title="Delete">
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        }
                    }
                ]
                : []
        ),
    ];

    const handleExport = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}/export${window.location.search}`, {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `enquiries.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            const fd = new FormData();
            fd.append('import_file', files[0]);

            setIsLoading(true);
            DefaultAxios
                .post(`${API_URL}/import`, fd)
                .then((res: { data: string[] }) => {
                    let title = 'Upload successful';
                    let message = '';
                    let icon: 'success' | 'warning' = 'success';

                    if (res.data.length) {
                        title = 'Upload with partiel error';
                        icon = 'warning';
                        message = '<ul class="' + classes.errorContainer + '">';
                        for (let m of res.data) {
                            message += `<li>${m}</li>`;
                        }
                        message += '</ul>';
                    }

                    Swal.fire({
                        title: title,
                        html: message,
                        icon: icon
                    });
                    setReDrawDatatable(new Date().getTime());
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = '';
                    })
                    setIsLoading(false);
                });
        }
    }

    const renderStatus = (row: any) => {
        if (!permissions['enquiry.change-status']) {
            return row.status_label;
        }

        return (
            <FormControl className={classes.formControl}>
                <Select
                    variant="outlined"
                    value={row.status}
                    onChange={(e) => handleEnquiryStatusChange(row.id, e.target.value as string)}
                    autoWidth
                >
                    {enquiryStatuses.map(data => {
                        return (
                            <MenuItem value={data.key} key={data.key}>{data.value}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    const handleEnquiryStatusChange = (id: string, status: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/change-status`, { enquiry_id: id, status })
                        .then(res => {
                            Swal.fire({
                                title: "Berhasil mengubah status",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime());
                                }
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })

                }
            })
    }

    const handleDelete = (id: string) => {
        setDeleteReason(prevState => ({
            ...prevState,
            id: id
        }));
        setShowDeleteModal(true);
    }

    const handleDeleteEnquiry = () => {
        if (!deleteReason.reason.trim()) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${API_URL}/delete`, deleteReason)
            .then(res => {
                Swal.fire({
                    title: "Enquiry Deleted",
                    icon: 'success',
                    onAfterClose: () => {
                        setDeleteReason({
                            reason: '',
                            id: ''
                        });
                        setShowDeleteModal(false);
                        setReDrawDatatable(new Date().getTime());
                    },
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <EnquiryLog
                open={modalLogOpen}
                onClose={handleModalLogClose}
                id={logId}
            />

            {uniqueCode &&
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "10px" }}>
                    <Typography variant="subtitle2">
                        Your Personal Unique URL:
                        <span style={{ marginLeft: "5px", color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                            copyToClipboard(`${process.env.REACT_APP_MAIN_URL}?rc=${uniqueCode}`)
                            alert('Kode telah di-copy');
                        }}>
                            {process.env.REACT_APP_MAIN_URL}?rc={uniqueCode}
                        </span>
                    </Typography>
                    <ShareDropdown
                        buttonType={2}
                        title="Sinarmas Referal Code"
                        description="Sinarmas Referal Code"
                        uri={`${process.env.REACT_APP_MAIN_URL}?rc=${uniqueCode}`}
                    />
                </div>
            }

            <Grid container direction="row-reverse" className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: "5px" }}
                    onClick={() => window.open('http://respro.sinarmasland.com/Login.aspx')}
                >
                    Respro
                </Button>

                {permissions['enquiry.import'] &&
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        startIcon={<GetAppIcon />}
                    >
                        Import
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".xls,.xlsx"
                            onChange={handleImportFileChanged}
                        />
                    </Button>
                }

                {permissions['enquiry.export'] &&
                    <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        size="small"
                        onClick={handleExport}
                        startIcon={<PublishIcon />}
                    >
                        Export
                    </Button>
                }
            </Grid>

            <DeleteEnquiryModal
                open={showDeleteModal}
                onClose={() => { setShowDeleteModal(false) }}
                deleteReason={deleteReason.reason}
                onHandleDeleteReasonChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const target = e.target;
                    if (target) {
                        setDeleteReason(prevState => ({
                            ...prevState,
                            reason: target.value
                        }))
                    }
                }}
                onHandleDeleteEnquiry={handleDeleteEnquiry}
            />

            <EditEnquiryModal
                open={editEnquiry.modal}
                id={editEnquiry.id}
                onClose={(refresh: boolean) => {
                    if (refresh) {
                        setReDrawDatatable(new Date().getTime());
                    }
                    setEditEnquiry({ id: '', modal: false })
                }
                }
            />

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
                responseCallback={(res: any) => {
                    const data: any[] = res.data;
                    const newSAPState: ISAPState[] = [];
                    const newOpportunityIdState: IChangeableState[] = [];
                    const newDroppedState: IChangeableState[] = [];

                    for (let key in data) {
                        newSAPState.push({
                            isEdit: false,
                            value: res.data[key].sap_lead_id
                        });
                    }

                    for (let key in data) {
                        newOpportunityIdState.push({
                            isEdit: false,
                            value: res.data[key].opportunity_id
                        });
                    }

                    for (let key in data) {
                        newDroppedState.push({
                            isEdit: false,
                            value: res.data[key].dropped_reason
                        });
                    }

                    setSAP(newSAPState);
                    setOpportunityIdState(newOpportunityIdState);
                    setDroppedState(newDroppedState);
                }}
            />
        </>
    );
}

export default EnquiryList;