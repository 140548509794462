import React, { useRef } from 'react';
import { Grid, TextField, Theme, MenuItem } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

// Interfaces
import { ICategoryList, IState } from './Page';
import AutoComplete from '@mui/lab/Autocomplete';

// Assets
import defaultThumbnail from '../../../Assets/Images/png/default-thumbnail-unit.png';

interface Props {
    form: IState;
    onChange: Function;
    onSelect: Function
    index: number;
    wishForHome?: boolean;
    placeholder?: string;
    categoryLists: ICategoryList[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumbnail: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '50%'
            }
        }
    })
);

const ImageForm = (props: Props) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null)

    const openUpload = () => {
        inputRef.current?.click()
    }

    const captionsOptions = [
        'Master Bedroom',
        '2nd Bedroom',
        '3rd Bedroom',
        'Living Room',
        'Kitchen',
        'Balcony',
        'Swimming Pool',
        'Jogging Track',
        'Masterplan Project',
        'Denah Ruko',
        'Siteplan Cluster',
        'Dining Room',
        'Master Bathroom',
        "2nd Bathroom",
        "3rd Bathroom",
        "Backyard",
        "Frontyard",
        "Balcony",
        "Living Room 1st Floor",
        "Living Room 2nd Floor"
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
                {
                    props.form.thumbnail ?
                        <img onClick={openUpload} src={props.form.thumbnail} width="100" alt={props.form.alt} style={{ width: '100%', minHeight: 136, objectFit: 'cover', cursor: 'pointer' }} />
                        :
                        <img onClick={openUpload} src={defaultThumbnail} alt={props.form.alt} style={{ cursor: 'pointer', backgroundColor: '#fafafa' }} className={classes.thumbnail} />
                }
                <input ref={inputRef} type="file" name="file" style={{ width: 0, height: 0 }} onChange={(e) => props.onChange(e, props.index)} />
            </Grid>
            <Grid item xs={12} md={10}>
                <Grid container spacing={3}>
                    {
                        props.wishForHome ?
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="url"
                                    variant="outlined"
                                    value={props.form.url}
                                    onChange={(e) => props.onChange(e, props.index)}
                                    label="URL"
                                />
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    {/* <TextField
                                        fullWidth
                                        name="caption"
                                        variant="outlined"
                                        value={props.form.caption}
                                        onChange={(e) => props.onChange(e, props.index)}
                                        placeholder="Max 50 karakter"
                                        label="Caption"
                                    /> */}
                                    <AutoComplete
                                        freeSolo
                                        options={captionsOptions}
                                        onChange={(e: React.ChangeEvent<{}>, newValue: string | null) => props.onSelect(props.index, 'caption', newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                name="caption"
                                                variant="outlined"
                                                // value={props.form.caption}
                                                onChange={(e) => props.onChange(e, props.index)}
                                                placeholder="Max 50 karakter"
                                                label="Caption"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        name="category"
                                        value={props.form.category}
                                        onChange={(e) => props.onChange(e, props.index)}
                                        label="Category"
                                    >
                                        {props.categoryLists.map(categoryList => <MenuItem key={categoryList.key} value={categoryList.key}>{categoryList.value}</MenuItem>)}
                                        <MenuItem value="other">Other</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="alt"
                                        variant="outlined"
                                        value={props.form.alt}
                                        onChange={(e) => props.onChange(e, props.index)}
                                        label="Alt"
                                    />
                                </Grid>
                            </>
                    }
                    <Grid item xs={12}>
                        {props.placeholder ?
                            <i>{props.placeholder}</i>
                            :
                            <i>Recommended dimension : 1500 x 1000 or larger with the same ratio</i>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ImageForm;
