import React, { useState, Fragment, useReducer, useEffect } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingScreen from '../../_components/LoadingScreen';
import Swal from 'sweetalert2';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Components
import DataTable from '../../_components/_dataTable/DataTable';
import AgentOfficeForm from './AgentOfficeForm';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}
export interface IState {
    id: string;
    name: string;
    address: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
    address: string
}

const useStyles = makeStyles({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
});

const AgentOfficeList = (props: Props) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/agent-office';
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const columns = [
        {
            name: 'name',
            label: 'Office Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'address',
            label: 'Office Entity',
            type: 'string',
            filterable: true
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        },
    ];

    const initialState = {
        id: '',
        name: '',
        address: '',
    };

    const initialErrorState = {
        name: '',
        address: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.address) {
            newError.address = 'Alamat wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDatatable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <AgentOfficeForm
                open={modalOpen}
                onClose={handleModalClose}
                state={inputState}
                errorState={errorState}
                onChange={handleChange}
                onSubmit={handleSubmit}
            />
            {
                permissions['agent-office.manage'] &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={handleModalOpen}
                >
                    Add New Agency
                </Button>
            }
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Fragment>
    );
}

export default AgentOfficeList;