import { Dialog, DialogContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IState } from '../AccountTracker'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import LoadingScreen from '../../../_components/LoadingScreen'

type Props = {
    open: boolean
    onClose: () => void
    data: IState
}

interface ITrackingData {
    type: string
    page: string
    counter: number

}

const AccountTrackerDetail = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [trackingData, setTrackingData] = useState<ITrackingData[]>([])

    const loadData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/account-tracking-visit/${props.data.id}`)
            .then(res => res.data)
            .then(res => {
                setTrackingData(res)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (props.open && props.data) {
            loadData()
        }

        // eslint-disable-next-line
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>
                <LoadingScreen open={isLoading} />
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tipe Halaman</TableCell>
                                        <TableCell>Page</TableCell>
                                        <TableCell>Counter</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        trackingData.length ?
                                            trackingData.map((data) => (
                                                <TableRow>
                                                    <TableCell>{data.type}</TableCell>
                                                    <TableCell>{data.page}</TableCell>
                                                    <TableCell>{data.counter}</TableCell>
                                                </TableRow>
                                            )) :
                                            <TableRow>
                                                <TableCell>Belum ada data tracking yang tersedia</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AccountTrackerDetail