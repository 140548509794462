import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import HomepageBanner from './_components/HomepageBanner';
import AdminNotifyEmails from './_components/AdminNotifyEmails';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

export interface ISimpleImageState {
    id: string;
    name: string;
    file: File | null;
    url: string;
}

export interface IAdminNotifyEmailsState {
    id: string;
    value: string;
}

export interface IAction {
    name: string,
    value: any,
    type: string
}

export const useStyles = makeStyles({
    borderBottomLine: {
        borderBottom: '1px solid #eee',
        textTransform: 'capitalize'
    },
    divider: {
        margin: '50px 0'
    }
})

export const API_URL = `${process.env.REACT_APP_API_URL}/site-setting`;

const SiteSetting = (props: Props) => {
    const permissions = usePermission()

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<any>({});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        DefaultAxios
            .get(API_URL)
            .then(res => {
                setState(res.data);
            });
    }

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />

            <Grid item xs={12}>
                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Homepage Background Banner</h1>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['homepage-background-banner-desktop'] || null}
                            loadData={loadData}
                            slug="homepage-background-banner-desktop"
                            info="Recommended dimension : 1278 x 510"
                            hideName
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['homepage-background-banner-mobile'] || null}
                            loadData={loadData}
                            slug="homepage-background-banner-mobile"
                            info="Recommended dimension : 375 x 638"
                            hideName
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "50px", marginBottom: "50px" }} />

                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Hero Image</h1>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['hero-image'] || null}
                            loadData={loadData}
                            slug="hero-image"
                            info="Recommended dimension : 1400 x 520"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['hero-image-mobile'] || null}
                            loadData={loadData}
                            slug="hero-image-mobile"
                            info="Recommended dimension : 420 x 580"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "50px", marginBottom: "50px" }} />

                {/* <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Homepage Banner</h1>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['homepage-banner'] || null}
                            loadData={loadData}
                            slug="homepage-banner"
                            label="URL"
                            info="Recommended dimension : 1130 x 300"
                            doublePreviewSize
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['homepage-banner-mobile'] || null}
                            loadData={loadData}
                            slug="homepage-banner-mobile"
                            label="URL"
                            info="Recommended dimension : 420 x 300"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "50px" , marginBottom: "50px"}} /> */}

                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Advertisement Slot</h1>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['advertisement-slot'] || null}
                            loadData={loadData}
                            slug="advertisement-slot"
                            label="URL"
                            info="Recommended dimension : 1130 x 300"
                            doublePreviewSize
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['advertisement-slot-mobile'] || null}
                            loadData={loadData}
                            slug="advertisement-slot-mobile"
                            label="URL"
                            info="Recommended dimension : 420 x 300"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "50px", marginBottom: "50px" }} />

                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Kavling Placeholder</h1>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <HomepageBanner
                            setIsLoading={setIsLoading}
                            state={state['kavling-placeholder'] || null}
                            loadData={loadData}
                            slug="kavling-placeholder"
                        />
                    </Grid>
                </Grid>

                {
                    permissions['admin-notify-emails.manage'] &&
                    <>
                        <Grid item xs={12}>
                            <h1 className={classes.borderBottomLine}>Admin Notify Emails</h1>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AdminNotifyEmails
                                    setIsLoading={setIsLoading}
                                    state={state['admin-notify-emails'] || null}
                                    loadData={loadData}
                                />
                            </Grid>
                        </Grid>
                    </>
                }

                {
                    permissions['admin-notify-emails.manage'] &&
                    <>
                        <Grid item xs={12}>
                            <h1 className={classes.borderBottomLine}>Admin 360 Notify Emails</h1>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AdminNotifyEmails
                                    is360={true}
                                    setIsLoading={setIsLoading}
                                    state={state['admin-360-notify-emails'] || null}
                                    loadData={loadData}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
        </Grid>
    );
}

export default SiteSetting;