import React, { useState, useReducer } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

interface IState {
    agent_id: string;
    agent_name: string;
    cluster_type: 'commercial' | 'residential';
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const AssignAgentBsd = () => {
    const initialState: IState = {
        agent_id: '',
        agent_name: '',
        cluster_type: 'commercial'
    };

    const [isLoading, setIsLoading] = useState(false);

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;

        setInputState({ name: target.name, value: target.value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: '', type: 'SET_ITEM' });
    }

    const handleSubmit = (type: 'assign' | 'unassign') => {
        if (!inputState.agent_id) {
            return;
        }

        renderWarningButton('')
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(`${process.env.REACT_APP_API_URL}/assign-agent-bsd`, {
                        ...inputState,
                        type
                    })
                    .then(res => {
                        Swal.fire({
                            title: "Berhasil memproses Request",
                            icon: 'success',
                            timer: 1000,
                            onAfterClose: () => {
                                window.location.reload();
                            }
                        })
                    })
                    .catch(err => {
                        generalErrorHandler(err)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <AsyncAutoComplete 
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                                label="Agent"
                                name="agent_id"
                                initialQuery={inputState.agent_name}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                iconSearch
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                select
                                fullWidth
                                variant="outlined"
                                label="BSD Cluster"
                                name="cluster_type"
                                value={inputState.cluster_type}
                                onChange={handleChange}
                            >
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="residential">Residential</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: '20px' }}
                                onClick={() => handleSubmit('assign')}
                            >
                                Assign All
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => handleSubmit('unassign')}
                            >
                                Unassign All
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AssignAgentBsd;