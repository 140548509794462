import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Button, IconButton, Switch, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import AgentExternalForm from './AgentExternalForm';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import { generalErrorHandler, isValidEmail, renderWarningButton } from '../../_utils/Helper';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

export interface IState {
    id: string;
    name: string;
    email: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
    email: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
}

const useStyles = makeStyles({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        float: 'right'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
});

const AgentExternalList = (props: Props) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/agent-external';
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'In-Active'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchActive(row.id)}
                    color="secondary"
                />
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {
                            permissions['agent.reset-password'] &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => handleResetPassword(row.id)}
                            >
                                Reset Password
                            </Button>
                        }
                    </div>
                );
            }
        },
    ];

    const initialState = {
        id: '',
        name: '',
        email: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: ''
    };

    const initialErrorState = {
        name: '',
        email: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleResetPassword = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin me-reset password user ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/password/reset`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mereset password",
                                icon: 'success',
                                timer: 1000
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchActive = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-active`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime())
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        if (['bukan_kata_sandi', 'bukan_kata_sandi_confirmation'].includes(name)) {
            value = value.replace(/\s*/g, '');
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.email) {
            newError.email = 'Email wajib diisi';
            isValid = false;
        } else if (!isValidEmail(inputState.email)) {
            newError.email = 'Format email tidak benar';
            isValid = false;
        }

        if (!inputState.id) {
            if (!inputState.bukan_kata_sandi) {
                newError.bukan_kata_sandi = 'Password wajib diisi';
                isValid = false;
            }
        }

        if (inputState.bukan_kata_sandi && inputState.bukan_kata_sandi.length < 8) {
            newError.bukan_kata_sandi = 'Password minimal 8 karakter';
            isValid = false;
        }

        if (inputState.bukan_kata_sandi !== inputState.bukan_kata_sandi_confirmation) {
            newError.bukan_kata_sandi_confirmation = 'Confirm Password harus sama dengan Password';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDatatable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            const fd = new FormData();
            fd.append('import_file', files[0]);

            setIsLoading(true);
            DefaultAxios
                .post(`${API_URL}/import`, fd)
                .then((res: { data: string[] }) => {
                    let title = 'Upload successful';
                    let message = '';
                    let icon: 'success' | 'warning' = 'success';

                    if (res.data.length) {
                        title = 'Upload with partiel error';
                        icon = 'warning';
                        message = '<ul class="' + classes.errorContainer + '">';
                        for (let m of res.data) {
                            message += `<li>${m}</li>`;
                        }
                        message += '</ul>';
                    }

                    Swal.fire({
                        title: title,
                        html: message,
                        icon: icon
                    });
                    setReDrawDatatable(new Date().getTime());
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = '';
                    })
                    setIsLoading(false);
                });
        }
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <AgentExternalForm
                open={modalOpen}
                onClose={handleModalClose}
                state={inputState}
                errorState={errorState}
                onChange={handleChange}
                onSubmit={handleSubmit}
            />
            {
                permissions['agent-external.manage'] &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={handleModalOpen}
                >
                    Add New User
                </Button>
            }
            <Button
                variant="contained"
                component="label"
                color="primary"
                size="small"
                className={classes.importButton}
                startIcon={<PublishIcon />}
            >
                Import
                <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".xls,.xlsx"
                    onChange={handleImportFileChanged}
                />
            </Button>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Fragment>
    );
}

export default AgentExternalList;