import React from 'react';
import { Dialog } from '@mui/material';

interface Props {
    image: string | undefined;
    onClose: () => void;
}

const ImagePreview = (props: Props) => {
    return (
        <Dialog
            open={!!props.image}
            onClose={props.onClose}
            scroll="body"
            maxWidth={false}
        >
            <img src={props.image} width="100%" alt="preview" />
        </Dialog>
    );
}

export default ImagePreview;