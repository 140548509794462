import React from 'react';
import { TextField } from '@mui/material';

interface Props {
    label?: string;
    name: string;
    value: any;
    onChange: (event: any) => void;
    maxRows?: number;
    placeholder?: string;
    disabled?: boolean;
    multiline?: boolean;
    rows?: number;
    error?: boolean
    helperText?: string
}

const TextArea = (props: Props) => {
    return (
        <TextField
            fullWidth
            multiline
            variant="outlined"
            { ...props }
        />
    );
}

TextArea.defaultProps = {
    maxRows: 3
}

export default TextArea;