import React, { Fragment, useState, useReducer, useRef, RefObject, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

// Material UI
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Card, CardContent, Grid, TextField, Button } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isValidLatLng } from '../../_utils/Helper';

interface Props {

}


interface IState {
    name: string;
    province_id: string;
    province_name: string;
    latitude: string;
    longitude: string;
}

interface IErrorState {
    name: string;
    latitude: string;
    longitude: string;
    province_id: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IRefs {
    [key: string]: RefObject<HTMLInputElement>
}

interface IListPlace {
    id: number;
    label: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '800px',
            marginBottom: '70px',
            boxShadow: '0px 3px 15px #0000001A',
            '& .Mui-disabled': {
                backgroundColor: '#eee'
            }
        },
        rootWider: {
            maxWidth: '100%',
            marginBottom: '70px',
            boxShadow: '0px 3px 15px #0000001A',
            '& .Mui-disabled': {
                backgroundColor: '#eee'
            }
        },
        borderBottomLine: {
            borderBottom: '1px solid #eee',
            marginBottom: 0
        },
    }),
);

const CityForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/city';
    const params: any = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [provinceList, setProvinceList] = useState<Array<IListPlace>>([]);
    const location = useLocation();

    const initialState = {
        name: '',
        province_id: '',
        province_name: '',
        latitude: '',
        longitude: '',
    }

    const refs: IRefs = {
        name: useRef<HTMLInputElement>(null),
        address: useRef<HTMLInputElement>(null),
        latitude: useRef<HTMLInputElement>(null),
        longitude: useRef<HTMLInputElement>(null),
        promotion_banner_caption: useRef<HTMLInputElement>(null),
    }

    const initialErrorState = {
        name: '',
        address: '',
        latitude: '',
        longitude: '',
        province_id: ''
    };

    // Input Reducer
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const resData = res.data;
                const newState: any = {};

                for (let [key, value] of Object.entries(resData)) {
                    newState[key] = value;
                }

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;


        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleProvinceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: 'province_id', value: e.target.value, type: 'SET_ITEM' });
    }

    const loadFormData = (state?: IState) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project/form-data`)
            .then(res => {
                setProvinceList(res.data.provinces);
            });
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            if (!firstError) firstError = 'name';
            newError.name = 'Name wajib diisi';
            isValid = false;
        }

        if (!inputState.province_id) {
            if (!firstError) firstError = 'province_id';
            newError.province_id = 'Province wajib diisi';
            isValid = false;
        }

        if (!inputState.latitude) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Latitude wajib diisi';
            isValid = false;
        } else if (!isValidLatLng(inputState.latitude)) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Format latitude tidak valid';
            isValid = false;
        }

        if (!inputState.longitude) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Longitude wajib diisi';
            isValid = false;
        } else if (!isValidLatLng(inputState.longitude)) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Format longitude tidak valid';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (params.id) {
            axios = DefaultAxios.patch(`${API_URL}/${params.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        history.push('/city');
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    useEffect(() => {

        loadFormData()

        document.body.style.backgroundColor = '#eee';
        let timeout: any;

        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.borderBottomLine}>City {location.pathname === '/city/add' ? 'Add' : 'EDIT'}</h1>
                    </Grid>
                    <Fragment>
                        <LoadingScreen open={isLoading} fullScreen />
                        <Grid item xs={12}>
                            <TextField
                                label="Nama"
                                name="name"
                                value={inputState.name}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                                error={!!errorState.name}
                                helperText={errorState.name}
                                ref={refs.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Provinsi"
                                name="province_id"
                                value={inputState.province_id}
                                onChange={handleProvinceChange}
                                error={!!errorState.province_id}
                                helperText={errorState.province_id}
                                variant="outlined"
                                select
                                fullWidth
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                ref={refs.province_id}
                            >
                                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                {provinceList.map(data => {
                                    return (
                                        <option value={data.id} key={data.id}>{data.label}</option>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Latitude"
                                name="latitude"
                                value={inputState.latitude}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                                error={!!errorState.latitude}
                                helperText={errorState.latitude}
                                ref={refs.latitude}
                                placeholder="Ex: -6.198049"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Longitude"
                                name="longitude"
                                value={inputState.longitude}
                                variant="outlined"
                                fullWidth
                                onChange={handleChanged}
                                error={!!errorState.longitude}
                                helperText={errorState.longitude}
                                ref={refs.longitude}
                                placeholder="Ex: 106.7626502"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Fragment>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default CityForm;