import React, { useState } from 'react'

/**
 * Components
 */
import { Button, Grid, MenuItem, TextField, Theme } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor';

/**
 * Utils
 */
import { createStyles, makeStyles } from '@mui/styles';
import { IValidationErrors } from '../../_utils/Validation';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper';


type PropertyType = 'Residential' | 'Ruko' | 'Kavling' | 'Apartment' | 'Kiosk' | 'Business Loft' | 'Warehouse' | 'Boutique SOHO'

interface State {
    propertyType: '' | PropertyType
    articleContent: string
    metaTitle: string
    metaDescription: string
}

const LpArticle = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>({
        propertyType: '',
        articleContent: '',
        metaTitle: '',
        metaDescription: '',
    })

    const [error, setError] = useState<IValidationErrors>({})

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))

        if (name === 'propertyType') {
            if (value !== '') {
                fetchArticle(value as PropertyType)
            } else {
                setState(prev => ({
                    ...prev,
                    articleContent: '',
                    metaTitle: '',
                    metaDescription: '',
                }))
            }
        }
    }

    const handleEditorChanged = (name: string, value: string) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const fetchArticle = (propertyType: PropertyType) => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/article-landing-page`, {
            params: {
                data_id: propertyType,
            }
        })
            .then(res => res.data)
            .then(data => {
                setState(prev => ({
                    ...prev,
                    articleContent: data.article,
                    metaTitle: data.meta_title || '',
                    metaDescription: data.meta_description || '',
                }))
            })
            .catch(e => {
                generalErrorHandler(e, {
                    404: () => {
                        setState(prev => ({
                            ...prev,
                            articleContent: '',
                            metaTitle: '',
                            metaDescription: '',
                        }))
                    }
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onSubmit = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/article-landing-page`, {
            data_id: state.propertyType,
            article: state.articleContent,
            meta_title: state.metaTitle || null,
            meta_description: state.metaDescription || null,
        })
            .then(res => res.data)
            .then(data => {
                console.log(data)
                renderToastSuccess(`Berhasil mengubah article untuk ${state.propertyType}`)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
                <h1 className={classes.borderBottomLine}>LP Article</h1>
            </Grid>

            <LoadingScreen open={isLoading} fullScreen />

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Property Type"
                    name="propertyType"
                    value={state.propertyType}
                    onChange={onChange}
                    error={!!error.propertyType}
                    helperText={error.propertyType}
                >
                    {PROPERTY_TYPES.map(pt => <MenuItem value={pt} key={pt}>{pt}</MenuItem>)}
                </TextField>
            </Grid>
            {
                state.propertyType !== '' &&
                <>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Meta Title"
                            name="metaTitle"
                            value={state.metaTitle}
                            onChange={onChange}
                            error={!!error.metaTitle}
                            helperText={error.metaTitle}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Meta Description"
                            name="metaDescription"
                            value={state.metaDescription}
                            onChange={onChange}
                            error={!!error.metaDescription}
                            helperText={error.metaDescription}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Content</h3>
                        <TextEditor
                            name="articleContent"
                            value={state.articleContent}
                            onChange={handleEditorChanged}
                            imagesUpload
                        />
                        {error.articleContent && <span style={{ fontSize: 12, color: '#f44336', marginTop: 4, marginLeft: 8 }}>{error.articleContent}</span>}
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </>
            }
        </Grid>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        borderBottomLine: {
            borderBottom: '1px solid #eee'
        },
        fab: {
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            display: 'none',
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            }
        },
        fabSpacing: {
            marginRight: '1rem',
        },
        container: {
            paddingBottom: '50px'
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'column',
            '& > .label': {
                fontSize: 16,
                color: '#484848',
                marginBottom: 12,
            },
            '& > .error': {
                color: theme.palette.error.main,
                marginTop: 12,
            }
        },
        promotionContainer: {
            position: 'relative',
            '& > .DateRangePicker': {
                width: '100%',
            },
        },
        promotion: {
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            top: 16,
            left: 36,
            background: 'white',
            zIndex: 1,
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        },
    })
);

const PROPERTY_TYPES = ['Residential', 'Ruko', 'Kavling', 'Apartment', 'Kiosk', 'Business Loft', 'Warehouse', 'Boutique SOHO']

export default LpArticle
