import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import { IAdminNotifyEmailsState } from '../SiteSetting';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';

interface Props {
    is360?: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    state: IAdminNotifyEmailsState;
    loadData: () => void;
}

const AdminNotifyEmails = ({ is360, setIsLoading, state, loadData }: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/site-setting`;

    const [inputState, setInputState] = useState('');

    useEffect(() => {
        if (state) {
            setInputState(state.value);
        }
    }, [state]);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState(e.target.value);
    }

    const handleSubmit = () => {
        let url = `${API_URL}/${!is360 ? 'admin-notify-emails' : 'admin-360-notify-emails'}`;

        setIsLoading(true);
        DefaultAxios
            .post(url, { value: inputState })
            .then(res => {
                Swal.fire({
                    title: "Berhasil menyimpan",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadData();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField 
                    fullWidth
                    variant="outlined"
                    label="Admin Notify Emails"
                    value={inputState}
                    onChange={handleChanged}
                />
            </Grid>

            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Upload
                </Button>
            </Grid>
        </Grid>
    )
}

export default AdminNotifyEmails;