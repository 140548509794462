import React, { useState } from 'react'
/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen'
import { Button, Grid } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { downloadLink, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'

type Props = {}

const PilarEnquiryExport = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const url = `${process.env.REACT_APP_API_URL}/pilar-enquiries/export`

    const handleExport = () => {
        setIsLoading(true)
        DefaultAxios.get(`${url}`, {
            responseType: 'blob'
        })
            .then(res => res.data)
            .then(res => {
                downloadLink(window.URL.createObjectURL(new Blob([res])), `pilar-enquiry-export.xlsx`)
                renderToastSuccess('Export Berhasil!')
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button variant='contained' sx={{ float: 'left' }} onClick={handleExport}>
                        Export Pilar Enquiry
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default PilarEnquiryExport