// import DefaultAxios from "./DefaultAxios";
import moment from "moment";
import Swal from "sweetalert2";
import * as loadImage from "blueimp-load-image";

export const renderWarningButton = (label: string, title = 'Apakah Anda Yakin?') => {
    return Swal.fire({
        title: title,
        text: label,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
    })
}

export const renderInfoButton = (label: string, body?: string, onClose?: () => void) =>
    Swal.fire({
        title: label,
        html: body,
        icon: 'info',
        onAfterClose: onClose,
    })

export function converNumberFormat(number_to_convert: string) {
    let converted_number = number_to_convert.toString();
    converted_number = converted_number.replace('.00', '');
    let number = converted_number.replace(/[^\d]/g, '');
    let num = number.replace(/,/gi, "");
    let num2 = num.split(/(?=(?:\d{3})+$)/).join(",");
    return num2;
}

export function isMobile() {
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
        return true;
    } else {
        return false;
    }
}

export function convertNumber(number: string, prefix?: string, with_decimal?: boolean) {
    let numberString;
    let decimal;

    //With Decimal
    if (with_decimal) {
        number = Number(number).toFixed(2);

        const exploded_number = number.split(".");
        numberString = parseInt(exploded_number[0].replace(/[^,\d]/g, '')).toString();
        decimal = typeof exploded_number[1] !== 'undefined' ? exploded_number[1] : "";
    } else {
        numberString = parseInt(number.replace(/[^,\d]/g, '')).toString();
    }

    const split = numberString.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah = rupiah + separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    let output = prefix ? prefix : ''

    if (with_decimal) {
        return rupiah ? `${output}${rupiah},${decimal}` : '';
    } else {
        return rupiah ? output + rupiah : ''
    }
}

export function convertPercentangeWithDecimal(number: string, max_number?: number) {
    if (typeof max_number === 'undefined') {
        max_number = 100;
    }

    if (number === "") {
        return "";
    }

    if ((number.match(/[.]/g) || []).length === 1) {
        if (number[number.length - 1] === '.') {
            const max_data = number.replace('.', '');

            if (parseInt(max_data) >= max_number) {
                return max_number;
            }
            return number;
        } else {
            const number_splitted = number.split('.');
            console.log(number_splitted[1].length);

            if (number_splitted[1].length === 1) {
                return parseFloat(number).toFixed(1);
            } else if (number_splitted[1].length === 2) {
                return parseFloat(number).toFixed(2);
            } else {
                return false;
            }
        }
    } else if ((number.match(/[.]/g) || []).length > 1) {
        return false;
    }

    if (isNaN(parseFloat(number))) {
        return false;
    } else {
        let new_value = parseFloat(number);
        new_value = new_value > max_number ? max_number : new_value;
        return new_value;
    }
}

export function inputNumber(number: string, min?: number) {
    const numberString = number.replace(/[^,\d]/g, '').toString()
    if (min !== undefined && min !== null && numberString) {
        if (Number(numberString) < min) {
            return min.toString()
        }
    }
    return numberString ? numberString : ''
}

export function inputPhoneNumber(number: string) {
    const phoneNumber = number.replace(/[^\d]/g, '').toString();

    return phoneNumber ? phoneNumber : '';
}

export function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export const checkNotification = () => new Promise((resolve, reject) => {
    // let url = process.env.REACT_APP_API_URL + '/notification'
    // DefaultAxios.get(url)
    //     .then(res => res.data)
    //     .then(res => {
    //         let isUnread = false
    //         if (!isUnread) {
    //             isUnread = res.some((element: any) => element.read_at === null)
    //         }
    //         localStorage.setItem('isUnread', isUnread ? 'true' : 'false')
    //         resolve(isUnread)
    //     })
    //     .catch(err => {
    //         console.log(err)
    //         reject()
    //     })
})

export const getMonth = (monthNum: number) => {
    switch (monthNum) {
        case 0:
            return 'January'
        case 1:
            return 'February'
        case 2:
            return 'March'
        case 3:
            return 'April'
        case 4:
            return 'May'
        case 5:
            return 'June'
        case 6:
            return 'July'
        case 7:
            return 'August'
        case 8:
            return 'September'
        case 9:
            return 'October'
        case 10:
            return 'November'
        case 11:
            return 'December'
    }
}

export const copyToClipboard = (content: string) => {
    // Create a dummy input to copy the string array inside it
    let dummy = document.createElement("input") as HTMLInputElement

    // Add it to the document
    document.body.appendChild(dummy);

    // Output the array into it
    dummy.value = content;

    // Select it
    dummy.select();

    // Copy its contents
    document.execCommand("copy");

    // Remove it as its not needed anymore
    document.body.removeChild(dummy);
}

export const numberToCurrency = (number: number | string, prefix?: string): string => {
    const formatter = new Intl.NumberFormat();
    const newNumber: unknown = String(number)
        .replace(/[.]\d{2}$/, "")
        .replace(/[^0-9]/g, "");
    return `${prefix ? prefix : ""} ${formatter.format(
        newNumber as number
    )}`.trim();
}

export const currencyToNumber = (currency: string) => {
    return Number(currency.replace(/[^0-9]/g, ""));
}

export const dateFormat = (date: string | Date, format = "DD/MM/YYYY"): string => {
    if (date === "") {
        return "";
    }
    const d = moment(date).format(format);
    return d;
}

export const isValidEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
}

export const isValidDate = (d: any) => {
    return d instanceof Date && !isNaN(d.getTime());
}

export const isValidLatLng = (s: string) => {
    var re = /^(-?\d+(\.\d+)?)$/;
    return re.test(s.toLowerCase());
}

export const generalErrorHandler = (error: any, overwrite?: { [key: number]: () => void }) => {
    let errorData = error.response.data;

    if (!(errorData instanceof Blob)) {
        errorData = new Blob([JSON.stringify(errorData)], {
            type: "application/json",
        });
    }

    let reader = new FileReader();

    reader.onload = () => {
        errorData = JSON.parse(String(reader.result));

        if (typeof error.response.status === "undefined") {
            Swal.fire({
                title: "Script Error",
                html: "Error pada script. Harap hubungi Admin",
                icon: "error",
            });
        } else if (error.response.status === 422) {
            let error_text = `<p>${errorData.message}</p>`;

            if (typeof errorData.errors !== "undefined") {
                const error_lists = errorData.errors;
                for (var k in error_lists) {
                    error_text += `<p>${error_lists[k]}</p>`;
                }
            }

            Swal.fire({
                title: "Validasi Error",
                html: error_text,
                icon: "error",
            });
        } else if (error.response.status === 400) {
            Swal.fire({
                title: "Data Error",
                html: errorData.message,
                icon: "error",
            });
        } else if (error.response.status === 404) {
            if (overwrite && overwrite[404]) {
                overwrite[404]()
            } else {
                Swal.fire({
                    title: "Data Error",
                    html: errorData.message,
                    icon: "error",
                });
            }
        } else if (error.response.status === 500) {
            Swal.fire({
                title: "Server Error",
                html: "Error pada server. Harap hubungi Admin",
                icon: "error",
            });
        } else if (error.response.status === 412) {
            Swal.fire({
                title: "Data Error",
                html: errorData,
                icon: "error",
            });
        }
    };

    reader.readAsText(errorData);
}

export const dataURItoBlob = (dataURI: string) => {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

export const resizeAndResetOrientationImage = (file: File) => {
    return new Promise<any>((resolve, reject) => {
        loadImage(file, (img: any, data: any) => {
            if (img.width === 1500 && img.height === 1000) {
                resolve(false)
            } else {
                resolve(true)
            }
        }, { canvas: true })

    }).then((resize: boolean) => {
        return new Promise<any>((resolve, reject) => {
            if (resize) {
                loadImage(
                    file,
                    (img: any, data: any) => {
                        var blob = dataURItoBlob(img.toDataURL());
                        resolve(blob);
                    },
                    { orientation: true, canvas: true, maxWidth: 1500, maxHeight: 1000 }
                );
            } else {
                console.log('dont resize');
                resolve(file);
            }
        });
    })
}

export const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => resolve(reader.result)

        reader.onerror = error => reject(error)
    })
}

export const getOrientation = (file: any, callback: any) => {
    let reader = new FileReader();

    reader.onload = (event) => {
        var target = event.target;
        if (target) {
            var view = new DataView(target.result as any);

            if (view.getUint16(0, false) !== 0xffd8) return callback(-2);

            var length = view.byteLength,
                offset = 2;

            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;

                if (marker === 0xffe1) {
                    if (view.getUint32((offset += 2), false) !== 0x45786966) {
                        return callback(-1);
                    }
                    var little = view.getUint16((offset += 6), false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;

                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + i * 12, little) === 0x0112)
                            return callback(view.getUint16(offset + i * 12 + 8, little));
                } else if ((marker & 0xff00) !== 0xff00) break;
                else offset += view.getUint16(offset, false);
            }
            return callback(-1);
        }
    };

    reader.readAsArrayBuffer(file);
}

export const resetOrientation = (srcBase64: any, srcOrientation: number, callback: any) => {
    var img = new Image();
    img.crossOrigin = "anonymous";

    img.onload = () => {
        var width = img.width,
            height = img.height,
            canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");

        if (ctx) {
            // set proper canvas dimensions before transform & export
            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            // transform context before drawing image
            switch (srcOrientation) {
                case 2:
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break;
                case 3:
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break;
                case 4:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                case 5:
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                case 6:
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                case 7:
                    ctx.transform(0, -1, -1, 0, height, width);
                    break;
                case 8:
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                default:
                    break;
            }

            // draw image
            ctx.drawImage(img, 0, 0);

            // export blob
            canvas.toBlob((blob: any) => {
                callback(blob);
            });
        }
    };

    img.src = srcBase64;
}

export const urlToFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
        });
}

export const _resizeAndResetOrientationImage = (file: File) => {
    return new Promise<File>((resolve, reject) => {
        getOrientation(file, (orientation: any) => {
            resetOrientation(
                URL.createObjectURL(file),
                orientation,
                (newImg: any) => {
                    let img = document.createElement("img");
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.target) {
                            img.src = e.target.result as string;
                            img.onload = () => {
                                let canvas = document.createElement("canvas");
                                let ctx = canvas.getContext("2d");
                                if (ctx) {
                                    ctx.drawImage(img, 0, 0);
                                    let MAX_WIDTH = 800;
                                    let MAX_HEIGHT = 800;
                                    let width = img.width;
                                    let height = img.height;
                                    if (width > height) {
                                        if (width > MAX_WIDTH) {
                                            height *= MAX_WIDTH / width;
                                            width = MAX_WIDTH;
                                        }
                                    } else {
                                        if (height > MAX_HEIGHT) {
                                            width *= MAX_HEIGHT / height;
                                            height = MAX_HEIGHT;
                                        }
                                    }
                                    canvas.width = width;
                                    canvas.height = height;
                                    ctx.drawImage(img, 0, 0, width, height);
                                    let dataurl = canvas.toDataURL("image/png");
                                    let [mimeType] = dataurl.replace("data:", "").split(";");
                                    resolve(urlToFile(dataurl, file.name, mimeType));
                                }
                            };
                            img.onerror = function () {
                                alert("Invalid file type: " + file.type);
                            };
                        }
                    };
                    reader.readAsDataURL(newImg);
                }
            );
        });
    });
}

export function isJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function openLink(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', `_blank`);
    link.setAttribute('rel', `noopener norefferrer`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const downloadLink = (url: string, fileName: string) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const renderToastSuccess = (title: string) =>
    Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: true,
        timer: 8000,
        timerProgressBar: true
    }).fire({
        icon: 'success',
        title: title
    })
